import React from "react";
import { Button, Result } from "antd";
import { useParams, Link, useNavigate } from "react-router-dom";

const FormFilled = () => {
  const { type, link } = useParams();
  const navigate = useNavigate();
  return (
    <Result
      status="success"
      title={type == "user-request-pdf" ? "User Request from submitted!" : "FFI Client Onboarding Submitted!"}
      subTitle='If you want to download the file, click "Download" button, or close this window.'
      extra={[
        <a href={`${process.env.REACT_APP_SERVER_URL}/onboarding/${type}/${link}`}>
          <Button
            type="primary"
            key="view"
            style={{ width: 150 }}
            // onClick={() => {
            //   navigate(`/onboarding/${type}/${link}`);
            // }}
          >
            View Form
          </Button>
        </a>,
        // <Button key="download" style={{ width: 150 }}>
        //   Download PDF
        // </Button>,
      ]}
    />
  );
};

export default FormFilled;
