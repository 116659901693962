import React, { useState, useEffect } from "react";
import millify from "millify";
import { Table, Typography, Row, Col, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";

const { Title } = Typography;

const ReportTable = ({ data }) => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [searchItem, setSearchItem] = useState("");
    const [searchedData, setSearchedData] = useState(data);

    useEffect(() => {
        const filterData = data?.filter((x) => {
            return (
                x.name.toLowerCase().includes(searchItem.toLowerCase()) ||
                x.account.toLowerCase().includes(searchItem.toLowerCase())
            );
        });
        setSearchedData(filterData);
    }, [searchItem]);

    const columns = [
        {
            title: "No.",
            dataIndex: "No",
            key: "No",
            align: "center",
            width: "5%",
            render: (value, item, index) => {
                return (page - 1) * 20 + (index + 1);
            },
        },
        {
            title: "Account",
            dataIndex: "account",
            key: "account",
            align: "center",
            width: "15%",
            sorter: (a, b) => a.account.localeCompare(b.account),
            render: (text, record) => (
                <Link to="/Position" state={{ account: text, server: record.db }}>
                    {text}
                </Link>
            ),
        },
        {
            title: "Company Name",
            dataIndex: "name",
            key: "account",
            align: "left",
            width: "55%",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "Server",
            dataIndex: "db",
            key: "db",
            align: "center",
            width: "10%",
            sorter: (a, b) => a.db.localeCompare(b.db),
        },
        {
            title: "Value",
            dataIndex: "value",
            key: "value",
            align: "right",
            width: "15%",
            sorter: (a, b) => a.value - b.value,
            render: (text) => millify(text),
        },
    ];

    const dataSource = searchedData.map((x) => ({
        key: x.account,
        account: x.account,
        name: x.name,
        value: x.value,
        db: x.db,
    }));

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Title level={4} style={{ marginLeft: "5px" }}>
                    Accounts position value ranking
                </Title>

                <Input.Search
                    placeholder="Search company name"
                    size="middle"
                    value={searchItem}
                    onChange={(e) => setSearchItem(e.target.value)}
                    allowClear
                    style={{ width: 300 }}
                />
            </div>

            <Table
                columns={columns}
                dataSource={dataSource}
                size="small"
                pagination={{
                    onChange(current) {
                        setPage(current);
                    },
                    position: ["topRight", "bottomRight"],
                    defaultPageSize: 20,
                }}
            />
        </>
    );
};

export default ReportTable;
