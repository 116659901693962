import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  SearchOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Divider,
  Row,
  Col,
  Card,
  Button,
  List,
  notification,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Search,
  Switch,
  Space,
  TreeSelect,
  Checkbox,
  Typography,
  Modal,
  Tag,
} from "antd";
import {
  useFfiCreateMutation,
  useFfiListQuery,
  useFfiListUnconfirmedQuery,
  useFfiUpdateMutation,
  useFfiDetailMutation,
  useFfiDeleteMutation,
} from "../services/onboardingApi";

const FFIOnboarding = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [fileTransferState, setFileTransferState] = useState({ email: false, orbis: false, client: false });
  const [orderSourceState, setOrderSourceState] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [FFIDetailState, setFFIDetailState] = useState({});

  const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);
  const loginUser = useSelector((state) => state.auth.value.user);

  const [CreateFFI, FFIData] = useFfiCreateMutation();
  const [DetailFFI, FFIDetail] = useFfiDetailMutation();
  const [UpdateFFI, FFIUpdate] = useFfiUpdateMutation();
  const [DeleteFFI, FFIDelete] = useFfiDeleteMutation();
  const { data: listFormData } = useFfiListQuery();
  const { data: listFormDataUnconfirmed } = useFfiListUnconfirmedQuery();

  // 每次 FFIDetail 立刻更新 FFIDetailState (用來當作按鈕的判斷條件以及重設條件)
  useEffect(() => {
    setFFIDetailState(FFIDetail);
  }, [FFIDetail]);

  const openNotificationWithIcon = () => {
    notification["success"]({
      message: "Form Update",
      description: "Form update successfully !",
    });
  };

  const duplicateAccountNotification = () => {
    notification["error"]({
      message: "Account Exists",
      description: "Account exist in system !",
    });
  };

  const noSuchAccountNotification = () => {
    notification["error"]({
      message: "Account not found",
      description: "Cannot find the account in system !",
    });
  };

  const SearchFormHandler = async () => {
    let res = await DetailFFI(searchValue);
    if ("error" in res) {
      noSuchAccountNotification();
    } else {
      let form_dateUpdate = {
        ...res?.data?.form_data,
        Estimated_First_Trade_Date: moment(res?.data?.form_data["Estimated_First_Trade_Date"]),
      };
      let formData = { ...res?.data, form_dateUpdate };
      form.resetFields(); // 先 reset 表格
      form.setFieldsValue(form_dateUpdate); // 再複製資料
    }
    setSearchValue("");
  };

  const CreateFormHandler = async (e) => {
    e.preventDefault();

    try {
      const val = await form.validateFields();
      let res = await CreateFFI(form.getFieldsValue());

      if ("error" in res) {
        console.log("ERROR");
        duplicateAccountNotification();
      } else {
        form.resetFields();
        navigate(`/formfilled/${"ffi-pdf"}/${res.data.data.account}`); // Redirect to new page
      }
    } catch (errInfo) {
      console.log("Failed:", errInfo);
    }
  };

  const DetailFormHandler = async (item) => {
    let acctNo = item.item;
    let res = await DetailFFI(acctNo);
    let form_dateUpdate = {
      ...res?.data?.form_data,
      Estimated_First_Trade_Date: moment(res?.data?.form_data["Estimated_First_Trade_Date"]),
    };
    let formData = { ...res?.data, form_dateUpdate };
    form.resetFields(); // 先 reset 表格
    form.setFieldsValue(form_dateUpdate); // 再複製資料
  };

  const UpdateFormHandler = async () => {
    let formData = {
      ...FFIDetailState.data,
      account: form.getFieldsValue()["Account_Number"],
      confirmed_date: moment(),
      confirmed_member: loginUser.username,
      is_confirmed: true,
      form_data: form.getFieldsValue(),
    };
    let res = await UpdateFFI({ account: formData.account, formData: formData });
    form.resetFields();
    setFFIDetailState({});
    openNotificationWithIcon();
  };

  const DeleteFormHandler = (item) => {
    showDeleteConfirm(item.item);
  };

  const ConfirmedDelete = async (acct) => {
    let res = await DeleteFFI(acct);
    form.resetFields();
  };

  const showDeleteConfirm = (acct) => {
    Modal.confirm({
      title: `Are you sure you want to delete the account ${acct}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        ConfirmedDelete(acct);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const isRequired = true;

  let optionArr = [];
  listFormData?.map((x) => {
    let obj = {};
    obj["value"] = x.account;
    optionArr.push(obj);
  });

  return (
    <>
      <Row gutter={[15, 15]} style={{ display: "flex", flexDirection: "row-reverse" }}>
        <Col order={1} xs={24} sm={24} lg={24} xl={6} xxl={6}>
          <Card hoverable={true}>
            {isAuthenticated ? (
              <>
                <Typography.Title level={4}>Unconfirmed forms</Typography.Title>
                <List
                  size="small"
                  split={false}
                  rowKey={listFormDataUnconfirmed?.map((x) => x.account)}
                  dataSource={listFormDataUnconfirmed?.map((x) => x.account)}
                  renderItem={(item) => {
                    return (
                      <List.Item
                        actions={[
                          <Button danger onClick={() => DeleteFormHandler({ item })}>
                            Delete
                          </Button>,
                        ]}
                      >
                        <Button type="link" onClick={() => DetailFormHandler({ item })}>
                          {item}
                        </Button>
                      </List.Item>
                    );
                  }}
                />
                <Divider style={{ borderColor: "darkgray" }} />
                <Typography.Title level={4}>Search Form</Typography.Title>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <AutoComplete
                    placeholder="Account Number"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.toUpperCase())}
                    // onSelect={(value, option) => console.log(`onSelect: ${value}, ${option}`)}
                    defaultOpen={false}
                    defaultActiveFirstOption={false}
                    options={optionArr}
                    style={{ width: "100%" }}
                    filterOption={(inputValue, option) => {
                      return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                    }}
                  ></AutoComplete>
                  <Button type="primary" icon={<SearchOutlined />} onClick={SearchFormHandler}>
                    Search
                  </Button>
                </div>
              </>
            ) : (
              <Space direction="vertical">
                <Typography.Title level={4}>Tips</Typography.Title>
                <Typography.Text>
                  Please fill out the form and submit. You can also download the form after completion. Please email{" "}
                  <a href="mailto: asia@viewtrade.com">asia@viewtrade.com</a> after completing the form.
                </Typography.Text>
              </Space>
            )}
          </Card>
        </Col>
        <Col order={2} xs={24} sm={24} lg={24} xl={18} xxl={18}>
          <Card hoverable={true}>
            <Card>
              <Space style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography.Title level={3}>FFI Client Onboarding Configuration</Typography.Title>
                <Space>
                  {/* 有帳號，尚未確認 */}
                  {(FFIDetailState?.data?.form_data["Account_Number"] !== undefined) &
                  !FFIDetailState?.data?.is_confirmed ? (
                    <>
                      <Button
                        type="primary"
                        style={{ width: "100px" }}
                        // onClick={() => window.location.reload(false)}
                        onClick={() => {
                          form.resetFields();
                          // await DetailFFI("000");
                          setFFIDetailState({});
                        }}
                      >
                        New Form
                      </Button>
                      <Button
                        type="primary"
                        style={{
                          width: "100px",
                          color: "black",
                          backgroundColor: "orange",
                          borderColor: "orange",
                        }}
                        onClick={UpdateFormHandler}
                      >
                        Confirm
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                  {/* 有帳號，已確認，則顯示更新及輸出表單的按鈕 */}
                  {(FFIDetailState?.data?.form_data["Account_Number"] !== undefined) &
                  FFIDetailState?.data?.is_confirmed ? (
                    <>
                      <Button
                        type="primary"
                        style={{ width: "100px" }}
                        onClick={() => {
                          form.resetFields();
                          setFFIDetailState({});
                        }}
                      >
                        New Form
                      </Button>
                      <Button
                        type="primary"
                        style={{ backgroundColor: "darkblue", width: "100px" }}
                        onClick={UpdateFormHandler}
                      >
                        Update
                      </Button>
                      <a
                        href={`${process.env.REACT_APP_SERVER_URL}/onboarding/ffi-pdf/${FFIDetailState?.data?.account}`}
                      >
                        <Button
                          type="primary"
                          style={{
                            backgroundColor: "darkred",
                            borderColor: "darkred",
                            width: "100px",
                          }}
                        >
                          View Form
                        </Button>
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                  {/* 沒帳號，顯示Submit & Reset */}
                  {FFIDetailState?.data?.form_data["Account_Number"] === undefined ? (
                    <Button type="primary" style={{ width: "100px" }} onClick={CreateFormHandler}>
                      Submit
                    </Button>
                  ) : (
                    ""
                  )}
                </Space>
              </Space>
              <Space style={{ marginTop: "8px" }}>
                {FFIDetailState?.data?.is_confirmed ? (
                  <>
                    <Tag color="green">Confirmed</Tag>
                    <Typography.Text type="secondary">
                      Confirmed by <Typography.Text strong>{FFIDetailState?.data?.confirmed_member} </Typography.Text>
                      at <Typography.Text italic>{FFIDetailState?.data?.confirmed_date.split("T")[0]}</Typography.Text>
                    </Typography.Text>
                  </>
                ) : (
                  // <Tag color="magenta">Unconfirm</Tag>
                  ""
                )}
              </Space>
            </Card>
            <Divider />
            <Form
              form={form}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 12,
              }}
              layout="horizontal"
              size="large"
              requiredMark={false}
              initialValues={{ Order_Types: ["DAY", "GTC", "GTD", "IOC", "FOK"] }}
            >
              <Form.Item
                label="FFI Account Name"
                name="FFI_Account_Name"
                rules={[{ required: isRequired }]}
                tooltip={{ title: "Please input account name", icon: <InfoCircleOutlined /> }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="FFI Authorized Representative Name"
                name="FFI_Authorized_Representative_Name"
                rules={[{ required: isRequired }]}
                tooltip={{
                  title: "Please input FFI Authorized Representative Name",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="FFI Authorized Representative Email"
                name="FFI_Authorized_Representative_Email"
                rules={[{ required: isRequired }]}
                tooltip={{
                  title: "Please input FFI Authorized Representative Email",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="FFI Authorized Representative Phone"
                name="FFI_Authorized_Representative_Phone"
                tooltip={{
                  title: "Please input FFI Authorized Representative Phone",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Viewtrade Sales Representative Name"
                name="Viewtrade_Sales_Representative_Name"
                rules={[{ required: isRequired }]}
                tooltip={{
                  title: "Please input Viewtrade Sales Representative Name",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Estimated First Trade Date"
                name="Estimated_First_Trade_Date"
                tooltip={{
                  title: "Please input estimated first trade date",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="Custody Firm"
                name="Custody_Firm"
                rules={[{ required: isRequired }]}
                tooltip={{
                  title: "Please select custodian bank",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group
                  options={["APEX for clearing & custody", "APEX for clearing & CitiBank NA for custody"]}
                  style={{ padding: "10px" }}
                />
              </Form.Item>

              <Form.Item
                label="Account"
                rules={[{ required: isRequired }]}
                tooltip={{
                  title: "Please enter account here",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Space size="middle" style={{ display: "flex", align: "baseline", marginBottom: "-10px" }}>
                  <Form.Item
                    name="Account_Type"
                    rules={[
                      {
                        required: true,
                        message: "Missing Account Type",
                      },
                    ]}
                  >
                    <Select style={{ width: 150 }}>
                      <Select.Option value="Cash">Cash</Select.Option>
                      <Select.Option value="Margin">Margin</Select.Option>
                      <Select.Option value="DVP">DVP</Select.Option>
                      <Select.Option value="LPMA">LPMA</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="Account_Number"
                    rules={[
                      {
                        required: true,
                        message: "Missing Account Number",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Account Number"
                      style={{ width: 250 }}
                      disabled={FFIDetailState?.data?.form_data["Account_Number"] !== undefined}
                    />
                  </Form.Item>
                </Space>
              </Form.Item>

              {/* **** 有帳號 : Additional Accounts 不顯示 ******************************************************* */}

              {FFIDetailState?.data?.form_data["Account_Number"] !== undefined ? (
                ""
              ) : (
                <Form.Item
                  label="Additional Accounts"
                  rules={[{ required: isRequired }]}
                  tooltip={{
                    title: "If there are other accounts using the same form, please add accounts here.",
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Form.List name="Additional_Accounts">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            size="middle"
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "Account_Type"]}
                              rules={[
                                {
                                  required: isRequired,
                                  message: "Missing Account Type",
                                },
                              ]}
                            >
                              {/* <Input placeholder="Account Type" /> */}
                              <Select style={{ width: 150 }}>
                                <Select.Option value="Cash">Cash</Select.Option>
                                <Select.Option value="Margin">Margin</Select.Option>
                                <Select.Option value="DVP">DVP</Select.Option>
                                <Select.Option value="LPMA">LPMA</Select.Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "Account_Number"]}
                              rules={[
                                {
                                  required: isRequired,
                                  message: "Missing Account Number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Account Number"
                                onChange={(e) => e.target.value.toUpperCase()}
                                style={{ width: 250 }}
                              />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Accounts
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form.Item>
              )}

              {/* ****************************************************************************************************** */}

              <Form.Item
                label="Commission Charge"
                name="Commission_Charge"
                tooltip={{
                  title: "Please input the commission schedule",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input.TextArea placeholder="Commission" row={4} style={{ height: 110 }} />
              </Form.Item>

              <Form.Item
                label="Products"
                name="Products"
                rules={[{ required: isRequired }]}
                tooltip={{
                  title: "Please check the trading products",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group
                  style={{ padding: "10px" }}
                  options={["Equity", "Options", "Fixed Income", "Mutual Funds"]}
                ></Checkbox.Group>
              </Form.Item>

              <Form.Item
                label="Equity Market Access"
                name="Equity_Market_Access"
                rules={[{ required: isRequired }]}
                tooltip={{
                  title: "Please select the trading market",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group
                  style={{ padding: "10px" }}
                  options={["U.S.", "Canada", "UK", "Australia", "German", "France", "Switzerland"]}
                ></Checkbox.Group>
              </Form.Item>

              <Form.Item
                label="Source of Orders"
                name="Source_of_Orders"
                rules={[{ required: isRequired }]}
                tooltip={{
                  title: "Please select how you plan to place the order",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group style={{ width: "100%", padding: "10px" }}>
                  <Space
                    size="middle"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "stretch",
                    }}
                  >
                    <Checkbox value="End client submits orders via Orbis white-label web">
                      End client submits orders via Orbis white-label web
                    </Checkbox>

                    <Checkbox value="Professional submits via web">Professional submits via web</Checkbox>

                    <Checkbox value="Orders go through direct FIX">Orders go through direct FIX</Checkbox>

                    <Checkbox
                      value="Orders go through other network"
                      onChange={(e) => setOrderSourceState(e.target.checked)}
                    >
                      Orders go through other network
                    </Checkbox>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      {orderSourceState || form.getFieldValue("Network_Types") != null ? (
                        <>
                          <Form.Item name="Network_Types">
                            <Select placeholder="Select Network Tyes" style={{ width: 200, margin: "0px 20px" }}>
                              <Select.Option value="Bloomberg">Bloomberg</Select.Option>
                              <Select.Option value="Sungard">Sungard</Select.Option>
                              <Select.Option value="NYFIX">NYFIX</Select.Option>
                              <Select.Option value="REDI">REDI</Select.Option>
                              <Select.Option value="Reuters">Reuters</Select.Option>
                              <Select.Option value="Other">Other</Select.Option>
                            </Select>
                          </Form.Item>
                          <Form.Item name="Network_Settings" style={{ flex: 1, marginBottom: "-10px" }}>
                            <Input.TextArea row={4} style={{ height: 100 }} />
                          </Form.Item>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Space>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                label="Order Types"
                name="Order_Types"
                tooltip={{
                  title: "Please select the order type",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group style={{ padding: "10px" }}>
                  <Row>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="DAY">DAY</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="GTC">GTC</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="GTD">GTD</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="IOC">IOC</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="FOK">FOK</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="LOO">LOO</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="LOC">LOC</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="MOO">MOO</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="MOC">MOC</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="OCO">OCO</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="Trailing Stop">Trailing Stop</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="Algos (VWAP/TWAP/POV)">Algos (VWAP/TWAP/POV)</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="Pre Market">Pre Market</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="Post Market">Post Market</Checkbox>
                    </Col>
                    <Col span={8} style={{ marginBottom: "8px" }}>
                      <Checkbox value="Night Session">Night Session</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                label="Number of end user accounts"
                name="Number_of_end_user_accounts"
                tooltip={{
                  title: "Please select how many users in this account",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Radio.Group style={{ padding: "10px" }}>
                  <Space direction="vertical" size="middle">
                    <Radio value={"N/A"}>N/A</Radio>
                    <Radio value={"Under 100"}>Under 100</Radio>
                    <Radio value={"100 ~ 500"}>100 ~ 500</Radio>
                    <Radio value={"500 ~ 1000"}>500 ~ 1000</Radio>
                    <Radio value={"1000+"}>1000+</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Expected Daily Order Flow"
                name="Expected_Daily_Order_Flow"
                tooltip={{
                  title: "Please select how many orders per day",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Radio.Group style={{ padding: "10px" }}>
                  <Space direction="vertical" size="middle">
                    <Radio value={"Under 100"}>Under 100</Radio>
                    <Radio value={"100 ~ 200"}>100 ~ 200</Radio>
                    <Radio value={"200 ~ 500"}>200 ~ 500</Radio>
                    <Radio value={"500+"}>500+</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Fractional Trading"
                name="Fractional_Trading"
                tooltip={{
                  title: "Please check if this account is going to trade fractional",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group style={{ width: "100%", padding: "10px" }}>
                  <Space direction="vertical" size="middle" style={{ display: "flex", width: "100%" }}>
                    <Checkbox value="Fractional Trading via FIX">Fractional Trading via FIX</Checkbox>
                    <Checkbox value="WebTrader for Fractional Trading">WebTrader for Fractional Trading</Checkbox>
                  </Space>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                label="Fractional Commission"
                name="Fractional_Commission"
                tooltip={{
                  title: "Please input fractional commission",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input.TextArea placeholder="Fractional Commission" row={4} style={{ flex: 1, height: 120 }} />
              </Form.Item>
              <Form.Item
                label="Provided Start of Day Files"
                name="Provided_Start_of_Day_Files"
                tooltip={{
                  title: "If you need to send out SOD file, please check the SOD file format",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group style={{ padding: "10px", width: "100%" }}>
                  <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    <Space>
                      <Checkbox value="Client positions">Client positions</Checkbox>
                    </Space>
                    <Space>
                      <Checkbox value="Client buying power">Client buying power</Checkbox>
                    </Space>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Checkbox value="Other SOD file">Other SOD file</Checkbox>
                      <Form.Item name="Other_SOD_file_information" style={{ flex: 1, margin: "0 0 -5px 20px" }}>
                        <Input placeholder="SOD files information" />
                      </Form.Item>
                    </div>
                  </Space>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                label="Required End of Day Files"
                name="Required_End_of_Day_Files"
                tooltip={{
                  title: "Please select EOD file format you want to get",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group style={{ padding: "10px" }}>
                  <Space direction="vertical" size="middle">
                    <Space>
                      <Checkbox value="Order summary">Order summary</Checkbox>
                    </Space>
                    <Space>
                      <Checkbox value="Positions file">Positions file</Checkbox>
                    </Space>
                    <Space>
                      <Checkbox value="Account balances">Account balances</Checkbox>
                    </Space>
                  </Space>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                label="File Transfer Method"
                name="File_Transfer_Method"
                tooltip={{
                  title: "Please check how to get EOD file",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group style={{ width: "100%", padding: "10px" }}>
                  <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Checkbox
                        value="Email (EOD only)"
                        onChange={(e) =>
                          setFileTransferState({
                            ...fileTransferState,
                            email: e.target.checked,
                          })
                        }
                      >
                        Email (EOD only)
                      </Checkbox>
                      <Form.Item name="EOD_Email" style={{ flex: 1, width: "100%", margin: "0 0 -15px 20px" }}>
                        {fileTransferState.email ? <Input placeholder="Email" /> : <></>}
                      </Form.Item>
                    </div>
                    <Space size="middle">
                      <Checkbox
                        value="SFTP at Orbis"
                        onChange={(e) =>
                          setFileTransferState({
                            ...fileTransferState,
                            orbis: e.target.checked,
                          })
                        }
                      >
                        SFTP at Orbis
                      </Checkbox>
                      {fileTransferState.orbis ? (
                        <div>( Please generate SFTP public key and email to support@orbisfn.com )</div>
                      ) : (
                        <></>
                      )}
                    </Space>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Checkbox
                        value="SFTP at Client"
                        onChange={(e) => {
                          setFileTransferState({
                            ...fileTransferState,
                            client: e.target.checked,
                          });
                        }}
                      >
                        SFTP at Client
                      </Checkbox>

                      {fileTransferState.client || form.getFieldValue("Client_SFTP_Information") != null ? (
                        <Form.Item
                          name="Client_SFTP_Information"
                          style={{ flex: 1, width: "100%", margin: "0 0 -5px 20px" }}
                        >
                          <Input.TextArea placeholder="Client SFTP server information" style={{ height: 80 }} />
                        </Form.Item>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Space>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                label="FFI Client Group Email for Emergency"
                name="FFI_Client_Group_Email_for_Emergency"
                tooltip={{
                  title: "Please input FFI group email for emergency notifications",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="FFI Client Group Email for Order Summary"
                name="FFI_Client_Group_Email_for_Order_Summary"
                tooltip={{
                  title: "Please input FFI group email for order summary files",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="FFI Technical Name"
                name="FFI_Technical_Name"
                tooltip={{
                  title: "Please input technical contact person name",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="FFI Technical Email"
                name="FFI_Technical_Email"
                tooltip={{
                  title: "Please input technical contact person email",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="FFI Technical Phone"
                name="FFI_Technical_Phone"
                tooltip={{
                  title: "Please input technical contact person phone",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Input />
              </Form.Item>
              <Divider />

              <Form.Item wrapperCol={{ span: 12, offset: 8 }}>
                {/* 有帳號，尚未確認 */}
                {(FFIDetailState?.data?.form_data["Account_Number"] !== undefined) &
                !FFIDetailState?.data?.is_confirmed ? (
                  <Space size="middle">
                    <Button
                      type="primary"
                      style={{ width: "100px" }}
                      // onClick={() => window.location.reload(false)}
                      onClick={() => {
                        form.resetFields();
                        // await DetailFFI("000");
                        setFFIDetailState({});
                      }}
                    >
                      New Form
                    </Button>
                    <Button
                      type="primary"
                      style={{
                        width: "100px",
                        color: "black",
                        backgroundColor: "orange",
                        borderColor: "orange",
                      }}
                      onClick={UpdateFormHandler}
                    >
                      Confirm
                    </Button>
                  </Space>
                ) : (
                  ""
                )}
                {/* 有帳號，已確認，則顯示更新及輸出表單的按鈕 */}
                {(FFIDetailState?.data?.form_data["Account_Number"] !== undefined) &
                FFIDetailState?.data?.is_confirmed ? (
                  <Space size="middle">
                    <Button
                      type="primary"
                      style={{ width: "100px" }}
                      onClick={() => {
                        form.resetFields();
                        setFFIDetailState({});
                      }}
                    >
                      New Form
                    </Button>
                    <Button
                      type="primary"
                      style={{ backgroundColor: "darkblue", width: "100px" }}
                      onClick={UpdateFormHandler}
                    >
                      Update
                    </Button>
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "darkred",
                        borderColor: "darkred",
                        width: "100px",
                      }}
                    >
                      View Form
                    </Button>
                  </Space>
                ) : (
                  ""
                )}
                {/* 沒帳號，顯示Submit & Reset */}
                {FFIDetailState?.data?.form_data["Account_Number"] === undefined ? (
                  <Space size="middle">
                    <Button type="primary" style={{ width: "100px" }} onClick={CreateFormHandler}>
                      Submit
                    </Button>
                    <Button type="primary" style={{ width: "100px" }} onClick={() => form.resetFields()}>
                      Reset
                    </Button>
                  </Space>
                ) : (
                  ""
                )}
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FFIOnboarding;
