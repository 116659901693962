import React, { useState } from "react";
import { ETFListTable, Selector, Loader } from "../components";
import { useEtfTopTenQuery, useEtfInitScreenerQuery } from "../services/orbisApi";
import { Row, Col } from "antd";

const ETFOverview = () => {
  const [inverse, setInverse] = useState("ALL");
  const [leverage, setLeverage] = useState("ALL");

  const { data: TopTenData, isFetching } = useEtfTopTenQuery({
    inverseType: inverse,
    leverageType: leverage,
  });
  const { data: SelectorData, isSuccess } = useEtfInitScreenerQuery();

  return (
    <>
      {isSuccess && (
        <Row style={{ marginBottom: "15px" }} className="block-container">
          <Col xs={24} sm={24} lg={24} xl={6} xxl={6} style={{ margin: "5px" }}>
            <Selector
              title="Inverse"
              selected={inverse}
              setSelected={setInverse}
              selection={SelectorData?.InverseTypes}
            />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={6} xxl={6} style={{ margin: "5px" }}>
            <Selector
              title="Leverage"
              selected={leverage}
              setSelected={setLeverage}
              selection={SelectorData?.LeverageTypes}
            />
          </Col>
          <Col xs={0} sm={0} lg={0} flex="Auto"></Col>
        </Row>
      )}

      {isFetching ? (
        <Loader />
      ) : (
        <Row gutter={[15, 15]}>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={12}>
            <ETFListTable title="Most popular ETFs by volume" topTen={TopTenData.topvolume} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={12}>
            <ETFListTable title="Top ETFs by most money traded" topTen={TopTenData.topvalue} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={12}>
            <ETFListTable title="Best performing ETFs (price change %)" topTen={TopTenData.topchangepc} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={12}>
            <ETFListTable title="Worst performing ETFs (price change %)" topTen={TopTenData.bottomchangepc} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ETFOverview;
