export const countrySelection = [
  ["", "ALL"],
  ["ARG", "Argentina"],
  ["AUS", "Australia"],
  ["AUT", "Austria"],
  ["BEL", "Belgium"],
  ["BRA", "Brazil"],
  ["CHE", "Switzerland"],
  ["CHN", "China"],
  ["DEU", "Germany"],
  ["DNK", "Denmark"],
  ["ESP", "Spain"],
  ["FIN", "Finland"],
  ["FRA", "France"],
  ["GBR", "United Kingdom"],
  ["GRC", "Greece"],
  ["HKG", "Hong Kong"],
  ["IDN", "Indonesia"],
  ["IND", "India"],
  ["IRL", "Ireland"],
  ["ISR", "Israel"],
  ["ITA", "Italy"],
  ["JPN", "Japan"],
  ["KOR", "Korea"],
  ["MEX", "Mexico"],
  ["NLD", "Netherlands"],
  ["NOR", "Norway"],
  ["NZL", "New Zealand"],
  ["PRT", "Portugal"],
  ["RUS", "Russia"],
  ["SGP", "Singapore"],
  ["SWE", "Sweden"],
  ["THA", "Thailand"],
  ["TUR", "Turkey"],
  ["ZAF", "South Africa"],
];
