import React, { useState } from "react";
import { Row, Col, Radio, Table } from "antd";
import millify from "millify";
import { Loader } from "../components";
import { useIpoPerformanceQuery } from "../services/orbisApi";
import { Link } from "react-router-dom";

const IPOPerformance = () => {
  const [period, setPeriod] = useState("1m");
  const { data, isFetching, isSuccess } = useIpoPerformanceQuery({
    loadQuotes: true,
    time: period,
    sort: "ipoRet",
  });

  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      align: "center",
      width: "10%",
      render: (text) => <Link to={"/stockdetail/" + text}>{text}</Link>,
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      align: "left",
      width: "25%",
      responsive: ["xl"],
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
      align: "left",
      ellipsis: true,
      width: "23%",
      responsive: ["lg"],
    },
    {
      title: "IPO Date",
      dataIndex: "ipoDate",
      key: "ipoDate",
      align: "center",
      width: "10%",
      render: (text) => <div>{text.split(" ")[0]}</div>,
    },
    {
      title: "IPO return",
      dataIndex: "ipoReturn",
      key: "ipoReturn",
      align: "right",
      width: "8%",
      responsive: ["lg"],
      sorter: (a, b) => a.ipoReturn - b.ipoReturn,
      render: (text) => {
        let color = text > 0 ? "green" : text < 0 ? "red" : "black";
        return <div style={{ color }}>{millify(text)}%</div>;
      },
    },
    {
      title: "Offer Price",
      dataIndex: "offerPrice",
      key: "offerPrice",
      align: "right",
      width: "8%",
      sorter: (a, b) => a.offerPrice - b.offerPrice,
      render: (text) => <div>${text.toFixed(2)}</div>,
    },
    {
      title: "Last Price",
      dataIndex: "lastPrice",
      key: "lastPrice",
      align: "right",
      width: "8%",
      sorter: (a, b) => a.lastPrice - b.lastPrice,
      render: (text) => <div>${text.toFixed(2)}</div>,
    },
    {
      title: "Change",
      dataIndex: "change",
      key: "change",
      align: "right",
      width: "8%",
      sorter: (a, b) => a.change - b.change,
      render: (text) => {
        let color = text > 0 ? "green" : text < 0 ? "red" : "black";
        return <div style={{ color }}>{millify(text)}%</div>;
      },
    },
  ];

  const dataSource = data?.map((x) => ({
    key: x.symbol,
    symbol: x.symbol,
    name: x.companyName,
    industry: x.industry.industryName,
    ipoDate: x.ipoDate,
    ipoReturn: x.ipoReturn,
    change: x.quote.changePercent,
    offerPrice: x.offerPrice,
    lastPrice: x.quote.lastPrice,
    yesterdayClose: x.yesterdayClose,
    yesterdayVolume: x.yesterdayVolume,
    marketCap: x.marketCap,
  }));

  return (
    <>
      <div className="block-container">
        <Row>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            <Radio.Group defaultValue="1m" buttonStyle="solid" onChange={(e) => setPeriod(e.target.value)}>
              <Radio.Button value="1m">One month</Radio.Button>
              <Radio.Button value="3m">Three month</Radio.Button>
              <Radio.Button value="6m">Six month</Radio.Button>
              <Radio.Button value="1y">One Year</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        {isFetching ? (
          <Loader />
        ) : (
          <Row style={{ marginTop: "15px" }}>
            <Table columns={columns} dataSource={dataSource} size="small" pagination={false} />
          </Row>
        )}
      </div>
    </>
  );
};

export default IPOPerformance;
