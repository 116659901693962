import React from "react";
import { Layout, Breadcrumb } from "antd";
import { Routes, Route } from "react-router-dom";

import {
  Homepage,
  Market,
  Trading,
  News,
  ETFCenter,
  ADRCenter,
  IPOCenter,
  CorporateAction,
  TipRank,
  Fractional,
  StockLending,
  StockDetail,
  AccountInfo,
  AccountActivity,
  FIXconnection,
  ProductMaster,
  NewsBody,
  TwQuote,
  TwStrategy,
  Login,
  Reports,
  Position,
  Signup,
  ResetPassword,
  Activate,
  ResetPasswordConfirm,
  AllAccounts,
  FFIOnboarding,
  UserRequest,
  FormFilled,
  ETFList,
  PWS,
  SEC,
} from "./pages";
import MyLayout from "./hocs/MyLayout";

const { Content } = Layout;

const App = () => {
  return (
    <>
      <MyLayout>
        <Content style={{ margin: "10px 10px" }}>
          {/* <Breadcrumb style={{ margin: "15px 5px" }}>
          <Breadcrumb.Item>User</Breadcrumb.Item>
          <Breadcrumb.Item>Bill</Breadcrumb.Item>
        </Breadcrumb> */}
          <div style={{ padding: 8, minHeight: 500 }}>
            <Routes>
              <Route exact path="/" element={<Homepage />} />
              <Route exact path="/market" element={<Market />} />
              <Route exact path="/trading" element={<Trading />} />
              <Route exact path="/news" element={<News />} />
              <Route exact path="/news/:id" element={<NewsBody />} />

              <Route exact path="/pws" element={<PWS />} />
              <Route exact path="/sec" element={<SEC />} />

              <Route exact path="/etfcenter/*" element={<ETFCenter />} />
              <Route exact path="/adrcenter/*" element={<ADRCenter />} />
              <Route exact path="/ipocenter/*" element={<IPOCenter />} />
              <Route exact path="/corporateaction" element={<CorporateAction />} />
              <Route exact path="/tiprank" element={<TipRank />} />
              <Route exact path="/accountinfo" element={<AccountInfo />} />
              <Route exact path="/accountactivity" element={<AccountActivity />} />
              <Route exact path="/productmaster" element={<ProductMaster />} />
              <Route exact path="/fractional" element={<Fractional />} />
              <Route exact path="/stocklending" element={<StockLending />} />
              <Route exact path="/stockdetail/:symbol" element={<StockDetail />} />
              <Route exact path="/fixconnection" element={<FIXconnection />} />
              <Route exact path="/twquote" element={<TwQuote />} />
              <Route exact path="/twstrategy" element={<TwStrategy />} />

              {/* Remove customer information link @20221125 */}
              <Route exact path="/allaccounts" element={<FIXconnection />} />
              <Route exact path="/reports" element={<FIXconnection />} />
              <Route exact path="/position" element={<FIXconnection />} />
              {/* <Route exact path="/allaccounts" element={<AllAccounts />} />
              <Route exact path="/reports" element={<Reports />} />
              <Route exact path="/position" element={<Position />} /> */}

              <Route exact path="/etflist" element={<ETFList />} />

              <Route exact path="/onboarding" element={<FFIOnboarding />} />
              <Route exact path="/userrequest" element={<UserRequest />} />
              <Route exact path="/formfilled/:type/:link" element={<FormFilled />} />

              <Route exact path="/login" element={<Login />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/resetpassword" element={<ResetPassword />} />
              <Route exact path="/activate/:uid/:token" element={<Activate />} />
              <Route exact path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
            </Routes>
          </div>
        </Content>
      </MyLayout>
    </>
  );
};

export default App;
