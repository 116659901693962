import React, { useState, useEffect } from "react";
import { Select, Divider } from "antd";

const Selector = ({ title, selected, setSelected, selection }) => {
  //   const [selected, setSelected] = useState("");

  //   useEffect(() => {
  //     console.log(selected);
  //   }, [selected]);

  return (
    <>
      <div style={{ color: "darkgray", margin: "0px 0px 1px 10px" }}>{title}</div>
      <Select
        size="middle"
        placeholder={title}
        value={selected}
        style={{ width: "100%" }}
        onChange={(e, { value }) => setSelected(value)}
      >
        {selection.map((x) => (
          <Select.Option value={x} key={x}>
            {x}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default Selector;
