import React from "react";
import { Layout } from "antd";
import { blue } from "@ant-design/colors";

const MyFooter = () => {
    return (
        <Layout.Footer style={{ textAlign: "center" }}>
            VTTW ©2022 Created by{" "}
            <a href="https://linkedin.com/in/ykangho/" style={{ color: blue[7] }}>
                YK
            </a>
        </Layout.Footer>
    );
};

export default MyFooter;
