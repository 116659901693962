import React from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Checkbox, Row, Col, Card, Modal, Divider, Space } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useSignUpMutation } from "../services/authApi";
import { signupSuccess, logout } from "../features/authSlice";

const Signup = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signup, signupResult] = useSignUpMutation();

  const onFinish = async (signupInfo) => {
    const result = await signup(signupInfo);
    form.resetFields();

    console.log(result);

    if (result.data) {
      dispatch(signupSuccess());
      showSuccessModal(`Confirmation email is sent to ${result.data.email}. Please verify your account!`);
      navigate("/login");
    } else {
      dispatch(logout());
      showFailModal(result.error.data);
    }
  };

  const showSuccessModal = (text) => {
    Modal.info({
      title: "SIGNUP SUCCESS",
      content: text,
    });
  };

  const showFailModal = (text) => {
    Modal.error({
      title: "SIGNUP FAIL",
      content: (
        <>
          {text.email ? text.email : ""}
          <br />
          {text.username ? text.username : ""}
        </>
      ),
    });
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={24} lg={24} xl={8} xxl={8}></Col>
        <Col
          xs={24}
          sm={24}
          lg={24}
          xl={8}
          xxl={8}
          style={{
            // border: "1px solid",
            height: "80vh",
            width: "100%",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <Card hoverable={true}>
            <Form
              form={form}
              size="large"
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item name="username" rules={[{ required: true, message: "Please input your Username!" }]}>
                <Input prefix={<UserOutlined style={{ color: "gray" }} />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input prefix={<MailOutlined style={{ color: "gray" }} />} placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please input your Password!" }]}
                hasFeedback
              >
                <Input prefix={<LockOutlined style={{ color: "gray" }} />} type="password" placeholder="Password" />
              </Form.Item>

              <Form.Item
                name="re_password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("The two passwords that you entered do not match!"));
                    },
                  }),
                ]}
              >
                <Input
                  prefix={<LockOutlined style={{ color: "gray" }} />}
                  type="password"
                  placeholder="Confirm Password"
                />
              </Form.Item>

              {/* <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
              </Form.Item> */}
              <Form.Item>
                <Button block type="primary" htmlType="submit" className="login-form-button">
                  Sign Up
                </Button>
              </Form.Item>
              <Form.Item>
                <div>
                  Already have an account? <Link to="/login">Sign In</Link>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} lg={24} xl={8} xxl={8}></Col>
      </Row>
    </>
  );
};

export default Signup;
