import React from "react";
import { useSelector } from "react-redux";
import { AuthorizationPage, Working } from "../components";
import { Button } from "antd";
import { useLoginMutation } from "../services/authApi";

const TwQuote = () => {
    const [login, result] = useLoginMutation();

    const handleLogin = async (e) => {
        e.preventDefault();
        const loginInfo = {
            email: "ykang.ho@gmail.com",
            password: "Ss882913",
        };
        await login(loginInfo);
    };

    console.log(result);
    const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);

    return (
        <>{!isAuthenticated ? <AuthorizationPage /> : <Working />}</>
        // <div>
        //   <Button onClick={handleLogin}>Click me!</Button>
        // </div>
    );
};

export default TwQuote;
