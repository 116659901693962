import React from "react";
import { Typography, Table, Alert } from "antd";
import { Link } from "react-router-dom";

const ETFSectorTable = ({ title, sectorData }) => {
  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      align: "center",
      width: "20%",
      render: (text, record) => <Link to={`/stockdetail/${text}`}>{text}</Link>,
    },
    {
      title: "Name",
      dataIndex: "name",
      ellipsis: true,
      key: "name",
      align: "left",
      //   responsive: ["xl"],
      width: "60%",
    },
    {
      title: "Change",
      dataIndex: "change",
      key: "change",
      align: "center",
      width: "20%",
      sorter: (a, b) => a.change - b.change,
      render: (text) => {
        let color = text > 0 ? "green" : text < 0 ? "red" : "black";
        return <div style={{ color }}>{text.toFixed(2)}%</div>;
      },
    },
  ];

  const dataSource = sectorData?.map((x) => ({
    key: x.symbol,
    symbol: x.symbol,
    name: x.quote.companyName,
    change: x.quote.changePercent,
  }));

  return (
    <div className="block-container">
      {/* <Typography.Title level={5}>{title}</Typography.Title> */}
      <Alert message={title} type="warning" style={{ fontWeight: "bold" }} />
      <Table showHeader={false} dataSource={dataSource} columns={columns} size="small" pagination={false} />
    </div>
  );
};

export default ETFSectorTable;
