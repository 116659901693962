import React from "react";
import Plot from "react-plotly.js";
import { Typography } from "antd";

const { Title } = Typography;

const PositionTreeMap = ({ data }) => {
    const treeMapColor = (num) => {
        let color = "";
        if (num < -2.5) {
            color = "#F63538";
        } else if (num >= -2.5 && num < -1.5) {
            color = "#BF4045";
        } else if (num >= -1.5 && num < -0.5) {
            color = "#8B444E";
        } else if (num >= -0.5 && num < 0.5) {
            color = "#414554";
        } else if (num >= 0.5 && num < 1.5) {
            color = "#35764E";
        } else if (num >= 1.5 && num < 2.5) {
            color = "#2F9E4F";
        } else if (num >= 2.5) {
            color = "#30CC5A";
        }
        return color;
    };
    const subData = data.data.length < 150 ? data.data : data.data.slice(0, 150);
    var labels = subData.map((x) => x.Symbol);
    var parents = Array(labels.length).fill("");
    var values = subData.map((x) => x.Value);
    const chartData = [
        {
            type: "treemap",
            labels: labels,
            parents: parents,
            values: values,
            // textinfo: "label+text+percent entry",
            textposition: "middle center",
            texttemplate: "%{label} (%{percentEntry})",
            hoverinfo: "label+value+percent entry",
            domain: { x: [0, 1] },
            outsidetextfont: { size: 20, color: "#377eb8" },
            marker: {
                autocolorscale: false,
                colors: subData.map((x) => treeMapColor(((x.LastPrice - x.Closing) / x.Closing) * 100)),
                // colorscale: "Earth",
                line: { width: 0 },
            },
            pathbar: { visible: false },
        },
    ];

    const layout = {
        margin: {
            l: 0,
            r: 0,
            t: 0,
            b: 0,
        },
        // showscale: true,
        // autosize: true,
    };

    return (
        <div>
            {/* <Title level={4} style={{ marginLeft: "5px" }}>
                {data.title}
            </Title> */}
            <Plot data={chartData} layout={layout} useResizeHandler={true} style={{ width: "100%" }} />
        </div>
    );
};

export default PositionTreeMap;
