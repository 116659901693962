import React, { useState } from "react";
import { Card, Table, Typography, Radio, Tooltip } from "antd";
import millify from "millify";
import { loader } from "../components";
import { useIndustryHeatMapQuery, useIndustryListQuery } from "../services/orbisApi";

const { Text, Title } = Typography;

const HomeIndustryHeatMap = ({ type, title }) => {
  const { data, isFetching, isSuccess } = useIndustryHeatMapQuery({ industryType: type });

  const [priceorcap, setPriceorcap] = useState("Price");

  const capColumn = [
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
      align: "center",
      width: "20%",
      render: (text) => <div style={{ fontWeight: 700 }}>{text}</div>,
    },
    {
      title: "1 month",
      dataIndex: "marketCapChangeOneMonth",
      key: "marketCapChangeOneMonth",
      align: "center",
      width: "20%",
      render(text, record) {
        return {
          props: {
            style: { color: "white", background: text > 0 ? "green" : "red" },
          },
          children: <div>{text.toFixed(2)}%</div>,
        };
      },
      // render: (text) => <div>{text.toFixed(2)}%</div>,
    },
    {
      title: "2 weeks",
      dataIndex: "marketCapChangeTwoWeeks",
      key: "marketCapChangeTwoWeeks",
      align: "center",
      width: "20%",
      render(text, record) {
        return {
          props: {
            style: { color: "white", background: text > 0 ? "green" : "red" },
          },
          children: <div>{text.toFixed(2)}%</div>,
        };
      },
      // render: (text) => <div>{text.toFixed(2)}%</div>,
    },
    {
      title: "1 week",
      dataIndex: "marketCapChangeOneWeek",
      key: "marketCapChangeOneWeek",
      align: "center",
      width: "20%",
      render(text, record) {
        return {
          props: {
            style: { color: "white", background: text > 0 ? "green" : "red" },
          },
          children: <div>{text.toFixed(2)}%</div>,
        };
      },
      // render: (text) => <div>{text.toFixed(2)}%</div>,
    },
    {
      title: "Now",
      dataIndex: "marketCapChange",
      key: "marketCapChange",
      align: "center",
      width: "20%",
      render(text, record) {
        return {
          props: {
            style: { color: "white", background: text > 0 ? "green" : "red" },
          },
          children: <div>{text.toFixed(2)}%</div>,
        };
      },
      // render: (text) => <div>{text.toFixed(2)}%</div>,
    },
  ];

  const priceColumn = [
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
      align: "center",
      width: "20%",
      render: (text) => <div style={{ fontWeight: 700 }}>{text}</div>,
    },
    {
      title: "1 month",
      dataIndex: "priceChangeTwoWeeks",
      key: "priceChangeTwoWeeks",
      align: "center",
      width: "20%",
      render(text, record) {
        return {
          props: {
            style: { color: "white", background: text > 0 ? "green" : "red" },
          },
          children: <div>{text.toFixed(2)}%</div>,
        };
      },
      // render: (text) => <div>{text.toFixed(2)}%</div>,
    },
    {
      title: "2 weeks",
      dataIndex: "priceChangeTwoWeeks",
      key: "priceChangeTwoWeeks",
      align: "center",
      width: "20%",
      render(text, record) {
        return {
          props: {
            style: { color: "white", background: text > 0 ? "green" : "red" },
          },
          children: <div>{text.toFixed(2)}%</div>,
        };
      },
      // render: (text) => <div>{text.toFixed(2)}%</div>,
    },
    {
      title: "1 week",
      dataIndex: "priceChangeOneWeek",
      key: "priceChangeOneWeek",
      align: "center",
      width: "20%",
      render(text, record) {
        return {
          props: {
            style: { color: "white", background: text > 0 ? "green" : "red" },
          },
          children: <div>{text.toFixed(2)}%</div>,
        };
      },
      // render: (text) => <div>{text.toFixed(2)}%</div>,
    },
    {
      title: "Now",
      dataIndex: "priceChange",
      key: "priceChange",
      align: "center",
      width: "20%",
      render(text, record) {
        return {
          props: {
            style: { color: "white", background: text > 0 ? "green" : "red" },
          },
          children: <div>{text.toFixed(2)}%</div>,
        };
      },
      // render: (text) => <div>{text.toFixed(2)}%</div>,
    },
  ];

  const dataSource = data?.map((x) => ({
    key: x.industry.levelCode,
    sector: x.industry.industryName,
    marketCap: x.marketCap,
    marketCapChange: x.marketCapChange,
    marketCapChangeOneMonth: x.marketCapChangeOneMonth,
    marketCapChangeOneWeek: x.marketCapChangeOneWeek,
    marketCapChangeTwoWeeks: x.marketCapChangeTwoWeeks,
    marketCapRank: x.marketCapRank,
    priceChange: x.priceChange,
    priceChangeOneMonth: x.priceChangeOneMonth,
    priceChangeOneWeek: x.priceChangeOneWeek,
    priceChangeTwoWeeks: x.priceChangeTwoWeeks,
    priceRank: x.priceRank,
  }));

  return (
    <>
      <Card bordered={false} hoverable={true}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px" }}>
          <Title level={4}>{title}</Title>

          <Radio.Group
            buttonStyle="solid"
            size={"middle"}
            onChange={(e) => setPriceorcap(e.target.value)}
            value={priceorcap}
          >
            <Tooltip title="Capital">
              <Radio.Button value={"Cap"}>Capital</Radio.Button>
            </Tooltip>
            <Tooltip title="Price">
              <Radio.Button value={"Price"}>Price</Radio.Button>
            </Tooltip>
          </Radio.Group>
        </div>

        {priceorcap == "Price" ? (
          <Table bordered columns={priceColumn} dataSource={dataSource} size={"small"} pagination={false} />
        ) : (
          <Table bordered columns={capColumn} dataSource={dataSource} size={"small"} pagination={false} />
        )}
      </Card>
    </>
  );
};

export default HomeIndustryHeatMap;
