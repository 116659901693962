import React from "react";
import { ETFDetails, ETFOverview, ETFScreener, ETFSector } from "../components";
import ETFLayout from "../hocs/ETFLayout";
import { Routes, Route } from "react-router-dom";

const ETFCenter = () => {
  return (
    <ETFLayout>
      <Routes>
        <Route exact path="/" element={<ETFOverview />} />
        <Route exact path="/etfscreener" element={<ETFScreener />} />
        <Route exact path="/etfsector" element={<ETFSector />} />
        <Route exact path="/etfdetails/:symbol" element={<ETFDetails />} />
      </Routes>
    </ETFLayout>
  );
};

export default ETFCenter;
