import React from "react";
import { Loader } from "../components";
import { Link } from "react-router-dom";
import { moment } from "moment";
import { format } from "date-fns";
import { Divider, Typography, List, Space } from "antd";
import { useListNewsQuery } from "../services/orbisApi";

const { Title, Text } = Typography;

const NewsProvider = ({ title, provider, start, count }) => {
  const result = useListNewsQuery({
    start: start,
    count: count,
    filter: `provider:${provider}`,
  });

  const outputList = (data) => (
    <List
      header={
        <div>
          <Title level={4}>{title}</Title>
        </div>
      }
      itemLayout="horizontal"
      size="default"
      dataSource={data}
      split={true}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={
              item.linkOnly ? (
                <a href={item.body} target="_blank">
                  {item.headline}
                </a>
              ) : (
                <Link to={`/news/${item.newsId}`}>{item.headline}</Link>
              )
            }
            description={
              <Space>
                <div style={{ color: "darkblue" }}>
                  {item.provider.charAt(0).toUpperCase() + item.provider.slice(1).toLowerCase()}{" "}
                </div>
                <div>
                  {/* {moment(item.createdOn).startOf("ss").fromNow()} */}
                  {item.createdOn.split(" ")[0]} {} {item.createdOn.split(" ")[1].slice(0, 5)}
                </div>
              </Space>
            }
          />
        </List.Item>
      )}
    />
  );

  return (
    <>
      {/* <Title level={4} style={{ marginLeft: "10px" }}>
        {title}
      </Title>
      <Divider style={{ borderColor: "darkblue", marginTop: "-1px" }} /> */}
      {result?.isFetching ? <Loader /> : outputList(result?.data)}
    </>
  );
};

export default NewsProvider;
