import React from "react";
import { Row, Col, Table } from "antd";
import millify from "millify";
import { Loader } from "../components";
import { Link } from "react-router-dom";
import { useIpoUpcomingQuery } from "../services/orbisApi";

const IPOUpcoming = () => {
  const { data, isFetching, isSuccess } = useIpoUpcomingQuery();

  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      align: "center",
      width: "10%",
      render: (text) => <Link to={"/stockdetail/" + text}>{text}</Link>,
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      align: "left",
      width: "40%",
      responsive: ["xl"],
    },
    {
      title: "IPO Date",
      dataIndex: "ipoDate",
      key: "ipoDate",
      align: "center",
      width: "10%",
      render: (text) => <div>{text.split(" ")[0]}</div>,
    },
    {
      title: "Lockup Period",
      dataIndex: "lockupPeriod",
      key: "lockupPeriod",
      align: "center",
      width: "10%",
      render: (text) => <div>{text} Days</div>,
    },
    {
      title: "Price Range",
      dataIndex: "priceRange",
      key: "priceRange",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <div>
          ${record.offerPriceMin} ~ ${record.offerPriceMax}
        </div>
      ),
    },
    {
      title: "Offer Size",
      dataIndex: "offerSize",
      key: "offerSize",
      align: "right",
      width: "10%",
      sorter: (a, b) => a.offerSize - b.offerSize,
      render: (text) => <div>{millify(text)}M</div>,
    },
    {
      title: "Offer Share",
      dataIndex: "offerShares",
      key: "offerShares",
      align: "right",
      width: "10%",
      sorter: (a, b) => a.offerShares - b.offerShares,
      render: (text) => <div>{text}M</div>,
    },
  ];

  const dataSource = data?.map((x) => ({
    key: x.symbol,
    symbol: x.symbol,
    name: x.companyName,
    ipoDate: x.ipoDate,
    lockupPeriod: x.lockupPeriod,
    offerSize: x.offerSize,
    offerShares: x.offerShares,
    offerPriceMax: x.offerPriceMax,
    offerPriceMin: x.offerPriceMin,
  }));

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <Row style={{ marginBottom: "15px" }} className="block-container">
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            {isSuccess && <Table columns={columns} dataSource={dataSource} size="small" pagination={false} />}
          </Col>
        </Row>
      )}
    </>
  );
};

export default IPOUpcoming;
