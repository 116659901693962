import React from "react";
import { Card, Typography, List, Space } from "antd";
import moment from "moment";
import { Loader } from "../components";
import { Link } from "react-router-dom";
import { useListNewsQuery } from "../services/orbisApi";

const { Title, Text } = Typography;

const HomeNews = () => {
  const news = useListNewsQuery({
    start: 0,
    count: 10,
    filter: `provider:BENZINGA,BIZWIRE_,FLYWALL_,PWS,PRIMZONE,SEEKINGALPHA`,
  });

  return (
    <>
      <Card hoverable={true}>
        <Title level={4}>News</Title>
        {news.isFetching ? (
          <Loader />
        ) : (
          <List
            // header={<Title level={4}>News</Title>}
            footer={
              <div style={{ textAlign: "right" }}>
                <Link to={"/news"}>Read more...</Link>
              </div>
            }
            itemLayout="horizontal"
            size="default"
            dataSource={news?.data}
            split={false}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    item.linkOnly ? (
                      <a href={item.body} target="_blank">
                        {item.headline}
                      </a>
                    ) : (
                      <Link to={`/news/${item.newsId}`}>{item.headline}</Link>
                    )
                  }
                  description={
                    <Space>
                      <div style={{ color: "darkblue" }}>
                        {item.provider.charAt(0).toUpperCase() + item.provider.slice(1).toLowerCase()}{" "}
                      </div>
                      <div>{moment(item.createdOn).startOf("ss").fromNow()}</div>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        )}
      </Card>
    </>
  );
};

export default HomeNews;
