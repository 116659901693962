import React from "react";
import { Routes, Route } from "react-router-dom";
import { IPORecent, IPOUpcoming, IPOPerformance } from "../components";
import IPOLayout from "../hocs/IPOLayout";

const IPOcenter = () => {
  return (
    <IPOLayout>
      <Routes>
        <Route exact path="/" element={<IPORecent />} />
        <Route exact path="/ipoupcoming" element={<IPOUpcoming />} />
        <Route exact path="/ipoperformance" element={<IPOPerformance />} />
      </Routes>
    </IPOLayout>
  );
};

export default IPOcenter;
