import React from "react";
import { Button, Col, Row, Card, Form, Input, Modal } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { useResetPasswordConfirmMutation } from "../services/authApi";

const ResetPasswordConfirm = () => {
  const [form] = Form.useForm();
  const { uid, token } = useParams();
  const [confirmPass, confirmPassResult] = useResetPasswordConfirmMutation();
  const navigate = useNavigate();

  const onFinish = async (passInfo) => {
    const confirmInfo = { uid, token, ...passInfo };
    const result = await confirmPass(confirmInfo);
    form.resetFields();

    if (result.error) {
      console.log(result.error.data);
      showFailModal(result.error.data);
    } else {
      showSuccessModal("Password changed, please use your new password to login.");
      navigate("/login");
    }
  };

  const showSuccessModal = (text) => {
    Modal.info({
      title: "RESET PASSWORD SUCCESS",
      content: text,
    });
  };

  const showFailModal = (text) => {
    Modal.error({
      title: "RESET PASSWORD FAIL",
      content: (
        <>
          <ul>
            {text.uid ? <li>{text.uid}</li> : ""}
            {text.new_password ? text.new_password.map((x) => <li>{x}</li>) : ""}
          </ul>
        </>
      ),
    });
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={24} lg={24} xl={8} xxl={8}></Col>
        <Col
          xs={24}
          sm={24}
          lg={24}
          xl={8}
          xxl={8}
          style={{
            height: "80vh",
            width: "100%",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card hoverable={true}>
            <Form form={form} size="large" name="resetPassword" onFinish={onFinish}>
              <Form.Item
                name="new_password"
                rules={[{ required: true, message: "Please input your Password!" }]}
                hasFeedback
              >
                <Input prefix={<LockOutlined style={{ color: "gray" }} />} type="password" placeholder="Password" />
              </Form.Item>

              <Form.Item
                name="re_new_password"
                dependencies={["new_password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("The two passwords that you entered do not match!"));
                    },
                  }),
                ]}
              >
                <Input
                  prefix={<LockOutlined style={{ color: "gray" }} />}
                  type="password"
                  placeholder="Confirm Password"
                />
              </Form.Item>
              <Form.Item>
                <Button block type="primary" htmlType="submit" className="login-form-button">
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} lg={24} xl={8} xxl={8}></Col>
      </Row>
    </>
  );
};

export default ResetPasswordConfirm;
