import React, { useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { PieChartOutlined, DotChartOutlined, LineChartOutlined } from "@ant-design/icons";

const IPOLayout = (props) => {
  const [menuSelected, setMenuSelected] = useState("Recent IPO");
  return (
    <>
      <Menu
        onClick={(e) => setMenuSelected(e.key)}
        selectedKeys={[menuSelected]}
        style={{ marginBottom: "15px" }}
        theme="light"
        mode="horizontal"
      >
        <Menu.Item key="Recent IPO" icon={<PieChartOutlined />}>
          <Link to="/ipocenter">Recent IPO</Link>
        </Menu.Item>
        <Menu.Item key="Upcoming IPO" icon={<DotChartOutlined />}>
          <Link to="/ipocenter/ipoupcoming">Upcoming IPO</Link>
        </Menu.Item>
        <Menu.Item key="IPO Performance" icon={<LineChartOutlined />}>
          <Link to="/ipocenter/ipoperformance">IPO Performance</Link>
        </Menu.Item>
      </Menu>
      {props.children}
    </>
  );
};

export default IPOLayout;
