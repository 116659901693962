import React from "react";
import { useSelector } from "react-redux";
import { AuthorizationPage, Working } from "../components";

const AccountActivity = () => {
    const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);
    return <>{!isAuthenticated ? <AuthorizationPage /> : <Working />}</>;
};

export default AccountActivity;
