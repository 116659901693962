import React from "react";
import millify from "millify";
import { Descriptions, Typography } from "antd";
import { SnippetsOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";

const { Title, Text } = Typography;
const { Item } = Descriptions;

const StockDetailRight = ({ quote, company }) => {
  return (
    <>
      <Descriptions
        column={1}
        bordered={false}
        layout="vertical"
        labelStyle={{ margin: "15px 10px 0px 10px", fontWeight: "500", color: blue[6] }}
        contentStyle={{
          margin: "0px 10px 8px 10px",
          fontWeight: "400",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {/* {company?.businessSummary && <Item label="Introduction">{company?.businessSummary}</Item>} */}
        {/* <Item label="Capital History">{company?.dividendsPaid?.text}</Item> */}
        {company?.longSummary && <Item label="Introduction">{company?.longSummary}</Item>}
        {/* <Item label="Business summary (CN)">{company?.summaries.zh}</Item> */}
        {/* <Item label="Stock Split">{company?.stockSummary?.STOCK_SPLITS?.text}</Item> */}
      </Descriptions>
      <Descriptions
        column={1}
        bordered={false}
        labelStyle={{ margin: "0px 0px 8px 10px", fontWeight: "500", color: blue[6] }}
        contentStyle={{
          margin: "0px 10px 8px 0px",
          fontWeight: "500",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {/* <Item label="Ticker">{company?.issuePrimaryTicker}</Item> */}
        <Item label="Symbol">{quote?.symbol}</Item>
        <Item label="ISIN">{quote?.isin}</Item>
        <Item label="CUSIP">{quote?.cusip}</Item>
        {company?.url && (
          <Item label="Website">
            <a href={company?.url} target="_blank">
              {company?.url.length < 25 ? company?.url : company?.url.slice(0, 25) + "..."}
            </a>
          </Item>
        )}
        {company?.email && (
          <Item label="Email">
            <a href={"mailto:" + company?.email} target="_blank">
              {company?.email.length < 25 ? company?.email : company?.email.slice(0, 25) + "..."}
            </a>
          </Item>
        )}
        {company?.fax && <Item label="FAX">{company?.fax}</Item>}
        {company?.phone && <Item label="Phone">{company?.phone}</Item>}
        {company?.employeeCount && <Item label="Employees">{company?.employeeCount}</Item>}
        {company?.incorpCountry && <Item label="Country">{company?.incorpCountry}</Item>}
        {company?.incorpDate && <Item label="Setup date">{company?.incorpDate?.split(" ")[0]}</Item>}
        {company?.fidelityIndustry && <Item label="Industry">{company?.fidelityIndustry}</Item>}
        {company?.fidelitySector && <Item label="Sector">{company?.fidelitySector}</Item>}
        {company?.industryRanking?.marketCap && (
          <Item label="Market cap">{millify(company?.industryRanking?.marketCap)}</Item>
        )}
        {company?.industryMarketCap && <Item label="Industry Market cap">{millify(company?.industryMarketCap)}</Item>}
        {company?.issuePrimaryExchange && <Item label="Primary Exchange">{company?.issuePrimaryExchange}</Item>}
        {company?.shareHolderCount && <Item label="Share holders">{company?.shareHolderCount}</Item>}
      </Descriptions>
    </>
  );
};

export default StockDetailRight;
