import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    // prepareHeaders: (headers, { getState }) => {
    //   const token = getState().auth.token;
    //   if (token) {
    //     headers.set("authorization", `JWT ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (builder) => ({
    // ==== login ====
    login: builder.mutation({
      query: (loginInfo) => ({
        url: "/auth/jwt/create/",
        method: "POST",
        body: loginInfo,
      }),
    }),

    // ==== checkAuthenticated ====
    checkAuthenticated: builder.mutation({
      query: (access) => ({
        url: "/auth/jwt/verify/",
        method: "POST",
        body: { token: access },
      }),
    }),

    // ==== loadUser ====
    loadUser: builder.mutation({
      query: (access) => ({
        url: "/auth/users/me/",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `JWT ${access}`,
        },
      }),
    }),

    // ==== Signup ====
    signUp: builder.mutation({
      query: (signupInfo) => ({
        url: "/auth/users/",
        method: "POST",
        body: signupInfo,
      }),
    }),

    // ==== Activation ====
    activation: builder.mutation({
      query: (activationInfo) => ({
        url: "/auth/users/activation/",
        method: "POST",
        body: activationInfo,
      }),
    }),

    // ==== Reset Password ====
    resetPassword: builder.mutation({
      query: (email) => ({
        url: "/auth/users/reset_password/",
        method: "POST",
        body: email,
      }),
    }),

    // ==== Reset Password Confirm ====
    resetPasswordConfirm: builder.mutation({
      query: (resetPasswordConfirmInfo) => ({
        url: "/auth/users/reset_password_confirm/",
        method: "POST",
        body: resetPasswordConfirmInfo,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useCheckAuthenticatedMutation,
  useLoadUserMutation,
  useSignUpMutation,
  useActivationMutation,
  useResetPasswordMutation,
  useResetPasswordConfirmMutation,
} = authApi;
