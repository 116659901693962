import React from "react";
import millify from "millify";
import { Table, Tag, Typography } from "antd";
import { Link } from "react-router-dom";

const ETFListTable = ({ title, topTen }) => {
  const columns = [
    {
      title: "LV",
      dataIndex: "leverage",
      key: "leverage",
      align: "center",
      width: "5%",
      render: (text) => {
        let color = text >= 3 ? "magenta" : text >= 2 ? "orange" : "geekblue";
        return <Tag color={color}>x{text}</Tag>;
      },
    },
    {
      title: "SYMBOL",
      dataIndex: "symbol",
      key: "symbol",
      align: "center",
      width: "12%",
      render: (text) => <Link to={"/stockdetail/" + text}>{text}</Link>,
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: "35%",
      responsive: ["lg"],
      render: (text, record) => (
        <a href={record.url} target="_blank">
          {text}
        </a>
      ),
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
      responsive: ["lg"],
      ellipsis: true,
      width: "12%",
    },
    {
      title: "LAST",
      dataIndex: "last",
      key: "last",
      align: "center",
      width: "12%",
      render: (text) => <div>${text}</div>,
    },
    {
      title: "CHANGE",
      dataIndex: "change",
      key: "change",
      align: "center",
      responsive: ["sm"],
      width: "12%",
      sorter: (a, b) => a.change - b.change,
      render: (text) => {
        let color = text > 0 ? "green" : text < 0 ? "red" : "black";
        return <div style={{ color }}>{text.toFixed(2)}%</div>;
      },
    },
    {
      title: "VOLUME",
      dataIndex: "volume",
      key: "volume",
      align: "center",
      responsive: ["sm"],
      width: "12%",
      sorter: (a, b) => a.volume - b.volume,
      render: (text) => millify(text),
    },
    // {
    //   title: "MARKET CAP",
    //   dataIndex: "marketcap",
    //   key: "marketcap",
    //   sorter: (a, b) => a.marketcap - b.marketcap,
    //   render: (text) => millify(text),
    // },
  ];

  const data = topTen?.map((x) => ({
    key: x.symbol,
    leverage: x.etf.leverage,
    symbol: x.symbol,
    name: x.quote.companyName,
    category: x.etf.category,
    last: x.quote.lastPrice,
    change: x.quote.changePercent,
    volume: x.quote.volume,
    marketcap: x.quote.marketCap,
    url: x.etf.url,
  }));

  return (
    <div className="block-container">
      <Typography.Title level={4} style={{ margin: "10px 0px" }}>
        {title}
      </Typography.Title>
      <Table columns={columns} dataSource={data} size="small" pagination={false} />
    </div>
  );
};

export default ETFListTable;
