import React, { useState, useEffect } from "react";
import moment from "moment";
import millify from "millify";
import { Radio, Descriptions, Spin, Row, Col, Typography, Skeleton, Space, Tag } from "antd";
import { StarOutlined, CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
import { blue, gold } from "@ant-design/colors";
import { Loader } from ".";
import {
    useEquityHistoryQuery,
    useIntradayChartQuery,
    useEquityQuoteQuery,
    useCompanyResearchQuery,
} from "../services/orbisApi";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";

const { Item } = Descriptions;

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const TradingChart = ({ selectedSymbol }) => {
    const [timePeriod, setTimePeriod] = useState(moment().subtract(1, "years").format("MM/DD/YYYY"));
    const [selected, setSelected] = useState("1Y");
    const [intraFrom, setIntraFrom] = useState(moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss"));
    const [intraTo, setIntraTo] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
    const [myLabel, setMyLabel] = useState();
    const [myData, setMyData] = useState();
    const [myVolume, setMyVolume] = useState();

    const company = useCompanyResearchQuery({ selectedSymbol });
    const quote = useEquityQuoteQuery({ symbols: selectedSymbol });

    const historical = useEquityHistoryQuery({
        symbol: selectedSymbol,
        start: timePeriod,
        end: moment().format("MM/DD/YYYY"),
        range: "",
        wetWeekly: false,
        rsiSpan: 1,
    });

    const intraday = useIntradayChartQuery({
        symbol: selectedSymbol,
        rsiSpan: "",
        from: intraFrom,
        to: intraTo,
        rawVolumes: false,
    });

    let onSelectionHandler = (value) => {
        switch (value) {
            case "1D":
                setSelected("1D");
                // setIntraFrom(moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss"));
                // setIntraTo(moment().format("YYYY-MM-DD HH:mm:ss"));
                break;
            case "5D":
                setSelected("5D");
                // setIntraFrom(moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss"));
                // setIntraTo(moment().format("YYYY-MM-DD HH:mm:ss"));
                break;
            case "1M":
                setTimePeriod(moment().subtract(1, "months").format("MM/DD/YYYY"));
                setSelected("1M");
                break;
            case "3M":
                setTimePeriod(moment().subtract(3, "months").format("MM/DD/YYYY"));
                setSelected("3M");
                break;
            case "6M":
                setTimePeriod(moment().subtract(6, "months").format("MM/DD/YYYY"));
                setSelected("6M");
                break;
            case "1Y":
                setTimePeriod(moment().subtract(1, "years").format("MM/DD/YYYY"));
                setSelected("1Y");
                break;
            case "5Y":
                setTimePeriod(moment().subtract(5, "years").format("MM/DD/YYYY"));
                setSelected("5Y");
                break;
            case "10Y":
                setTimePeriod(moment().subtract(10, "years").format("MM/DD/YYYY"));
                setSelected("10Y");
                break;
        }
    };

    useEffect(() => {
        if (selected === "1D") {
            let oneDay = intraday?.data?.filter((item, index, array) => {
                return item.date >= intraday?.data[intraday?.data?.length - 1]["date"].split(" ")[0];
            });
            setMyLabel(oneDay?.map((x) => moment(x.date).format("MM/DD HH:mm")));
            setMyData(oneDay?.map((x) => x.price.toFixed(2)));
            setMyVolume(oneDay?.map((x) => x.volume));
        } else if (selected === "5D") {
            setMyLabel(intraday?.data?.map((x) => moment(x.date).format("MM/DD HH:mm")));
            setMyData(intraday?.data?.map((x) => x.price.toFixed(2)));
            setMyVolume(intraday?.data?.map((x) => x.volume));
        } else {
            setMyLabel(historical?.data?.map((x) => moment(x.date).format("YYYY-MM-DD")));
            setMyData(historical?.data?.map((x) => x.price.toFixed(2)));
            setMyVolume(historical?.data?.map((x) => x.volume));
        }
    }, [historical, selected, intraday]);

    const options = {
        responsive: true,
        interaction: {
            mode: "index",
            intersect: false,
        },
        stacked: false,
        scales: {
            y: {
                type: "linear",
                display: true,
                grace: "10%",
                position: "left",
                ticks: {
                    maxTicksLimit: 10,
                },
            },
            y1: {
                type: "linear",
                display: true,
                position: "right",
                grace: "80%",
                grid: {
                    drawOnChartArea: false,
                },
            },
            x: {
                ticks: {
                    maxTicksLimit: 20,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: "Stock Charts",
            },
        },
    };

    const data = {
        labels: myLabel,
        datasets: [
            {
                type: "line",
                label: selectedSymbol.toUpperCase(),
                data: myData,
                lineTension: 0.15,
                // fill: "origin",
                borderColor: blue[4],
                backgroundColor: blue[5],
                pointRadius: 0,
                yAxisID: "y",
            },
            {
                type: "bar",
                label: "Volume",
                data: myVolume,
                backgroundColor: gold[2],
                yAxisID: "y1",
            },
        ],
    };

    const SelectRange = () => {
        return (
            <>
                <Radio.Group
                    defaultValue="1Y"
                    buttonStyle="solid"
                    size="middle"
                    value={selected}
                    onChange={(e) => onSelectionHandler(e.target.value)}
                >
                    <Radio.Button value="1D">1D</Radio.Button>
                    <Radio.Button value="5D">5D</Radio.Button>
                    <Radio.Button value="1M">1M</Radio.Button>
                    <Radio.Button value="3M">3M</Radio.Button>
                    <Radio.Button value="6M">6M</Radio.Button>
                    <Radio.Button value="1Y">1Y</Radio.Button>
                    <Radio.Button value="5Y">5Y</Radio.Button>
                    <Radio.Button value="10Y">10Y</Radio.Button>
                </Radio.Group>
            </>
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
                {company.isFetching || quote.isFetching ? (
                    <Spin style={{ display: "flex", justifyContent: "center", alignItems: "center" }} />
                ) : (
                    <div>
                        <Row
                            style={{
                                padding: "0px 15px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Col style={{ display: "flex", flexDirection: "column" }}>
                                <Typography.Title level={4} style={{ fontWeight: "900", margin: "0px" }}>
                                    {quote?.data[0]?.companyName.length < 35
                                        ? quote?.data[0]?.companyName
                                        : quote?.data[0]?.companyName.slice(0, 35) + "..."}{" "}
                                    {company?.data?.adr ? <Tag color="volcano">ADR</Tag> : ""}{" "}
                                    {company?.data?.etf ? <Tag color="geekblue">ETF</Tag> : ""}
                                </Typography.Title>
                                <Typography.Title
                                    level={5}
                                    type="secondary"
                                    style={{ fontWeight: "600", margin: "5px 0px" }}
                                >
                                    {quote?.data[0]?.symbol} : {quote?.data[0]?.exchange} ({quote?.data[0]?.currency})
                                </Typography.Title>
                            </Col>
                            <Col style={{ display: "flex", flexDirection: "column" }}>
                                <StarOutlined style={{ fontSize: "160%", margin: "5px" }} />
                            </Col>
                        </Row>

                        <Row
                            style={{ padding: "0px 15px", display: "flex", flexDirection: "row", alignItems: "center" }}
                        >
                            <Col>
                                <Typography.Title
                                    level={4}
                                    style={{
                                        color:
                                            quote?.data[0]?.lastPrice > quote?.data[0]?.yesterdayClose
                                                ? "green"
                                                : "red",
                                        fontWeight: "900",
                                        marginRight: "20px",
                                    }}
                                >
                                    {quote?.data[0]?.lastPrice}
                                </Typography.Title>
                            </Col>
                            <Col>
                                {quote?.data[0]?.lastPrice > quote?.data[0]?.yesterdayClose ? (
                                    <CaretUpFilled style={{ color: "green", fontSize: "150%" }} />
                                ) : (
                                    <CaretDownFilled style={{ color: "red", fontSize: "150%" }} />
                                )}
                            </Col>
                            <Col style={{ display: "flex", flex: "1" }}>
                                <Typography.Title
                                    level={5}
                                    style={{
                                        color:
                                            quote?.data[0]?.lastPrice > quote?.data[0]?.yesterdayClose
                                                ? "green"
                                                : "red",
                                        fontWeight: "900",
                                        marginRight: "20px",
                                    }}
                                >
                                    {quote?.data[0]?.change} ({quote?.data[0]?.changePercent.toFixed(2)}%)
                                </Typography.Title>
                            </Col>
                        </Row>
                    </div>
                )}

                {historical.isFetching ? (
                    <Spin
                        style={{ height: "320px", display: "flex", justifyContent: "center", alignItems: "center" }}
                    />
                ) : (
                    <div>
                        <SelectRange />
                        <div style={{ margin: "10px" }}>
                            <Chart options={options} data={data} />
                        </div>
                    </div>
                )}

                {company.isFetching || quote.isFetching ? (
                    <Spin
                        style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}
                    />
                ) : (
                    <Row style={{ marginTop: "20px", padding: "5px 0px 10px 15px" }}>
                        <Descriptions
                            column={{ xs: 1, sm: 1, lg: 1, xl: 2, xxl: 3 }}
                            size="small"
                            labelStyle={{
                                color: blue[7],
                                margin: "4px 0px",
                                fontSize: "13px",
                                fontWeight: "400",
                                borderBottom: "#d9d9d9 1px solid",
                            }}
                            contentStyle={{
                                margin: "4px 0px",
                                fontSize: "13px",
                                fontWeight: "600",
                                display: "flex",
                                justifyContent: "flex-end",
                                marginRight: "35px",
                                borderBottom: "#d9d9d9 1px solid",
                            }}
                        >
                            <Item label="Open">${quote?.data[0]?.openingPrice}</Item>
                            <Item label="Day High">${quote?.data[0]?.dayHigh}</Item>
                            <Item label="Day Low">${quote?.data[0]?.dayLow}</Item>
                            <Item label="52W High">${quote?.data[0]?.high52week}</Item>
                            <Item label="52W Low">${quote?.data[0]?.low52week}</Item>
                            <Item label="Yesterday Close">${quote?.data[0]?.yesterdayClose}</Item>
                            <Item label="Yesterday Vol.">{millify(quote?.data[0]?.yesterdayVolume)}</Item>
                            <Item label="Market Cap">${millify(quote?.data[0]?.marketCap)}</Item>
                            <Item label="Market Cap Size">{quote?.data[0]?.marketCapClassification}</Item>
                            <Item label="30days Avg Vol.">{millify(quote?.data[0]?.averageVolume30)}</Item>
                            <Item label="Outstanding">{millify(quote?.data[0]?.sharesOutstanding)}</Item>
                            <Item label="Beta">{quote?.data[0]?.beta}</Item>
                            <Item label="PE Ratio">{quote?.data[0]?.priceEarningRatio.toFixed(2)}%</Item>
                            <Item label="EPS (12months)">{quote?.data[0].trailing12MonthsEps.toFixed(2)}</Item>
                        </Descriptions>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default TradingChart;
