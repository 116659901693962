import React from "react";
import { Row, Col, Table } from "antd";
import millify from "millify";
import { Link } from "react-router-dom";
import { Loader } from "../components";
import { useIpoRecentQuery } from "../services/orbisApi";

const IPORecent = () => {
  const { data, isFetching, isSuccess } = useIpoRecentQuery({
    loadQuotes: true,
    time: "1m",
    sort: "ipoRet",
  });

  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      align: "center",
      width: "8%",
      render: (text) => <Link to={"/stockdetail/" + text}>{text}</Link>,
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      align: "left",
      width: "34%",
      responsive: ["xl"],
    },
    {
      title: "IPO Date",
      dataIndex: "ipoDate",
      key: "ipoDate",
      align: "center",
      width: "10%",
      render: (text) => <div>{text.split(" ")[0]}</div>,
    },
    {
      title: "Offer Price",
      dataIndex: "offerPrice",
      key: "offerPrice",
      align: "right",
      width: "8%",
      sorter: (a, b) => a.offerPrice - b.offerPrice,
      render: (text) => <div>${text.toFixed(2)}</div>,
    },
    {
      title: "Offer Shares",
      dataIndex: "offerShares",
      key: "offerShares",
      align: "right",
      width: "8%",
      sorter: (a, b) => a.offerShares - b.offerShares,
      render: (text) => <div>{text}M</div>,
    },
    {
      title: "Day 1",
      dataIndex: "dayOne",
      key: "dayOne",
      align: "right",
      width: "8%",
      sorter: (a, b) => a.dayOne - b.dayOne,
      render: (text) => {
        let color = text > 0 ? "green" : text < 0 ? "red" : "black";
        return <div style={{ color }}>{millify(text)}%</div>;
      },
    },
    {
      title: "IPO return",
      dataIndex: "ipoReturn",
      key: "ipoReturn",
      align: "right",
      width: "8%",
      responsive: ["lg"],
      sorter: (a, b) => a.ipoReturn - b.ipoReturn,
      render: (text) => {
        let color = text > 0 ? "green" : text < 0 ? "red" : "black";
        return <div style={{ color }}>{millify(text)}%</div>;
      },
    },
    {
      title: "Last Price",
      dataIndex: "lastPrice",
      key: "lastPrice",
      align: "right",
      width: "8%",
      responsive: ["lg"],
      sorter: (a, b) => a.lastPrice - b.lastPrice,
      render: (text) => <div>${text.toFixed(2)}</div>,
    },
    {
      title: "Change",
      dataIndex: "change",
      key: "change",
      align: "right",
      width: "8%",
      responsive: ["lg"],
      sorter: (a, b) => a.change - b.change,
      render: (text) => {
        let color = text > 0 ? "green" : text < 0 ? "red" : "black";
        return <div style={{ color }}>{millify(text)}%</div>;
      },
    },
  ];

  const dataSource = data?.map((x) => ({
    key: x.symbol,
    symbol: x.symbol,
    name: x.quote.companyName,
    ipoDate: x.ipoDate,
    offerPrice: x.offerPrice,
    offerShares: x.offerShares,
    dayOne: x.firstDayReturn,
    ipoReturn: x.totalReturn,
    lastPrice: x.quote.lastPrice,
    change: x.quote.changePercent,
  }));

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <Row style={{ marginBottom: "15px" }} className="block-container">
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            {isSuccess && <Table columns={columns} dataSource={dataSource} size="small" pagination={false} />}
          </Col>
        </Row>
      )}
    </>
  );
};

export default IPORecent;
