import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const analysisApi = createApi({
  reducerPath: "analysisApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
  }),
  endpoints: (builder) => ({
    // ==== position ====
    position: builder.mutation({
      query: ({ account, server }) => ({
        url: `/analysis/position`,
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        params: {
          account: account,
          server: server,
        },
      }),
    }),

    // ==== All Accounts ====
    allAccounts: builder.query({
      query: ({ isempty }) => ({
        url: `/analysis/allaccounts`,
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        params: {
          isempty: isempty,
        },
      }),
    }),

    // ==== All Accounts (Mutation) ====
    allAccountsMu: builder.mutation({
      query: ({ isempty }) => ({
        url: `/analysis/allaccounts`,
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        params: {
          isempty: isempty,
        },
      }),
    }),

    // ==== All Accounts Value (Mutation) ====
    allAccountsValueMu: builder.mutation({
      query: () => ({
        url: "/analysis/allaccountsvalue",
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }),
    }),

    // ==== One Account Value (Mutation) ====
    accountValueMu: builder.mutation({
      query: ({ account }) => ({
        url: `/analysis/accountvalue`,
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        params: {
          account: account,
        },
      }),
    }),

    // ==== All Accounts Value ====
    allAccountsValue: builder.query({
      query: () => ({
        url: "/analysis/allaccountsvalue",
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }),
    }),

    // ==== History Positions ====
    historyPositions: builder.mutation({
      query: ({ omnibusaccount, startdate, enddate, db }) => ({
        url: "/analysis/historypositions",
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        params: {
          omnibusaccount,
          startdate,
          enddate,
          db,
        },
      }),
    }),

    // ==== History Positions Quarterly====
    historyPositionsQuarter: builder.mutation({
      query: ({ omnibusaccount, startdate, enddate }) => ({
        url: "/analysis/historypositionsquarter",
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        params: {
          omnibusaccount,
          startdate,
          enddate,
        },
      }),
    }),

    // ==== ETF List ====
    etfList: builder.query({
      query: ({ date }) => ({
        url: "/analysis/etflist",
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        params: {
          date,
        },
      }),
    }),

    // ==== ETF Compare ====
    etfCompare: builder.mutation({
      query: ({ date1, date2 }) => ({
        url: "/analysis/etfcompare",
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        params: {
          date1,
          date2,
        },
      }),
    }),
  }),
});

export const {
  usePositionMutation,
  useHistoryPositionsMutation,
  useHistoryPositionsQuarterMutation,
  useAllAccountsQuery,
  useAllAccountsMuMutation,
  useAllAccountsValueQuery,
  useAccountValueMuMutation,
  useAllAccountsValueMuMutation,
  useEtfListQuery,
  useEtfCompareMutation,
} = analysisApi;
