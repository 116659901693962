import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Checkbox, Row, Col, Card, Divider, Space, Modal } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useLoginMutation, useLoadUserMutation } from "../services/authApi";
import { loginSuccess, logout } from "../features/authSlice";
import { useNavigate } from "react-router-dom";
import { userLoadSuccess, userLoadFail } from "../features/authSlice";

const Login = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);
    const [login, loginResult] = useLoginMutation();
    const [loadUser, lodUserResult] = useLoadUserMutation();

    if (isAuthenticated) {
        navigate("/");
    }

    const onFinish = async (loginInfo) => {
        const result = await login(loginInfo);

        const userResult = await loadUser(result.data.access);
        if (userResult.error) {
            dispatch(userLoadFail());
        } else {
            dispatch(userLoadSuccess(userResult.data));
        }

        if (result.data) {
            dispatch(loginSuccess(result.data));
            navigate("/");
        } else {
            dispatch(logout());
            showModal(result.error.data.detail);
        }

        form.resetFields();
    };

    const showModal = (text) => {
        Modal.error({
            title: "LOGIN FAIL",
            content: text,
        });
    };

    return (
        <>
            <Row>
                <Col xs={24} sm={24} lg={24} xl={8} xxl={8}></Col>
                <Col
                    xs={24}
                    sm={24}
                    lg={24}
                    xl={8}
                    xxl={8}
                    style={{
                        // border: "1px solid",
                        height: "80vh",
                        width: "100%",
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        // alignItems: "center",
                    }}
                >
                    <Card hoverable={true}>
                        <Form
                            form={form}
                            size="large"
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        type: "email",
                                        message: "The input is not valid E-mail!",
                                    },
                                    {
                                        required: true,
                                        message: "Please input your E-mail!",
                                    },
                                ]}
                            >
                                <Input prefix={<MailOutlined style={{ color: "gray" }} />} placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: "Please input your Password!" }]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            {/* <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
              </Form.Item> */}
                            <Form.Item>
                                <Button block type="primary" htmlType="submit" className="login-form-button">
                                    Login
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <div>
                                    {/* Don't have an account? <Link to="/signup">Register now</Link> */}
                                    Don't have an account? <Link to="">Register now</Link>
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    Forget your password? <Link to="/resetpassword">Forgot password</Link>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col xs={24} sm={24} lg={24} xl={8} xxl={8}></Col>
            </Row>
        </>
    );
};

export default Login;
