import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAllAccountsQuery } from "../services/analysisApi";
import { Typography, Table, Card, Input, Spin } from "antd";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { AuthorizationPage } from "../components";
import { Link } from "react-router-dom";

const { Title } = Typography;

const AllAccounts = () => {
    const [page, setPage] = useState(1);
    const [inputItem, setInputItem] = useState("");
    const [searchedData, setSearchedData] = useState();
    const { data, isFetching, isLoading, isSuccess } = useAllAccountsQuery({ isempty: "" });
    const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);

    useEffect(() => {
        const filterData = data?.filter((x) => {
            return (
                x.name.toLowerCase().includes(inputItem.toLowerCase()) ||
                x.account.toLowerCase().includes(inputItem.toLowerCase())
            );
        });
        setSearchedData(filterData);
    }, [inputItem, data]);

    console.log(searchedData);

    const columns = [
        {
            title: "No.",
            dataIndex: "No",
            key: "No",
            align: "center",
            width: "5%",
            render: (value, item, index) => {
                return (page - 1) * 20 + (index + 1);
            },
        },
        {
            title: "Account",
            dataIndex: "account",
            key: "account",
            align: "center",
            width: "15%",
            sorter: (a, b) => a.account.localeCompare(b.account),
            render: (text, record) => {
                if (record.isempty == true) {
                    return <div style={{ color: "grey" }}>{text}</div>;
                } else {
                    return (
                        <Link to="/Position" state={{ account: text, server: record.db }}>
                            {text}
                        </Link>
                    );
                }
            },
        },
        {
            title: "Company Name",
            dataIndex: "name",
            key: "account",
            align: "left",
            width: "50%",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "Server",
            dataIndex: "db",
            key: "db",
            align: "center",
            width: "15%",
            sorter: (a, b) => a.db.localeCompare(b.db),
        },
        {
            title: "Empty Account",
            dataIndex: "isempty",
            key: "isempty",
            align: "center",
            width: "15",
            sorter: (a, b) => a.isempty - b.isempty,
            render: (text) => {
                if (text == true) {
                    return <ExclamationCircleOutlined style={{ fontSize: "16px", color: "red" }} />;
                } else {
                    return <CheckCircleOutlined style={{ fontSize: "16px", color: "green" }} />;
                }
            },
        },
    ];

    const dataSource = searchedData?.map((x) => ({
        key: x.account + x.name + x.db,
        account: x.account,
        name: x.name,
        db: x.db,
        isempty: x.isempty,
    }));

    return (
        <>
            {!isAuthenticated ? (
                <AuthorizationPage />
            ) : (
                <Card>
                    <Spin spinning={isFetching} tip="Loading...">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Title level={4} style={{ marginLeft: "5px" }}>
                                All Accounts
                            </Title>
                            <Input.Search
                                placeholder="Search company name"
                                size="middle"
                                value={inputItem}
                                onChange={(e) => setInputItem(e.target.value)}
                                allowClear
                                style={{ width: 300 }}
                            />
                        </div>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            size="small"
                            pagination={{
                                onChange(current) {
                                    setPage(current);
                                },
                                position: ["topRight", "bottomRight"],
                                defaultPageSize: 20,
                            }}
                        />
                    </Spin>
                </Card>
            )}
        </>
    );
};

export default AllAccounts;
