import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { BlockOutlined, AppstoreOutlined, AppstoreAddOutlined } from "@ant-design/icons";

const ETFLayout = (props) => {
  const [menuSelected, setMenuSelected] = useState("ETF Overview");
  return (
    <>
      <Menu
        onClick={(e) => setMenuSelected(e.key)}
        selectedKeys={[menuSelected]}
        style={{ marginBottom: "15px" }}
        theme="light"
        mode="horizontal"
      >
        <Menu.Item key="ETF Overview" icon={<AppstoreOutlined />}>
          <Link to="/etfcenter">ETF Overview</Link>
        </Menu.Item>
        <Menu.Item key="ETF Screener" icon={<AppstoreAddOutlined />}>
          <Link to="/etfcenter/etfscreener">ETF Screener</Link>
        </Menu.Item>
        <Menu.Item key="ETF Sector" icon={<BlockOutlined />}>
          <Link to="/etfcenter/etfsector">ETF Sector</Link>
        </Menu.Item>
        {/* <Menu.Item key="ETF Details" icon={<BlockOutlined />}>
          <Link to="/etfcenter/etfdetails">ETF Details</Link>
        </Menu.Item> */}
      </Menu>
      {props.children}
    </>
  );
};

export default ETFLayout;
