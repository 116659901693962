import { configureStore, combineReducers } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { orbisApi } from "../services/orbisApi";
import { finMindApi } from "../services/finMindApi";
import { analysisApi } from "../services/analysisApi";
import { authApi } from "../services/authApi";
import { onboardingApi } from "../services/onboardingApi";
import authSlice from "../features/authSlice";
import productMasterReducer from "../features/productMasterSlice";

// const reducer = combineReducers({
//     [orbisApi.reducerPath]: orbisApi.reducer
// })

export default configureStore({
  reducer: {
    [orbisApi.reducerPath]: orbisApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [analysisApi.reducerPath]: analysisApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    auth: authSlice,
    productMaster: productMasterReducer,

    // [finMindApi.reducerPath]: finMindApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(onboardingApi.middleware)
      .concat(orbisApi.middleware)
      .concat(authApi.middleware)
      .concat(analysisApi.middleware),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
