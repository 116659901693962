import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "antd";
import { MySidebar, MyFooter, MyHeader } from "../components";
import { useCheckAuthenticatedMutation, useLoadUserMutation } from "../services/authApi";
import { authenticatedSuccess, authenticatedFail, userLoadSuccess, userLoadFail } from "../features/authSlice";
import "../App.css";

const MyLayout = (props) => {
  const dispatch = useDispatch();
  const [checkAuthenticated, checkAuthenticatedResult] = useCheckAuthenticatedMutation();
  const [loadUser, loadUserResult] = useLoadUserMutation();

  useEffect(() => {
    const accessChecking = async () => {
      if (!localStorage.getItem("access")) {
        dispatch(authenticatedFail());
        dispatch(userLoadFail());
      } else {
        const result = await checkAuthenticated(localStorage.getItem("access"));

        if (result.error) {
          dispatch(authenticatedFail());
        } else {
          dispatch(authenticatedSuccess());
        }

        const loadResult = await loadUser(localStorage.getItem("access"));
        if (loadResult.error) {
          dispatch(userLoadFail());
        } else {
          dispatch(userLoadSuccess(loadResult.data));
        }
      }
    };
    accessChecking();
  }, []);

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <MySidebar />
        <Layout className="site-layout">
          <MyHeader />
          {props.children}
          <MyFooter />
        </Layout>
      </Layout>
    </>
  );
};

export default MyLayout;
