import React, { useState } from "react";
import { Loader } from "../components";
import { Link } from "react-router-dom";
import { Table, Typography, Card, List, Radio, Tooltip } from "antd";
import { CaretUpFilled, CaretDownFilled, EllipsisOutlined } from "@ant-design/icons";
import { useTopStocksQuery } from "../services/orbisApi";

const { Title, Text } = Typography;

const HomeTopStock = () => {
  const [category, setCategory] = useState("MA");
  const topStock = useTopStocksQuery({
    category: category,
    market: "BIG3",
    order: "H",
    count: "10",
  });

  return (
    <>
      <Card bordered={false} hoverable={true}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "10px" }}>
          <Title level={4}>Top 10 Stocks</Title>
          <Radio.Group
            buttonStyle="solid"
            size={"middle"}
            onChange={(e) => setCategory(e.target.value)}
            value={category}
          >
            <Tooltip title="Most Active">
              <Radio.Button value={"MA"}>Active</Radio.Button>
            </Tooltip>
            <Tooltip title="Most Active by $ Traded">
              <Radio.Button value={"DT"}>Trade</Radio.Button>
            </Tooltip>
            <Tooltip title="Most Active by % Price">
              <Radio.Button value={"MALP"}>Price</Radio.Button>
            </Tooltip>
            {/* <Radio.Button value={"LP"}>Price</Radio.Button> */}
          </Radio.Group>
        </div>
        {topStock?.isFetching ? (
          <Loader />
        ) : (
          <List
            bordered={false}
            itemLayout="horizontal"
            size="small"
            dataSource={topStock?.data}
            split={true}
            renderItem={(item) => (
              <List.Item>
                <Tooltip title={item.quote.companyName} placement="left">
                  <List.Item.Meta
                    title={
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div>
                          <Link to={`/stockdetail/${item.symbol}`}>{item.symbol}</Link>
                        </div>
                        <div
                          style={{
                            fontWeight: 700,
                            color: item.quote.change > 0 ? "green" : item.quote.change < 0 ? "red" : "darkgray",
                          }}
                        >
                          {item?.quote?.change > 0 ? (
                            <CaretUpFilled />
                          ) : item?.quote?.change < 0 ? (
                            <CaretDownFilled />
                          ) : (
                            <EllipsisOutlined />
                          )}{" "}
                          {item.quote.lastPrice.toFixed(2)}
                        </div>
                      </div>
                    }
                    description={
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div>
                          {item?.quote?.companyName?.length <= 25
                            ? item?.quote?.companyName
                            : item?.quote?.companyName?.slice(0, 25) + "..."}
                        </div>
                        <div
                          style={{
                            color: item.quote.change > 0 ? "green" : item.quote.change < 0 ? "red" : "darkgray",
                          }}
                        >
                          {item.quote.change.toFixed(2)} ({item.quote.changePercent.toFixed(2)}%)
                        </div>
                      </div>
                    }
                  />
                </Tooltip>
              </List.Item>
            )}
          />
        )}
      </Card>
    </>
  );
};

export default HomeTopStock;
