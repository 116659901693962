import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Divider, Select } from "antd";
import { Selector, Loader, ETFScreenerTable } from "../components";
import { useEtfInitScreenerQuery, useEtfScreenerQuery } from "../services/orbisApi";

const ETFScreener = () => {
  const [asset, setAsset] = useState(["0", "Any"]);
  const [category, setCategory] = useState(["0", "Any"]);
  const [sponsor, setSponsor] = useState(["0", "Any"]);
  const [inverse, setInverse] = useState("ALL");
  const [leverage, setLeverage] = useState("ALL");

  const { data: SelectorData, isSuccess } = useEtfInitScreenerQuery();
  const { data: EtfScreenerData, isFetching } = useEtfScreenerQuery({
    inverseType: inverse,
    leverageType: leverage,
    assetId: asset[0],
    categoryId: category[0],
    sponsorId: sponsor[0],
    num: "3000",
    sort: "volume",
    asc: true,
    loadIndustries: false,
    loadUpgrades: false,
    loadEarnings: false,
  });

  let assetArr = [];
  let categoryArr = [];
  let sponsorArr = [];
  let leverageArr = [];
  let inverseArr = [];

  if (isSuccess) {
    assetArr = Object.entries(SelectorData?.AssetClasses);
    assetArr.unshift(["0", "Any"]);
    categoryArr = Object.entries(SelectorData?.Categories);
    categoryArr.unshift(["0", "Any"]);
    sponsorArr = Object.entries(SelectorData?.Sponsors).map((x) => [x[0], x[1].name]);
    sponsorArr.unshift(["0", "Any"]);
    leverageArr = SelectorData?.LeverageTypes;
    inverseArr = SelectorData?.InverseTypes;
  }

  return (
    <>
      {isSuccess && (
        <>
          <Row style={{ marginBottom: "15px" }} className="block-container">
            <Col xs={24} sm={24} lg={24} xl={4} xxl={4} style={{ margin: "5px" }}>
              <div style={{ color: "darkgray", margin: "0px 0px 1px 10px" }}>Asset</div>
              <Select
                size="middle"
                placeholder="Asset"
                value={asset[1]}
                style={{ width: "100%" }}
                onChange={(e, { key, value }) => setAsset([key, value])}
              >
                {assetArr.map((x) => (
                  <Select.Option value={x[1]} key={x[0]}>
                    {x[1]}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={24} lg={24} xl={4} xxl={4} style={{ margin: "5px" }}>
              <div style={{ color: "darkgray", margin: "0px 0px 1px 10px" }}>Category</div>
              <Select
                size="middle"
                placeholder="Category"
                value={category[1]}
                style={{ width: "100%" }}
                onChange={(e, { key, value }) => setCategory([key, value])}
              >
                {categoryArr.map((x) => (
                  <Select.Option value={x[1]} key={x[0]}>
                    {x[1]}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={24} lg={24} xl={4} xxl={4} style={{ margin: "5px" }}>
              <div style={{ color: "darkgray", margin: "0px 0px 1px 10px" }}>Sponsor</div>
              <Select
                size="middle"
                placeholder="Sponsor"
                value={sponsor[1]}
                style={{ width: "100%" }}
                onChange={(e, { key, value }) => setSponsor([key, value])}
              >
                {sponsorArr.map((x) => (
                  <Select.Option value={x[1]} key={x[0]}>
                    {x[1]}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col xs={24} sm={24} lg={24} xl={4} xxl={4} style={{ margin: "5px" }}>
              <div style={{ color: "darkgray", margin: "0px 0px 1px 10px" }}>Leverage</div>
              <Select
                size="middle"
                placeholder="Leverage"
                value={leverage}
                style={{ width: "100%" }}
                onChange={(e, { key, value }) => setLeverage(value)}
              >
                {leverageArr.map((x) => (
                  <Select.Option value={x} key={x}>
                    {x}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={24} lg={24} xl={4} xxl={4} style={{ margin: "5px" }}>
              <div style={{ color: "darkgray", margin: "0px 0px 1px 10px" }}>Inverse</div>
              <Select
                size="middle"
                placeholder="Inverse"
                value={inverse}
                style={{ width: "100%" }}
                onChange={(e, { key, value }) => setInverse(value)}
              >
                {inverseArr.map((x) => (
                  <Select.Option value={x} key={x}>
                    {x}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </>
      )}

      {isFetching ? (
        <Loader />
      ) : (
        <Row>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            <ETFScreenerTable screenerData={EtfScreenerData} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ETFScreener;
