import React from "react";
import Plot from "react-plotly.js";
import { Typography } from "antd";

const { Title } = Typography;

const ReportBar = ({ data }) => {
    const text = data.slice(0, 10).map((x) => x.name);
    const values = data.slice(0, 10).map((x) => x.value);
    const accounts = data.slice(0, 10).map((x) => x.account);

    // console.log(labels);

    const chartData = [
        {
            type: "bar",
            x: accounts,
            y: values,
            text: text,
            textposition: "none",
            hovertemplate: "%{x}<br>%{y}<br>%{text}<extra></extra>",
            marker: {
                color: [
                    "#e47c00",
                    "#e68423",
                    "#e88d38",
                    "#e9954b",
                    "#ea9d5c",
                    "#eba56d",
                    "#eaad7e",
                    "#eab68e",
                    "#e8be9f",
                    "#e9cfcf",
                ],
                // color: values,
                // colorscale: "RdBu",
            },
        },
    ];

    const layout = {
        margin: {
            l: 60,
            r: 20,
            t: 20,
            b: 50,
        },
        xaxis: {
            title: "Accounts",
            color: "#2f2f2f",
        },
        yaxis: {
            title: "Position Value",
            color: "#2f2f2f",
        },

        // showscale: true,
        // autosize: true,
    };

    return (
        <>
            <Title level={4} style={{ marginLeft: "5px" }}>
                Top 10 Accounts
            </Title>
            <Plot data={chartData} layout={layout} useResizeHandler={true} style={{ width: "100%" }} />
        </>
    );
};

export default ReportBar;
