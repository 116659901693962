import React from "react";
import millify from "millify";
import { Link } from "react-router-dom";
import { Table, Typography } from "antd";

const { Title } = Typography;

const PositionTable = ({ data }) => {
    const columns = [
        {
            title: "Symbol",
            dataIndex: "Symbol",
            key: "Symbol",
            align: "center",
            width: "11%",
            render: (text) => <Link to={`/stockdetail/${text}`}>{text}</Link>,
        },
        {
            title: "Company",
            dataIndex: "CompanyName",
            key: "CompanyName",
            align: "center",
            width: "45%",
        },
        {
            title: "Type",
            dataIndex: "Type",
            key: "Type",
            align: "center",
            width: "11%",
        },
        {
            title: "Qty",
            dataIndex: "Qty",
            key: "Qty",
            align: "center",
            width: "11%",
            sorter: (a, b) => a.Qty - b.Qty,
        },
        // {
        //   title: "Avg Price",
        //   dataIndex: "AvgPrice",
        //   key: "AvgPrice",
        //   align: "center",
        //   width: "8%",
        //   sorter: (a, b) => a.AvgPrice - b.AvgPrice,
        // },
        {
            title: "Closing",
            dataIndex: "Closing",
            key: "Closing",
            align: "center",
            width: "11%",
            sorter: (a, b) => a.Closing - b.Closing,
        },
        // {
        //   title: "Last Price",
        //   dataIndex: "LastPrice",
        //   key: "LastPrice",
        //   align: "center",
        //   width: "10%",
        //   sorter: (a, b) => a.LastPrice - b.LastPrice,
        // },
        // {
        //   title: "Gain Amt",
        //   dataIndex: "GainAmt",
        //   key: "GainAmt",
        //   align: "center",
        //   width: "8%",
        // },
        // {
        //   title: "Dividend",
        //   dataIndex: "Div",
        //   key: "Div",
        //   align: "center",
        //   width: "11%",
        // },
        {
            title: "Value",
            dataIndex: "Value",
            key: "Value",
            align: "center",
            width: "11%",
            sorter: (a, b) => a.Value - b.Value,
            render: (text) => millify(text),
        },
    ];

    const dataSource = data?.data?.map((x) => ({
        key: x.Symbol,
        Symbol: x.Symbol,
        CompanyName: x.CompanyName,
        Type: x.Type,
        Qty: x.Qty,
        AvgPrice: x.AvgPrice,
        Closing: x.Closing,
        LastPrice: x.LastPrice,
        GainAmt: x.GainAmt,
        Div: x.Div,
        Value: x.Value,
    }));

    return (
        <>
            {/* <Title level={4}>{data?.title}</Title> */}
            <Table
                columns={columns}
                dataSource={dataSource}
                size="small"
                pagination={{
                    position: ["topRight", "bottomRight"],
                    defaultPageSize: 20,
                }}
            />
        </>
    );
};

export default PositionTable;
