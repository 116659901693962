import React from "react";
// import { Page, Text, Image, Document, StyleSheet } from "@react-pdf/renderer";
import { Avatar } from "antd";
import vtlogo from "../images/vt-logo.png";
import logo from "../images/logo1.png";

const PDFOnboarding = () => {
  return (
    <>
      <div>PDFOnboarding</div>
      <Avatar src={vtlogo} />
    </>
  );
};

export default PDFOnboarding;
