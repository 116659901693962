import React from "react";
import { useSelector } from "react-redux";
import { AuthorizationPage, Working, PDFOnboarding } from "../components";

const TwStrategy = () => {
  // const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);
  // return <>{!isAuthenticated ? <AuthorizationPage /> : <Working />}</>;
  return <PDFOnboarding />;
};

export default TwStrategy;
