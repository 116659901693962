import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Select, Input, AutoComplete, InputNumber, Rol, Col, Button, Divider, Space, Typography } from "antd";
import {
  DollarOutlined,
  FieldTimeOutlined,
  DollarCircleOutlined,
  FormOutlined,
  ContainerOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const TradingForm = ({ setSelectedSymbol }) => {
  const [symbol, setSymbol] = useState("");
  const [security, setSecurity] = useState("");
  const [side, setSide] = useState("");
  const [orderType, setOrderType] = useState("");
  const [qty, setQty] = useState("");
  const [marketTime, setMarketTime] = useState("");
  const [duration, setDuration] = useState("");

  const productMaster = useSelector((state) => state.productMaster.value);

  const handlefilterOption = (inputValue, option) => {
    return option.value.indexOf(inputValue) !== -1;
  };

  const securitySelect = [
    <Option key="Equity" value="Equity">
      Equity
    </Option>,
    <Option key="Options" value="Options">
      Options
    </Option>,
  ];

  const transactionSelect = [
    <Option key="Buy" value="Buy">
      Buy
    </Option>,
    <Option key="Sell" value="Sell">
      Sell
    </Option>,
  ];

  const orderTypeSelect = [
    <Option key="Limit" value="Limit">
      Limit
    </Option>,
    <Option key="Market" value="Market">
      Market
    </Option>,
    <Option key="Stop" value="Stop">
      Stop
    </Option>,
    <Option key="Stop Limit" value="Stop Limit">
      Stop Limit
    </Option>,
    <Option key="MOO" value="MOO">
      MOO
    </Option>,
    <Option key="MOC" value="MOC">
      MOC
    </Option>,
    <Option key="LOO" value="LOO">
      LOO
    </Option>,
    <Option key="LOC" value="LOC">
      LOC
    </Option>,
    <Option key="TWAP" value="TWAP">
      TWAP
    </Option>,
    <Option key="VWAP" value="VWAP">
      VWAP
    </Option>,
    <Option key="POV" value="POV">
      POV
    </Option>,
    <Option key="Trailing Stop" value="Trailing Stop">
      Trailing Stop
    </Option>,
  ];

  const marketTimeSelect = [
    <Option key="PreMarket" value="PreMarket">
      Pre Market
    </Option>,
    <Option key="CoreMarket" value="CoreMarket">
      Core Market
    </Option>,
    <Option key="PostMarket" value="PostMarket">
      Post Market
    </Option>,
  ];

  const durationSelect = [
    <Option key="DAY" value="DAY">
      Day
    </Option>,
    <Option key="GTC" value="GTC">
      GTC
    </Option>,
  ];

  return (
    <div>
      <Space direction="vertical" size="large" style={{ display: "flex" }}>
        <div>
          {/* <Divider orientation="left" plain style={{ marginBottom: "1px" }}>
            Symbol
          </Divider> */}
          {/* <Input
            size="large"
            placeholder="Symbol"
            value={symbol}
            onChange={(e) => {
              setSymbol(e.target.value);
            }}
          /> */}
          <div style={{ margin: "0px 0px 1px 10px" }}>Symbol</div>
          <AutoComplete
            // size="large"
            placeholder="Symbol"
            defaultActiveFirstOption={false}
            defaultOpen={false}
            notFoundContent={<div></div>}
            value={symbol}
            options={productMaster}
            onChange={(e) => setSymbol(e.toUpperCase())}
            onSelect={(e) => {
              setSelectedSymbol(e);
            }}
            style={{ width: "100%" }}
            filterOption={handlefilterOption}
          ></AutoComplete>
        </div>
        <div>
          {/* <Divider orientation="left" plain style={{ marginBottom: "1px" }}>
            Security
          </Divider> */}
          <div style={{ margin: "0px 0px 1px 10px" }}>Security</div>
          <Select
            // size="middle"
            placeholder="Security"
            value={security}
            onChange={(e) => setSecurity(e)}
            style={{ width: "100%" }}
          >
            {securitySelect}
          </Select>
        </div>
        <div>
          {/* <Divider orientation="left" plain style={{ marginBottom: "1px" }}>
            Side
          </Divider> */}
          <div style={{ margin: "0px 0px 1px 10px" }}>Side</div>
          <Select size="default" placeholder="Side" value={side} onChange={(e) => setSide(e)} style={{ width: "100%" }}>
            {transactionSelect}
          </Select>
        </div>
        <div>
          {/* <Divider orientation="left" plain style={{ marginBottom: "1px" }}>
            Order Type
          </Divider> */}
          <div style={{ margin: "0px 0px 1px 10px" }}>Order Type</div>
          <Select
            // size="large"
            placeholder="Order Type"
            value={orderType}
            onChange={(e) => setOrderType(e)}
            style={{ width: "100%" }}
          >
            {orderTypeSelect}
          </Select>
        </div>
        <div>
          {/* <Divider orientation="left" plain style={{ marginBottom: "1px" }}>
            Quentity
          </Divider> */}
          <div style={{ margin: "0px 0px 1px 10px" }}>Quentity</div>
          <Input size="default" placeholder="Quentity" value={qty} onChange={(e) => setQty(e.target.value)} />
        </div>
        <div>
          {/* <Divider orientation="left" plain style={{ marginBottom: "1px" }}>
            Market Time
          </Divider> */}
          <div style={{ margin: "0px 0px 1px 10px" }}>Market Time</div>
          <Select
            // size="large"
            placeholder="Market Time"
            value={marketTime}
            onChange={(e) => setMarketTime(e)}
            style={{ width: "100%" }}
          >
            {marketTimeSelect}
          </Select>
        </div>
        <div>
          {/* <Divider orientation="left" plain style={{ marginBottom: "1px" }}>
            Duration
          </Divider> */}
          <div style={{ margin: "0px 0px 1px 10px" }}>Duration</div>
          <Select
            // size="large"
            placeholder="Duration"
            value={duration}
            onChange={(e) => setDuration(e)}
            style={{ width: "100%" }}
          >
            {durationSelect}
          </Select>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Button block type="primary">
            Trade
          </Button>
        </div>
      </Space>
    </div>
  );
};

export default TradingForm;
