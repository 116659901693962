import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  SearchOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  CareRightOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Divider,
  Row,
  Col,
  Card,
  Button,
  List,
  notification,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Search,
  Switch,
  Space,
  TreeSelect,
  Checkbox,
  Typography,
  Modal,
  Tag,
} from "antd";
import {
  useUserRequestQuery,
  useUserRequestMuMutation,
  useUserRequestOneMutation,
  useUserRequestCreateMutation,
  useUserRequestDeleteMutation,
  useUserRequestUpdateMutation,
} from "../services/onboardingApi";

const UserRequest = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [apiInputDisabled, setApiInputDisabled] = useState(true);
  const [blpInputDisabled, setBlpInputDisabled] = useState(true);
  const [billOthersInputDisabled, setBillOthersInputDisabled] = useState(true);
  const [detailState, setDetailState] = useState({});
  const [searchFormData, setSearchFormData] = useState({});

  const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);
  const loginUser = useSelector((state) => state.auth.value.user);

  const { data: dataUserRequest } = useUserRequestQuery({ company: "", firstname: "", lastname: "", confirmed: "" });
  const [userRequestMu, dataUserRequestMu] = useUserRequestMuMutation();
  const [userRequestOne, dataUserRequestOne] = useUserRequestOneMutation();
  const [userRequestCreate, dataUserRequestCreate] = useUserRequestCreateMutation();
  const [userRequestUpdate, dataUserRequestUpdate] = useUserRequestUpdateMutation();
  const [userRequestDelete, dataUserRequestDelete] = useUserRequestDeleteMutation();

  console.log(dataUserRequestMu);

  // 每次 dataUserRequestOne 立刻更新 detailState (用來當作按鈕的判斷條件以及重設條件)
  useEffect(() => {
    setDetailState(dataUserRequestOne.data);
  }, [dataUserRequestOne.data]);

  const openNotification = ({ type, message, description }) => {
    notification[type]({
      message,
      description,
    });
  };

  const CreateFormHandler = async () => {
    try {
      const val = await form.validateFields();
      const res = await userRequestCreate(form.getFieldsValue());
      console.log(res);
      if (isAuthenticated) {
        openNotification({ type: "success", message: "Form Created!", description: "Form create successfully!" });
      } else {
        // redirect to success page
        navigate(`/formfilled/${"user-request-pdf"}/${res.data.id}`);
      }

      form.resetFields();
    } catch (errInfo) {
      openNotification({ type: "error", message: "Form Create Fail!", description: "Something goes wrong!" });
      console.log("Error: ", errInfo);
    }
  };

  const ResetFormHandler = () => {
    form.resetFields();
    setDetailState({});
  };

  const DeleteFormHandler = ({ item }) => {
    console.log(item);
    showDeleteConfirm(item);
  };

  const showDeleteConfirm = (item) => {
    Modal.confirm({
      title: `Are you sure you want to delete ${item.first_name}.${item.last_name}\'s file?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        ConfirmedDelete(item.id);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const ConfirmedDelete = async (id) => {
    let res = await userRequestDelete(id);
    form.resetFields();
  };

  const DetailFormHandler = async ({ item }) => {
    let res = await userRequestOne(item.id);
    form.resetFields();
    form.setFieldsValue(res.data.form_data);
  };

  const UpdateFormHandler = async () => {
    console.log(detailState);
    let formData = {
      ...detailState,
      company_name: form.getFieldsValue()["Company_Name"],
      first_name: form.getFieldsValue()["User_First_Name"],
      last_name: form.getFieldsValue()["User_Last_Name"],
      confirmed_date: moment(),
      confirmed_member: loginUser.username,
      is_confirmed: true,
      form_data: form.getFieldsValue(),
    };

    try {
      let res = await userRequestUpdate({ pk: detailState.id, data: formData });
      form.resetFields();
      setDetailState({});
      openNotification({ type: "success", message: "Form Updated!", description: "Form update successfully!" });
    } catch (errInfo) {
      openNotification({ type: "error", message: "Form Update Fail!", description: "Something goes wrong!" });
    }
  };

  const SearchFormHandler = async (item) => {
    try {
      let res = await userRequestMu({ company: item });
      //   let res_firstname = await userRequestMu({ firstname: item });
      //   let res_lastname = await userRequestMu({ lastname: item });
    } catch (errInfo) {
      console.log(errInfo);
    }
  };

  const isRequired = true;
  const professionalDefinition = [
    "Is registered or qualified with: the Securities and Exchange Commission, the Commodities Futures Trading Commission, any state securities agency, any securites exchange or association, or any commodities or futures contract market or association.",
    'Is engaged as an "investment advisor" as that term is defined in Section 201(11) of the Investment Advisor\'s Act of 1940 (whether or not registered or qualified under that Act).',
    "Is employed by a bank or another organization that is exempt from registration under Federal and/or state securities laws to perform functions that would require him or her to be so registered or qualified if he or she were to perform such function for an organization not so exempt.",
  ];

  return (
    <>
      <Row gutter={[15, 15]} style={{ display: "flex", flexDirection: "row-reverse" }}>
        <Col order={1} xs={24} sm={24} lg={24} xl={6} xxl={6}>
          <Card hoverable={true}>
            {isAuthenticated ? (
              <>
                <Typography.Title level={4}>Unconfirmed forms</Typography.Title>

                <List
                  size="small"
                  split={false}
                  //   rowKey={dataUserRequest?.data?.map((x) => x.id)}
                  dataSource={dataUserRequest?.filter((value, index, arr) => {
                    return value.is_confirmed == false;
                  })}
                  renderItem={(item) => {
                    return (
                      <List.Item
                        key={item.id}
                        actions={[
                          <Button danger onClick={() => DeleteFormHandler({ item })}>
                            Delete
                          </Button>,
                        ]}
                      >
                        <Button type="link" onClick={() => DetailFormHandler({ item })}>
                          {item.first_name + "." + item.last_name}
                        </Button>
                      </List.Item>
                    );
                  }}
                />

                <Divider style={{ borderColor: "darkgray" }} />
                <Typography.Title level={4}>Search Form</Typography.Title>
                <Input.Search
                  placeholder="Company Name"
                  enterButton="Search"
                  onSearch={(value) => SearchFormHandler(value)}
                />
                {dataUserRequestMu.isSuccess && (
                  <Space style={{ marginTop: "20px" }}>
                    <List
                      size="small"
                      split={false}
                      dataSource={dataUserRequestMu?.data.map((x) => x)}
                      renderItem={(item) => {
                        return (
                          <List.Item
                            key={item.id}
                            actions={[
                              <Button danger onClick={() => DeleteFormHandler({ item })}>
                                Delete
                              </Button>,
                            ]}
                          >
                            <Button type="link" onClick={() => DetailFormHandler({ item })}>
                              {item.first_name + "." + item.last_name}
                            </Button>
                          </List.Item>
                        );
                      }}
                    />
                  </Space>
                )}
              </>
            ) : (
              <Space direction="vertical">
                <Typography.Title level={4}>Tips</Typography.Title>
                <Typography.Text>
                  Please fill out the form and email to <a href="mailto: asia@viewtrade.com">asia@viewtrade.com</a> as
                  an attachment. Orbis Systems personnel will contact you regarding your request.
                </Typography.Text>
                <Divider />
                <Typography.Title level={4}>Definition</Typography.Title>
                <Typography.Title level={5}>Professional</Typography.Title>
                <Typography.Text>Any person that meets any of the following creteria:</Typography.Text>
                <List
                  split={false}
                  dataSource={professionalDefinition}
                  renderItem={(item) => (
                    <List.Item>
                      <i>{item}</i>
                    </List.Item>
                  )}
                />
                <Divider />
                <Typography.Title level={5}>Non-Professional</Typography.Title>
                <Typography.Text>
                  is any natural person and connot be a corporation, trust, organization, institutio or partnership
                  account
                </Typography.Text>
              </Space>
            )}
          </Card>
        </Col>
        <Col order={2} xs={24} sm={24} lg={24} xl={18} xxl={18}>
          <Card hoverable={true}>
            <Card>
              <div style={{ display: "flex", direction: "row", justifyContent: "space-between" }}>
                <Typography.Title level={3}>Client UserAccess Request Form</Typography.Title>

                {!_.isEmpty(detailState) ? (
                  <Space direction="row">
                    <Button type="primary" style={{ width: "100px" }} onClick={ResetFormHandler}>
                      New Form
                    </Button>

                    {detailState?.is_confirmed ? (
                      <>
                        <Button
                          type="primary"
                          style={{ width: "100px", backgroundColor: "darkblue", borderColor: "darkblue" }}
                          onClick={UpdateFormHandler}
                        >
                          Update
                        </Button>
                        <a href={`${process.env.REACT_APP_SERVER_URL}/onboarding/user-request-pdf/${detailState?.id}`}>
                          <Button
                            type="primary"
                            style={{
                              backgroundColor: "darkred",
                              borderColor: "darkred",
                              width: "100px",
                            }}
                          >
                            View Form
                          </Button>
                        </a>
                      </>
                    ) : (
                      <Button
                        type="primary"
                        style={{
                          width: "100px",
                          color: "black",
                          backgroundColor: "orange",
                          borderColor: "orange",
                        }}
                        onClick={UpdateFormHandler}
                      >
                        Confirm
                      </Button>
                    )}
                  </Space>
                ) : (
                  <Space direction="row">
                    <Button type="primary" style={{ width: "100px" }} onClick={CreateFormHandler}>
                      Submit
                    </Button>
                    <Button type="primary" style={{ width: "100px" }} onClick={ResetFormHandler}>
                      Reset
                    </Button>
                  </Space>
                )}
              </div>
              {detailState?.is_confirmed ? (
                <>
                  <Tag color="green">Confirmed</Tag>
                  <Typography.Text type="secondary">
                    Confirmed by <Typography.Text strong>{detailState?.confirmed_member} </Typography.Text>
                    at <Typography.Text italic>{detailState?.confirmed_date.split("T")[0]}</Typography.Text>
                  </Typography.Text>
                </>
              ) : (
                ""
              )}
            </Card>
            <Divider />
            <Form
              form={form}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 12,
              }}
              layout="horizontal"
              size="large"
              requiredMark={false}
              // initialValues={{ Order_Types: ["DAY", "GTC", "GTD", "IOC", "FOK"] }}
            >
              <Form.Item
                label="User First Name"
                name="User_First_Name"
                rules={[{ required: isRequired }]}
                tooltip={{ title: "Please input user first name name", icon: <InfoCircleOutlined /> }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="User Last Name"
                name="User_Last_Name"
                rules={[{ required: isRequired }]}
                tooltip={{ title: "Please input user last name name", icon: <InfoCircleOutlined /> }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Company Name"
                name="Company_Name"
                rules={[{ required: isRequired }]}
                tooltip={{ title: "Please input company name", icon: <InfoCircleOutlined /> }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="User Email"
                name="User_Email"
                rules={[{ required: isRequired }]}
                tooltip={{ title: "Please input user email", icon: <InfoCircleOutlined /> }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="User Phone"
                name="User_Phone"
                tooltip={{ title: "Please input user phone", icon: <InfoCircleOutlined /> }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="User Type"
                name="User_Type"
                rules={[{ required: isRequired }]}
                tooltip={{
                  title: "Please select user type",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group style={{ padding: "10px" }} options={["Demo", "UAT", "Production"]}></Checkbox.Group>
              </Form.Item>
              <Form.Item
                label="Application Type"
                name="Application_Type"
                rules={[{ required: isRequired }]}
                tooltip={{
                  title: "Please select application type",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group
                  style={{ padding: "10px" }}
                  options={["WebTrader", "Mobile Trader", "Pro Trader", "Watchmen/Backoffice"]}
                ></Checkbox.Group>
              </Form.Item>
              <Form.Item
                label="Subscriber Status"
                name="Subscriber_Status"
                rules={[{ required: isRequired }]}
                tooltip={{
                  color: "lightgray",
                  placement: "right",
                  title: (
                    <List
                      split={false}
                      dataSource={professionalDefinition}
                      renderItem={(item) => (
                        <List.Item>
                          <i>{item}</i>
                        </List.Item>
                      )}
                    />
                  ),
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group
                  style={{ padding: "10px" }}
                  options={["Professional", "Non-professional"]}
                ></Checkbox.Group>
              </Form.Item>

              <Form.Item
                label="API User"
                tooltip={{
                  title: "API User required?",
                  icon: <InfoCircleOutlined />,
                }}
                style={{ width: "100%", marginBottom: "2px" }}
              >
                <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                  <Form.Item name="API_User">
                    <Checkbox.Group style={{ width: "130px", padding: "10px" }}>
                      <Checkbox value="API User" onChange={(e) => setApiInputDisabled(!e.target.checked)}>
                        API User
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>

                  <Form.Item hidden={apiInputDisabled} name="API_Entitlements" style={{ width: "100%" }}>
                    <Input placeholder="API Entitlements" />
                  </Form.Item>
                </div>
              </Form.Item>

              <Form.Item
                label="Bloomberg EMSx User"
                tooltip={{
                  title: "Please specify Bloomberg EMSx User",
                  icon: <InfoCircleOutlined />,
                }}
                style={{ width: "100%", marginBottom: "2px" }}
              >
                <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                  <Form.Item
                    name="Bloomberg_EMSx_User"
                    tooltip={{
                      title: "Need Bloomberg EMSx user?",
                      icon: <InfoCircleOutlined />,
                    }}
                  >
                    <Checkbox.Group style={{ width: "200px", padding: "10px" }}>
                      <Checkbox value="Bloomberg EMSx User" onChange={(e) => setBlpInputDisabled(!e.target.checked)}>
                        Bloomberg EMSx User
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                  <Space direction="horizontal" size="middle" style={{ width: "100%" }}>
                    <Form.Item hidden={blpInputDisabled} name="Bloomberg_EMSx_User_Name">
                      <Input placeholder="User Name" />
                    </Form.Item>
                    <Form.Item hidden={blpInputDisabled} name="Bloomberg_EMSx_User_UUID">
                      <Input placeholder="UUID" />
                    </Form.Item>
                  </Space>
                </div>
              </Form.Item>

              <Form.Item
                label="Market Data Type"
                name="Market_Data_Type"
                tooltip={{
                  title: "Please select your market data type",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group
                  style={{ padding: "10px", width: "350px" }}
                  options={[
                    "20 minutes delayed",
                    "Real-time BATS (BZX)",
                    "Real-time CBOE One",
                    "Real-time Snap NBBO",
                    "Real-time Streaming NBBO",
                  ]}
                ></Checkbox.Group>
              </Form.Item>
              <Form.Item
                label="User Job Function"
                name="User_Job_Function"
                tooltip={{
                  title: "Please select user job function",
                  icon: <InfoCircleOutlined />,
                }}
              >
                <Checkbox.Group
                  style={{ padding: "10px", width: "350px" }}
                  options={["Registered Representative", "Sales Representative", "IT developer or Tester"]}
                ></Checkbox.Group>
              </Form.Item>

              <Form.Item
                label="Billable to"
                tooltip={{
                  title: "Please select bill",

                  icon: <InfoCircleOutlined />,
                }}
                style={{ width: "100%", marginBottom: "2px" }}
              >
                <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                  <Form.Item
                    name="Billable_to"
                    tooltip={{
                      title: "Billable to?",
                      icon: <InfoCircleOutlined />,
                    }}
                  >
                    <Space direction="horizontal" size="middle" style={{ padding: "10px", width: "180px" }}>
                      <Checkbox.Group>
                        <Checkbox value="Client">Client</Checkbox>
                        <Checkbox value="Others" onChange={(e) => setBillOthersInputDisabled(!e.target.checked)}>
                          Others
                        </Checkbox>
                      </Checkbox.Group>
                    </Space>
                  </Form.Item>

                  <Form.Item hidden={billOthersInputDisabled} name="Bill_to_others" style={{ width: "100%" }}>
                    <Input placeholder="Bill to ..." />
                  </Form.Item>
                </div>
              </Form.Item>
              <Divider />
              <Form.Item wrapperCol={{ span: 12, offset: 8 }}>
                <Space size="middle">
                  <Button type="primary" style={{ width: "100px" }} onClick={CreateFormHandler}>
                    Submit
                  </Button>
                  <Button type="primary" style={{ width: "100px" }} onClick={ResetFormHandler}>
                    Reset
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserRequest;
