import React from "react";
import millify from "millify";
import { Table, Typography } from "antd";
import { Link } from "react-router-dom";

const ADRListTable = ({ title, data }) => {
  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      align: "center",
      width: "12%",
      render: (text) => <Link to={"/stockdetail/" + text}>{text}</Link>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      responsive: ["lg"],
      align: "left",
      width: "40%",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
      width: "12%",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      width: "12%",
      sorter: (a, b) => a.price - b.price,
      render: (text) => <div>${text}</div>,
    },
    {
      title: "Change",
      dataIndex: "change",
      key: "change",
      align: "right",
      width: "12%",
      sorter: (a, b) => a.change - b.change,
      render: (text) => {
        let color = text > 0 ? "green" : text < 0 ? "red" : "black";
        return <div style={{ color }}>{text.toFixed(2)}%</div>;
      },
    },
    {
      title: "Volume",
      dataIndex: "volume",
      key: "volume",
      align: "right",
      width: "12%",
      sorter: (a, b) => a.volume - b.volume,
      render: (text) => millify(text),
    },
    // {
    //   title: "Market Cap",
    //   dataIndex: "marketcap",
    //   key: "marketcap",
    //   align: "right",
    //   width: "10%",
    //   responsive: ["lg"],
    //   sorter: (a, b) => a.marketcap - b.marketcap,
    //   render: (text) => millify(text),
    // },
  ];

  const dataSource = data.map((x) => ({
    key: x.symbol,
    symbol: x.symbol,
    name: x.quote.companyName,
    country: x.country,
    price: x.quote.lastPrice,
    change: x.quote.changePercent,
    volume: x.quote.volume,
    // marketcap: x.quote.marketCap,
  }));

  return (
    <div className="block-container">
      <Typography.Title level={4} style={{ margin: "10px 0px" }}>
        {title}
      </Typography.Title>
      <Table columns={columns} dataSource={dataSource} size="small" pagination={false} />
    </div>
  );
};

export default ADRListTable;
