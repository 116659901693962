import React, { useEffect, useState } from "react";
import moment from "moment";
import millify from "millify";
import { Radio, Space } from "antd";
import { blue, gold } from "@ant-design/colors";
import { Loader } from ".";
import { useEquityHistoryQuery, useIntradayChartQuery } from "../services/orbisApi";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    registerables,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
    ...registerables,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const StockDetailChart = ({ symbol }) => {
    const [timePeriod, setTimePeriod] = useState(moment().subtract(1, "years").format("MM/DD/YYYY"));
    const [selected, setSelected] = useState("1Y");
    const [intraFrom, setIntraFrom] = useState(moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss"));
    const [intraTo, setIntraTo] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
    const [myLabel, setMyLabel] = useState();
    const [myData, setMyData] = useState();
    const [myVolume, setMyVolume] = useState();

    const historical = useEquityHistoryQuery({
        symbol: symbol,
        start: timePeriod,
        end: moment().format("MM/DD/YYYY"),
        range: "",
        wetWeekly: false,
        rsiSpan: 1,
    });

    const intraday = useIntradayChartQuery({
        symbol,
        rsiSpan: "",
        from: intraFrom,
        to: intraTo,
        rawVolumes: false,
    });

    let onSelectionHandler = (value) => {
        switch (value) {
            case "1D":
                setSelected("1D");
                // setIntraFrom(moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss"));
                // setIntraTo(moment().format("YYYY-MM-DD HH:mm:ss"));
                break;
            case "5D":
                setSelected("5D");
                // setIntraFrom(moment().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss"));
                // setIntraTo(moment().format("YYYY-MM-DD HH:mm:ss"));
                break;
            case "1M":
                setTimePeriod(moment().subtract(1, "months").format("MM/DD/YYYY"));
                setSelected("1M");
                break;
            case "3M":
                setTimePeriod(moment().subtract(3, "months").format("MM/DD/YYYY"));
                setSelected("3M");
                break;
            case "6M":
                setTimePeriod(moment().subtract(6, "months").format("MM/DD/YYYY"));
                setSelected("6M");
                break;
            case "1Y":
                setTimePeriod(moment().subtract(1, "years").format("MM/DD/YYYY"));
                setSelected("1Y");
                break;
            case "5Y":
                setTimePeriod(moment().subtract(5, "years").format("MM/DD/YYYY"));
                setSelected("5Y");
                break;
            case "10Y":
                setTimePeriod(moment().subtract(10, "years").format("MM/DD/YYYY"));
                setSelected("10Y");
                break;
        }
    };

    useEffect(() => {
        if (selected === "1D") {
            let oneDay = intraday?.data?.filter((item, index, array) => {
                return item.date >= intraday?.data[intraday?.data?.length - 1]["date"].split(" ")[0];
            });
            setMyLabel(oneDay?.map((x) => moment(x.date).format("MM/DD HH:mm")));
            setMyData(oneDay?.map((x) => x.price.toFixed(2)));
            setMyVolume(oneDay?.map((x) => x.volume));
        } else if (selected === "5D") {
            setMyLabel(intraday?.data?.map((x) => moment(x.date).format("MM/DD HH:mm")));
            setMyData(intraday?.data?.map((x) => x.price.toFixed(2)));
            setMyVolume(intraday?.data?.map((x) => x.volume));
        } else {
            setMyLabel(historical?.data?.map((x) => moment(x.date).format("YYYY-MM-DD")));
            setMyData(historical?.data?.map((x) => x.price.toFixed(2)));
            setMyVolume(historical?.data?.map((x) => x.volume));
        }
    }, [historical, selected, intraday]);

    const SelectRange = () => {
        return (
            <>
                <Radio.Group
                    defaultValue="1Y"
                    buttonStyle="solid"
                    size="middle"
                    value={selected}
                    onChange={(e) => onSelectionHandler(e.target.value)}
                >
                    <Radio.Button value="1D">1D</Radio.Button>
                    <Radio.Button value="5D">5D</Radio.Button>
                    <Radio.Button value="1M">1M</Radio.Button>
                    <Radio.Button value="3M">3M</Radio.Button>
                    <Radio.Button value="6M">6M</Radio.Button>
                    <Radio.Button value="1Y">1Y</Radio.Button>
                    <Radio.Button value="5Y">5Y</Radio.Button>
                    <Radio.Button value="10Y">10Y</Radio.Button>
                </Radio.Group>
            </>
        );
    };

    const options = {
        responsive: true,
        interaction: {
            mode: "index",
            intersect: false,
        },
        stacked: false,
        scales: {
            y: {
                type: "linear",
                display: true,
                grace: "10%",
                position: "left",
                ticks: {
                    maxTicksLimit: 10,
                },
            },
            y1: {
                type: "linear",
                display: true,
                position: "right",
                grace: "80%",
                grid: {
                    drawOnChartArea: false,
                },
            },
            x: {
                ticks: {
                    maxTicksLimit: 20,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: "Stock Charts",
            },
        },
    };

    const data = {
        labels: myLabel,
        datasets: [
            {
                type: "line",
                label: symbol.toUpperCase(),
                data: myData,
                lineTension: 0.15,
                // fill: "origin",
                borderColor: blue[4],
                backgroundColor: blue[5],
                pointRadius: 0,
                yAxisID: "y",
            },
            {
                type: "bar",
                label: "Volume",
                data: myVolume,
                backgroundColor: gold[2],
                yAxisID: "y1",
            },
        ],
    };

    // const intradayData = {
    //   labels: intraday?.data,
    //   datasets: [
    //     {
    //       type: "line",
    //       label: symbol.toUpperCase(),
    //       data: intraday?.data?.map((x) => x.price.toFixed(2)),
    //       lineTension: 0.15,
    //       // fill: "origin",
    //       borderColor: blue[4],
    //       backgroundColor: blue[5],
    //       pointRadius: 0,
    //       yAxisID: "y",
    //     },
    //     {
    //       type: "bar",
    //       label: "Volume",
    //       data: historical?.data?.map((x) => x.volume),
    //       backgroundColor: gold[2],
    //       yAxisID: "y1",
    //     },
    //   ],
    // };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <SelectRange />
            <div style={{ margin: "10px" }}>
                {historical.isFetching ? <Loader /> : <Chart options={options} data={data} />}
            </div>
        </div>
    );
};

export default StockDetailChart;
