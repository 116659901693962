import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    access: localStorage.getItem("access"),
    refresh: localStorage.getItem("refresh"),
    isAuthenticated: null,
    user: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      localStorage.setItem("access", action.payload.access);
      localStorage.setItem("refresh", action.payload.refresh);
      state.value.access = action.payload.access;
      state.value.refresh = action.payload.refresh;
      state.value.isAuthenticated = true;
    },
    logout: (state) => {
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      state.value.access = null;
      state.value.refresh = null;
      state.value.isAuthenticated = false;
      state.value.user = null;
    },
    authenticatedSuccess: (state) => {
      state.value.isAuthenticated = true;
    },
    authenticatedFail: (state) => {
      state.value.isAuthenticated = false;
    },
    userLoadSuccess: (state, action) => {
      state.value.user = action.payload;
    },
    userLoadFail: (state) => {
      state.value.user = null;
    },
    signupSuccess: (state) => {
      state.value.isAuthenticated = false;
    },
  },
});

export const {
  loginSuccess,
  logout,
  authenticatedSuccess,
  authenticatedFail,
  userLoadSuccess,
  userLoadFail,
  signupSuccess,
} = authSlice.actions;

export default authSlice.reducer;
