import React, {useState} from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, AutoComplete } from "antd";
import { NewsProvider } from "../components";

const News = () => {
  const [symbol, setSymbol] = useState("");
  const productMaster = useSelector(state => state.productMaster.value)

  const onSelectedSymbol = () => {
    console.log(symbol)
  }

  const handlefilterOption = (inputValue, option) => {
    return option.value.indexOf(inputValue) !== -1;
  };

  return (
    <>
      {/* <Row gutter={[15, 15]}>
        <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
          <Card style={{display:"flex", justifyContent:"flex-end"}}>
            <AutoComplete
                // size="large"
                placeholder="Symbol"
                defaultActiveFirstOption={false}
                defaultOpen={false}
                notFoundContent={<div></div>}
                value={symbol}
                options={productMaster}
                onChange={(e) => setSymbol(e.toUpperCase())}
                onSelect={(e) => {
                  onSelectedSymbol(e);
                }}
                style={{width: "200px", margin:"0px" }}
                filterOption={handlefilterOption}
              />
          </Card>
        </Col>
      </Row> */}
      <Row gutter={[15, 15]} >
        <Col xs={24} sm={24} lg={24} xl={14} xxl={14}>
          <Card>
            <NewsProvider
              title={"News"}
              provider={"BENZINGA,BIZWIRE_,FLYWALL_,PWS,SEEKINGALPHA,PRIMZONE"}
              start={0}
              count={20}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} lg={24} xl={10} xxl={10}>
          <div>
            <Card>
              <NewsProvider title={"PR News"} provider={"PR_NEWS_"} start={0} count={5} />
            </Card>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Card>
              <NewsProvider title={"SEC News"} provider={"SEC_____"} start={0} count={5} />
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default News;
