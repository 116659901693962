import React from "react";
import { AuthorizationPage, Working } from "../components";
import { useDispatch, useSelector } from "react-redux";

const TipRank = () => {
    const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);
    return <>{!isAuthenticated ? <AuthorizationPage /> : <Working />}</>;
};

export default TipRank;
