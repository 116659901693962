import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const onboardingApi = createApi({
  reducerPath: "onboardingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
  }),
  tagTypes: ["form_data", "userRequest"],
  endpoints: (builder) => ({
    // ==== List all form ====
    ffiList: builder.query({
      query: () => ({
        url: "/onboarding/ffi-list",
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }),
      providesTags: ["form_data"],
    }),

    // ==== List all confirmed form ====
    ffiListConfirmed: builder.query({
      query: () => ({
        url: `/onboarding/ffi-list-confirmed`,
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }),
      providesTags: ["form_data"],
    }),

    // ==== List all unconfirmed form ====
    ffiListUnconfirmed: builder.query({
      query: () => ({
        url: `/onboarding/ffi-list-unconfirmed`,
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }),
      providesTags: ["form_data"],
    }),

    // ==== Show one form ====
    ffiDetail: builder.mutation({
      query: (account) => ({
        url: `/onboarding/ffi-detail/${account}`,
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }),
      invalidatesTags: ["form_data"],
    }),

    // ==== Create form ====
    ffiCreate: builder.mutation({
      query: (formData) => ({
        url: "/onboarding/ffi-create",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["form_data"],
    }),

    // ==== Update form ====
    ffiUpdate: builder.mutation({
      query: ({ account, formData }) => ({
        url: `/onboarding/ffi-update/${account}`,
        method: "POST",
        body: formData,
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }),
      invalidatesTags: ["form_data"],
    }),

    // ==== Delete form ====
    ffiDelete: builder.mutation({
      query: (account) => ({
        url: `/onboarding/ffi-delete/${account}`,
        method: "DELETE",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }),
      invalidatesTags: ["form_data"],
    }),

    // ========== User Request ===================================================
    // ==== Create User Request Form ====
    userRequestCreate: builder.mutation({
      query: (data) => ({
        url: "/onboarding/user-request-create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["userRequest"],
    }),

    // ==== Update User Request Form ====
    userRequestUpdate: builder.mutation({
      query: ({ pk, data }) => ({
        url: `/onboarding/user-request-update/${pk}`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }),
      invalidatesTags: ["userRequest"],
    }),

    // ==== Request User Request Form ====
    userRequest: builder.query({
      query: ({ company, firstname, lastname, confirmed }) => ({
        url: "/onboarding/user-request",
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        params: {
          company,
          firstname,
          lastname,
          confirmed,
        },
      }),
      providesTags: ["userRequest"],
    }),

    // ==== Request User Request Form Mutation ====
    userRequestMu: builder.mutation({
      query: ({ company, firstname, lastname, confirmed }) => ({
        url: "/onboarding/user-request",
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
        params: {
          company,
          firstname,
          lastname,
          confirmed,
        },
      }),
      invalidatesTags: ["userRequest"],
    }),

    // ==== Request One User Request Form ====
    userRequestOne: builder.mutation({
      query: (id) => ({
        url: `/onboarding/user-request/${id}`,
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }),
      invalidatesTags: ["userRequest"],
    }),

    // ==== Delete User Request Form ====
    userRequestDelete: builder.mutation({
      query: (id) => ({
        url: `/onboarding/user-request-delete/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      }),
      invalidatesTags: ["userRequest"],
    }),
  }),
});

export const {
  useFfiListQuery,
  useFfiListConfirmedQuery,
  useFfiListUnconfirmedQuery,
  useFfiDetailMutation,
  useFfiUpdateMutation,
  useFfiCreateMutation,
  useFfiDeleteMutation,
  useUserRequestQuery,
  useUserRequestMuMutation,
  useUserRequestOneMutation,
  useUserRequestCreateMutation,
  useUserRequestDeleteMutation,
  useUserRequestUpdateMutation,
} = onboardingApi;
