import React from "react";
import { useSelector } from "react-redux";
import { AuthorizationPage, Working } from "../components";
import { useFIXUATQuery, useFIXPRODQuery } from "../services/orbisApi";

const FIXconnection = () => {
    //   const UAT = useFIXUATQuery();
    //   const Prod = useFIXPRODQuery();

    //   console.log(UAT?.data);
    //   console.log(Prod?.data);

    const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);
    return <>{!isAuthenticated ? <AuthorizationPage /> : <Working />}</>;
};

export default FIXconnection;
