import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { Loader, CorporateActionTable, AuthorizationPage } from "../components";
import { Row, Col, Select, Typography, Checkbox, Divider, Input, DatePicker, Button, Empty, Switch } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useCorporateActionTypesQuery, useCoSearchMutation } from "../services/orbisApi";

const { Option } = Select;
const { RangePicker } = DatePicker;

const CorporateAction = () => {
  const [checkedList, setCheckedList] = useState([]);
  const [symbol, setSymbol] = useState("");
  const [date, setDate] = useState([]);
  const { data: coTypes } = useCorporateActionTypesQuery();
  const [getCo, result] = useCoSearchMutation();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);

  const buttonHandler = () => {
    if (symbol.length && date.length && checkedList.length) {
      getCo({
        symbol: symbol,
        dateFrom: date[0],
        dateTo: date[1],
        type: checkedList.join(","),
        usePayDate: false,
      });
    }
  };

  const checkBoxHandler = (list) => {
    setCheckedList(list);
  };

  const switchHandler = (checked, event) => {
    setCheckedList(checked ? coTypes : []);
  };

  return (
    <>
      {!isAuthenticated ? (
        <AuthorizationPage />
      ) : (
        <Row gutter={[15, 15]} style={{ flexDirection: "row-reverse" }}>
          <Col order={2} xs={24} sm={24} lg={24} xl={18} xxl={18}>
            <div className="block">
              <Typography.Title level={4} style={{ marginLeft: "15px" }}>
                Corporate Actions
              </Typography.Title>
              <Divider style={{ borderColor: "darkblue", marginTop: "-5px" }} />
              {result.isLoading ? (
                <Loader />
              ) : !result?.data ? (
                <Empty
                  style={{ height: "75vh" }}
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                ></Empty>
              ) : (
                <CorporateActionTable data={result?.data} />
              )}
            </div>
          </Col>
          <Col order={1} xs={24} sm={24} lg={24} xl={6} xxl={6}>
            <div className="block">
              <Divider plain orientation="left" style={{ color: "darkblue", borderColor: "darkblue" }}>
                Symbol
              </Divider>
              <Input
                size="middle"
                placeholder="Symbol"
                value={symbol}
                onChange={(e) => setSymbol(e.target.value.toUpperCase())}
                style={{ margin: "-10px 0 25px" }}
              ></Input>
              <Divider plain orientation="left" style={{ color: "darkblue", borderColor: "darkblue" }}>
                Date Range
              </Divider>
              <RangePicker
                size="middle"
                style={{ width: "100%", margin: "-10px 0 25px" }}
                onChange={(date, dateString) => {
                  setDate(date.map((x) => format(x._d, "MM/dd/yyyy")));

                  // console.log(format(date[0]._d, "MM/dd/yyyy"));
                  // setDate(dateString);
                }}
              />
              <Divider plain orientation="left" style={{ color: "darkblue", borderColor: "darkblue" }}>
                Action Types
              </Divider>
              <Row style={{ margin: "0 0 20px 5px", display: "flex", alignItems: "center" }}>
                <div>Select All</div>
                <Switch size="small" style={{ marginLeft: "10px" }} onChange={switchHandler} />
              </Row>

              <Checkbox.Group style={{ width: "100%" }} value={checkedList} onChange={checkBoxHandler}>
                <Row>
                  {coTypes?.map((x) => (
                    <Col key={x} span={12} style={{ marginBottom: "10px" }}>
                      <Checkbox value={x} key={x}>
                        {x}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>

              <Divider style={{ borderColor: "darkblue" }} />
              <Button
                type="primary"
                shape="round"
                size="middle"
                icon={<SearchOutlined />}
                block
                style={{ marginBottom: "15px" }}
                onClick={buttonHandler}
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CorporateAction;
