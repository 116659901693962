import React, { useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { AppstoreOutlined, AppstoreAddOutlined } from "@ant-design/icons";

const ADRLayout = (props) => {
  const [menuSelected, setMenuSelected] = useState("ADR Overview");
  return (
    <>
      <Menu
        onClick={(e) => setMenuSelected(e.key)}
        selectedKeys={[menuSelected]}
        style={{ marginBottom: "15px" }}
        theme="light"
        mode="horizontal"
      >
        <Menu.Item key="ADR Overview" icon={<AppstoreOutlined />}>
          <Link to="/adrcenter">ADR Overview</Link>
        </Menu.Item>
        <Menu.Item key="ADR Search" icon={<AppstoreAddOutlined />}>
          <Link to="/adrcenter/adrsearch">ADR Search</Link>
        </Menu.Item>
      </Menu>
      {props.children}
    </>
  );
};

export default ADRLayout;
