import React, { useState } from "react";
import moment from "moment";
import { Row, Col } from "antd";
import { blue } from "@ant-design/colors";
import {
    Loader,
    StockDetailTitle,
    StockDetailChart,
    StockDetailRight,
    StockDetailNews,
    StockDetailEarnings,
    StockDetailFundamentals,
    StockDetailOwnership,
} from "../components";
import { useParams } from "react-router-dom";
import { useCompanyResearchQuery, useEquityQuoteQuery, useOwnershipDetailsQuery } from "../services/orbisApi";

const StockDetail = () => {
    const { symbol } = useParams();
    const company = useCompanyResearchQuery({ symbol });
    const quote = useEquityQuoteQuery({ symbols: symbol });
    const ownership = useOwnershipDetailsQuery({
        symbols: symbol,
        filter: "ALL",
        sort: "market",
        count: 10,
        page: 0,
    });

    return (
        <>
            {/* Main */}
            <Row gutter={[15, 15]}>
                <Col xs={24} sm={24} lg={24} xl={18} xxl={18}>
                    <div style={{ backgroundColor: "white", padding: "15px" }}>
                        {quote.isFetching || company.isFetching ? (
                            <Loader />
                        ) : (
                            <StockDetailTitle quote={quote?.data[0]} company={company?.data} />
                        )}
                    </div>
                    <div style={{ backgroundColor: "white", marginTop: "15px", padding: "15px" }}>
                        <Col xs={24} xxl={24}>
                            <StockDetailChart symbol={symbol} />
                        </Col>
                    </div>
                    <div style={{ backgroundColor: "white", marginTop: "15px", padding: "15px" }}>
                        <Col xs={24} xxl={24}>
                            <StockDetailNews symbol={symbol} />
                        </Col>
                    </div>

                    {/* <div style={{ backgroundColor: "white", marginTop: "15px", padding: "15px" }}>
            <Col xs={24} xxl={24}>
              <StockDetailEarnings symbol={symbol} />
            </Col>
          </div>
          <div style={{ backgroundColor: "white", marginTop: "15px", padding: "15px" }}>
            <Col xs={24} xxl={24}>
              <StockDetailFundamentals symbol={symbol} />
            </Col>
          </div> */}
                    {ownership.isSuccess ? (
                        ownership.data[0].details.length > 0 ? (
                            <div style={{ backgroundColor: "white", marginTop: "15px", padding: "15px" }}>
                                <Col xs={24} xxl={24}>
                                    <StockDetailOwnership data={ownership?.data[0].details} />
                                </Col>
                            </div>
                        ) : (
                            <div></div>
                        )
                    ) : (
                        <div></div>
                    )}
                </Col>

                {/* Right */}
                <Col xs={24} sm={24} lg={24} xl={6} xxl={6}>
                    <div style={{ backgroundColor: "white", padding: "15px" }}>
                        {company.isFetching || quote.isFetching ? (
                            <Loader />
                        ) : (
                            <StockDetailRight quote={quote?.data[0]} company={company?.data} />
                        )}
                    </div>
                    <div style={{ backgroundColor: "white", marginTop: "15px", padding: "15px" }}>Advertisement</div>
                </Col>
            </Row>
        </>
    );
};

export default StockDetail;
