import React from 'react';
// import ReactDOM from 'react-dom';    // React 17 before...
import {createRoot} from 'react-dom/client'
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './app/store'
import 'antd/dist/antd.css'

const root = createRoot(document.getElementById('root'))
root.render (<BrowserRouter>
    <Provider store={store}>
        <App/>
    </Provider>
</BrowserRouter>)

// // React 17 before...
// ReactDOM.render (<BrowserRouter>
//     <Provider store={store}>
//         <App/>
//     </Provider>
// </BrowserRouter>, document.getElementById('root'));
