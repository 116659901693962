import React, { useState } from "react";
import { Row, Col, Select } from "antd";
import { countrySelection } from "../data/countrySelection";
import { ADRSearchTable, Loader } from "../components";
import { useAdrSearchQuery } from "../services/orbisApi";

const ADRSearch = () => {
  const [country, setCountry] = useState(["ARG", "Argentina"]);

  const selectionArr = countrySelection.slice(1);

  const { data, isFetching } = useAdrSearchQuery({
    country: country[0],
    loadQuotes: true,
    loadEarningReleases: false,
    loadUpgradesDowngrades: false,
  });

  return (
    <>
      <Row style={{ marginBottom: "15px" }} className="block-container">
        <Col xs={24} sm={24} lg={24} xl={8} xxl={8} style={{ margin: "5px" }}>
          <div style={{ color: "darkgray", margin: "0px 0px 1px 10px" }}>Country</div>
          <Select
            size="middle"
            placeholder="Country"
            value={country[1]}
            style={{ width: "100%" }}
            onChange={(e, { key, value }) => setCountry([key, value])}
          >
            {selectionArr.map((x) => (
              <Select.Option value={x[1]} key={x[0]}>
                {x[1]}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      {isFetching ? (
        <Loader />
      ) : (
        <Row>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            <ADRSearchTable data={data} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ADRSearch;
