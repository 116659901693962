import React, { useState } from "react";
import { useEtfSectorBreakdownQuery } from "../services/orbisApi";
import { ETFSectorTable, Loader } from "../components";
import { Row, Col, Select } from "antd";

const ETFSector = () => {
  const [duration, setDuration] = useState(["1d", "One Day"]);

  const { data, isFetching } = useEtfSectorBreakdownQuery({
    duration: duration[0],
    displaySize: 5,
    exposure: 90,
  });

  const durationSelectOptions = [
    ["1d", "One Day"],
    ["1w", "One Week"],
    ["2w", "Two Weeks"],
    ["1m", "One Month"],
  ];

  return (
    <>
      <Row style={{ marginBottom: "15px" }} className="block-container">
        <Col xs={24} sm={24} lg={24} xl={6} xxl={6} style={{ margin: "5px" }}>
          <div style={{ color: "darkgray", margin: "0px 0px 1px 10px" }}>Duration</div>
          <Select
            size="middle"
            placeholder="Duration"
            value={duration[1]}
            style={{ width: "100%" }}
            onChange={(e, { key, value }) => setDuration([key, value])}
          >
            {durationSelectOptions.map((x) => (
              <Select.Option value={x[1]} key={x[0]}>
                {x[1]}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>

      {isFetching ? (
        <Loader />
      ) : (
        <Row gutter={[15, 15]}>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={8}>
            <ETFSectorTable title="Energy" sectorData={data.ENERGY} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={8}>
            <ETFSectorTable title="Basic Material" sectorData={data.MATERIALS} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={8}>
            <ETFSectorTable title="Industrials" sectorData={data.INDUSTRIALS} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={8}>
            <ETFSectorTable title="Consumer Cyclical" sectorData={data.CONSUMER_DISCRETIONARY} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={8}>
            <ETFSectorTable title="Consumer Defensive" sectorData={data.CONSUMER_STAPLES} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={8}>
            <ETFSectorTable title="Healthcare" sectorData={data.HEALTH_CARE} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={8}>
            <ETFSectorTable title="Financial Services" sectorData={data.FINANCIALS} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={8}>
            <ETFSectorTable title="Real Estate" sectorData={data.REAL_ESTATE} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={8}>
            <ETFSectorTable title="Technology" sectorData={data.INFORMATION_TECHNOLOGY} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={8}>
            <ETFSectorTable title="Communication Services" sectorData={data.TELECOMMUNICATION} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={12} xxl={8}>
            <ETFSectorTable title="Utilities" sectorData={data.UTILITIES} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ETFSector;
