import React from "react";
import millify from "millify";
import { Typography, Statistic, Row, Col, Space, Button, Tag, Descriptions, Tooltip } from "antd";
import { StarOutlined, CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
import { blue } from "@ant-design/colors";

const { Text, Title, Paragraph } = Typography;
const { Item } = Descriptions;

const StockDetailTitle = ({ quote, company }) => {
  return (
    <>
      <Row
        style={{
          padding: "0px 15px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Col style={{ display: "flex", flexDirection: "column" }}>
          <Title level={3} style={{ fontWeight: "900", margin: "0px" }}>
            {quote?.companyName} {company?.adr ? <Tag color="volcano">ADR</Tag> : ""}{" "}
            {company?.etf ? <Tag color="geekblue">ETF</Tag> : ""}
          </Title>
          <Title level={5} type="secondary" style={{ fontWeight: "600", margin: "5px 0px" }}>
            {quote?.symbol} : {quote?.exchange} ({quote?.currency})
          </Title>
        </Col>
        <Col style={{ display: "flex", flexDirection: "column" }}>
          <Tooltip placement="topRight" title="Add to favorite list">
            <StarOutlined style={{ fontSize: "150%", margin: "5px" }} />
          </Tooltip>
        </Col>
      </Row>

      <Row style={{ padding: "0px 15px", display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Col>
          <Title
            level={4}
            style={{
              color: quote?.lastPrice > quote?.yesterdayClose ? "green" : "red",
              fontWeight: "900",
              marginRight: "20px",
            }}
          >
            {quote?.lastPrice}
          </Title>
        </Col>
        <Col>
          {quote?.lastPrice > quote?.yesterdayClose ? (
            <CaretUpFilled style={{ color: "green", fontSize: "150%" }} />
          ) : (
            <CaretDownFilled style={{ color: "red", fontSize: "150%" }} />
          )}
        </Col>
        <Col style={{ display: "flex", flex: "1" }}>
          <Title
            level={5}
            style={{
              color: quote?.lastPrice > quote?.yesterdayClose ? "green" : "red",
              fontWeight: "900",
              marginRight: "20px",
            }}
          >
            {quote?.change} ({quote?.changePercent.toFixed(2)}%)
          </Title>
        </Col>

        <Col style={{ display: "flex", flexDirection: "row", marginRight: "30px" }}>
          <p>Vol.</p>
          <Title level={5} style={{ fontWeight: "700" }}>
            {quote?.volume.toLocaleString()}
          </Title>
        </Col>

        <Col style={{ display: "flex", flexDirection: "row" }}>
          <p style={{ marginRight: "4px" }}>Day Range</p>
          <Title level={5} style={{ fontWeight: "700" }}>
            ${quote?.dayLow}-${quote?.dayHigh}
          </Title>
        </Col>
        {/* <Col>{quote?.yesterdayClose}</Col>
        <Col>{quote?.marketCap}</Col>
        <Col>
          {quote?.low52week} ~ {quote?.high52week}
        </Col>
        <Col>
          {quote?.dayLow} ~ {quote?.dayHigh}
        </Col> */}
      </Row>
      <Row style={{ padding: "5px 0px 10px 15px" }}>
        <Descriptions
          column={{ xs: 1, sm: 1, lg: 3, xl: 4, xxl: 4 }}
          size="small"
          labelStyle={{
            color: blue[7],
            margin: "4px 0px",
            // fontSize: "12px",
            fontWeight: "400",
            borderBottom: "#d9d9d9 1px solid",
          }}
          contentStyle={{
            margin: "4px 0px",
            // fontSize: "12px",
            fontWeight: "600",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "35px",
            borderBottom: "#d9d9d9 1px solid",
          }}
        >
          <Item label="Symbol">{quote?.symbol}</Item>
          {/* <Item label="Country">{quote?.country}</Item> */}
          {/* <Item label="Currency">{quote?.currency}</Item> */}
          {/* <Item label="ISIN">{quote?.isin}</Item> */}
          {/* <Item label="CUSIP">{quote?.cusip}</Item> */}
          <Item label="Open">${quote?.openingPrice}</Item>
          {/* <Item label="Day Range">
          ${quote?.dayLow} ~ ${quote?.dayHigh}
        </Item> */}
          <Item label="Day High">${quote?.dayHigh}</Item>
          <Item label="Day Low">${quote?.dayLow}</Item>
          <Item label="52W High">${quote?.high52week}</Item>
          <Item label="52W Low">${quote?.low52week}</Item>

          {/* <Item label="52W Range">
          ${quote?.low52week} ~ ${quote?.high52week}
        </Item> */}
          <Item label="Outstanding">{millify(quote?.sharesOutstanding)}</Item>
          <Item label="Yesterday Close">${quote?.yesterdayClose}</Item>
          <Item label="Yesterday Vol.">{millify(quote?.yesterdayVolume)}</Item>
          <Item label="Market Cap">${millify(quote?.marketCap)}</Item>
          <Item label="30days Avg Vol.">{millify(quote?.averageVolume30)}</Item>
          <Item label="Market Cap Size">{quote?.marketCapClassification}</Item>
          <Item label="Beta">{quote?.beta}</Item>
          <Item label="Dividend Rate">{quote?.dividendRate}%</Item>
          <Item label="PE Ratio">{quote?.priceEarningRatio.toFixed(2)}%</Item>
          <Item label="EPS (12months)">{quote?.trailing12MonthsEps.toFixed(2)}</Item>
        </Descriptions>
      </Row>
    </>
  );
};

export default StockDetailTitle;
