import React from "react";
import { Table, Divider, Typography } from "antd";
import { Loader } from "../components";
import millify from "millify";
import { grey } from "@ant-design/colors";
// import { useOwnershipDetailsQuery } from "../services/orbisApi";

const { Text, Title } = Typography;

const StockDetailOwnership = ({ data }) => {
  //   const ownership = useOwnershipDetailsQuery({
  //     symbols: symbol,
  //     filter: "ALL",
  //     sort: "market",
  //     count: 10,
  //     page: 0,
  //   });

  const columns = [
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      align: "center",
      width: "40%",
    },
    {
      title: "Owner Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: "15%",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      align: "center",
      width: "15%",
      render: (text) => <div>{text}%</div>,
      sorter: (a, b) => a.percentage - b.percentage,
    },
    {
      title: "Shares",
      dataIndex: "shares",
      key: "shares",
      align: "right",
      width: "15%",
      sorter: (a, b) => a.shares - b.shares,
      render: (text) => <div>{text.toLocaleString()}</div>,
    },
    {
      title: "Market Value",
      dataIndex: "market",
      key: "market",
      align: "right",
      width: "15%",
      sorter: (a, b) => a.market - b.market,
      render: (text) => <div>${millify(text)}</div>,
    },
  ];

  const dataSource = data.map((x) => ({
    key: x.ownerId,
    owner: x.ownerName,
    type: x.ownerType,
    percentage: x.percentageOwnership,
    shares: x.numberOfShares,
    market: x.marketValue,
  }));

  return (
    <>
      <Title level={4}>Top 10 Ownership</Title>
      <Divider style={{ borderColor: grey[0], marginTop: "-5px" }} />

      <Table columns={columns} dataSource={dataSource} size="small" pagination={false} />
    </>
  );
};

export default StockDetailOwnership;
