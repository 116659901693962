import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const productMasterSlice = createSlice({
  name: "productMaster",
  initialState,
  reducers: {
    updateProductMaster: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updateProductMaster } = productMasterSlice.actions;

export default productMasterSlice.reducer;
