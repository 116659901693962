import React, { useState, Fragement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Input, Space, Dropdown, Menu, Select, AutoComplete, Avatar } from "antd";
import {
    UserOutlined,
    SearchOutlined,
    DownOutlined,
    SmileOutlined,
    SettingOutlined,
    UserDeleteOutlined,
    UserAddOutlined,
} from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import { useProductMasterSymbolQuery } from "../services/orbisApi";
import { updateProductMaster } from "../features/productMasterSlice";
import { logout } from "../features/authSlice";
import { Link, useNavigate } from "react-router-dom";

const { Search } = Input;
const { Header } = Layout;
const { Option } = Select;

const MyHeader = () => {
    const { data, isFetching, isSuccess } = useProductMasterSymbolQuery();
    const [symbol, setSymbol] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);

    const auth = useSelector((state) => state.auth.value);

    let optionArr = [];
    data?.SYMBOL.map((x) => {
        let obj = {};
        obj["value"] = x;
        optionArr.push(obj);
    });

    dispatch(updateProductMaster(optionArr));

    const handlefilterOption = (inputValue, option) => {
        return option.value.indexOf(inputValue) !== -1;
    };

    return (
        <Header
            style={{
                padding: "25px",
                // border: "5px #FFAC55 solid",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                background: "#fff",
            }}
        >
            <Space size="large">
                <AutoComplete
                    size="middle"
                    placeholder="Symbol"
                    defaultActiveFirstOption={false}
                    defaultOpen={false}
                    notFoundContent={<div></div>}
                    value={symbol}
                    options={optionArr}
                    onChange={(e) => setSymbol(e.toUpperCase())}
                    onSelect={() => {
                        navigate(`/stockdetail/${symbol}`);
                        setSymbol("");
                    }}
                    style={{ width: "180px" }}
                    filterOption={handlefilterOption}
                ></AutoComplete>

                <Dropdown
                    // overlayStyle={{ width: 250 }}
                    overlay={
                        <Menu defaultSelectedKeys={[]}>
                            <Space direction="vertical">
                                {isAuthenticated ? (
                                    <>
                                        <Menu.Item
                                            icon={<SettingOutlined />}
                                            key="settings"
                                            eventKey="settings"
                                            style={{ width: "200px" }}
                                        >
                                            User settings
                                        </Menu.Item>
                                        <Menu.Item
                                            icon={<UserDeleteOutlined />}
                                            key="logout"
                                            eventKey="logout"
                                            style={{ width: "200px" }}
                                        >
                                            <a
                                                href="#!"
                                                onClick={() => {
                                                    dispatch(logout());
                                                }}
                                            >
                                                Logout
                                            </a>
                                        </Menu.Item>
                                    </>
                                ) : (
                                    <>
                                        <Menu.Item
                                            icon={<UserAddOutlined />}
                                            key="login"
                                            eventKey="login"
                                            style={{ width: "200px" }}
                                        >
                                            <Link to="/login">Login</Link>
                                        </Menu.Item>
                                    </>
                                )}
                            </Space>
                        </Menu>
                    }
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <Avatar size="large" style={{ backgroundColor: blue[7] }} icon={<UserOutlined />} />
                            {/* <DownOutlined /> */}
                        </Space>
                    </a>
                </Dropdown>
            </Space>
        </Header>
    );
};

export default MyHeader;
