import React from "react";
import { Table, Tooltip, Descriptions } from "antd";
import { RightCircleFilled } from "@ant-design/icons";

const { Item } = Descriptions;

const CorporateActionTable = ({ data }) => {
    let DTCC_LINK = (x) => {
        return `https://portal.dtcc.com/gca/gca/getCompositeList.do?archiveFlag=n&callingApp=gca&webFlow=mainWebFlow&advancedSearch=false&showSearchResults=false&actionCode=&clientId=&queryNumber=0&columnNumber=0&quickSearch=false&showSearchForm=true&searchId=-1&loadedSearchId=&gcaid=&securityid=${x}&securityname=&assetType=&issuercountry=&eventtype=&eventSubtype=&_chkPosition=on&_compositeRecordStatusList%5B0%5D.value=on&_compositeRecordStatusList%5B1%5D.value=on&_compositeRecordStatusList%5B2%5D.value=on&_compositeRecordStatusList%5B3%5D.value=on&selectAllWorkflowStatus=Y&_selectAllWorkflowStatus=on&selectAllDtcManVol=Y&_selectAllDtcManVol=on&datetype=&startdate=&plusminusind=&numberdays=&fromTime=&toTime=&chkToday=&datetype2=&startdate2=&plusminusind2=&numberdays2=&fromTime2=&toTime2=&chkToday2=&chkWatchlist=&_chkModified=on&_chkActive=on&_chkProcessedAtDTC=on&_chkPosition=on&searchName=&baseClassName=compositeSearchResults`;
    };

    const columns = [
        {
            title: "Effect Date",
            dataIndex: "effDate",
            key: "effDate",
            align: "center",
            width: "12%",
            render: (text) => <div>{text.split(" ")[0]}</div>,
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            align: "center",
            width: "12%",
            render: (text, record) => (
                <Tooltip
                    placement="topLeft"
                    title={
                        <Descriptions
                            size="small"
                            column={1}
                            title="Detail Corporate Action"
                            labelStyle={{ fontWeight: "500" }}
                            contentStyle={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <Item label="Spinoff Ratio">{record.spinoffRatio}%</Item>
                            <Item label="Spinoff Factor">{record.splitFactor}</Item>
                            <Item label="Dividend Amount">$USD {record.dividendAmount}</Item>
                            <Item label="Symbol">
                                {record.oldSymbol}
                                <RightCircleFilled style={{ color: "darkgray", margin: "0 3px" }} />
                                {record.newSymbol}
                            </Item>
                            <Item label="Cusip">
                                {record.oldCusip}
                                <RightCircleFilled style={{ color: "darkgray", margin: "0 3px" }} />
                                {record.newCusip}
                            </Item>
                            <Item label="Exchange">
                                {record.oldExchange}
                                <RightCircleFilled style={{ color: "darkgray", margin: "0 3px" }} />
                                {record.newExchange}
                            </Item>
                            <Item label="DTCC Link">
                                <a href={DTCC_LINK(record.oldCusip)} target="_blank">
                                    {record.oldCusip}
                                </a>
                            </Item>
                        </Descriptions>
                    }
                    color="white"
                    arrowPointAtCenter
                >
                    <a href="#">{text}</a>
                </Tooltip>
            ),
        },
        {
            title: "Old Entry",
            children: [
                {
                    title: "Old Symbol",
                    dataIndex: "oldSymbol",
                    key: "oldSymbol",
                    align: "center",
                    width: "12%",
                },
                {
                    title: "Old Exchange",
                    dataIndex: "oldExchange",
                    key: "oldExchange",
                    align: "center",
                    width: "12%",
                },
                {
                    title: "Old Name",
                    dataIndex: "oldName",
                    key: "oldName",
                    align: "center",
                    responsive: ["xl"],
                    ellipsis: true,
                    width: "14%",
                },
            ],
        },
        {
            title: "New Entry",
            children: [
                {
                    title: "New Symbol",
                    dataIndex: "newSymbol",
                    key: "newSymbol",
                    align: "center",
                    width: "12%",
                },
                {
                    title: "New Exchange",
                    dataIndex: "newExchange",
                    key: "newExchange",
                    align: "center",
                    width: "12%",
                },
                {
                    title: "New Name",
                    dataIndex: "newName",
                    key: "newName",
                    align: "center",
                    responsive: ["xl"],
                    ellipsis: true,
                    width: "14%",
                },
            ],
        },
    ];

    const dataSource = data?.map((x) => ({
        key: x.effDate + x.oldEntry.symbol + x.type,
        effDate: x.effDate,
        type: x.type,
        oldSymbol: x.oldEntry.symbol,
        oldCusip: x.oldEntry.cusip,
        oldExchange: x.oldEntry.exchange,
        oldName: x.oldEntry.companyName,
        newSymbol: x.newEntry.symbol,
        newExchange: x.newEntry.exchange,
        newName: x.newEntry.companyName,
        newCusip: x.newEntry.cusip,
        dividendAmount: x.dividendAmount,
        grossDividendAmount: x.grossDividendAmount,
        spinoffRatio: x.spinoffRatio,
        splitFactor: x.splitFactor,
    }));

    return (
        <div style={{ marginBottom: "15px" }}>
            <Table columns={columns} dataSource={dataSource} size="small" pagination={false} />
        </div>
    );
};

export default CorporateActionTable;
