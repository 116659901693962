import React from "react";
import { Card, Col } from "antd";
import { CaretUpFilled, CaretDownFilled, EllipsisOutlined } from "@ant-design/icons";
import { blue, orange, cyan, lime } from "@ant-design/colors";

const HomeStockCard = ({ title, price, change, changePercent, bgColor }) => {
  return (
    <>
      <Card
        bordered={false}
        hoverable={true}
        bodyStyle={{ backgroundColor: bgColor, color: "black", fontSize: "16px" }}
      >
        <div style={{ fontSize: "16px", fontWeight: 700 }}>{title}</div>
        <div
          style={{
            marginTop: "4px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              display: "flex",
              fontWeight: 700,
              flex: 1,
              color: change > 0 ? "green" : change < 0 ? "red" : "darkgray",
            }}
          >
            ${price}
          </div>
          {change > 0 ? (
            <CaretUpFilled style={{ fontSize: "18px", color: "green" }} />
          ) : change < 0 ? (
            <CaretDownFilled style={{ fontSize: "18px", color: "red" }} />
          ) : (
            <EllipsisOutlined style={{ fontSize: "18px", color: "darkgray" }} />
          )}
          <div
            style={{
              fontSize: "14px",
              color: change > 0 ? "green" : change < 0 ? "red" : "darkgray",
            }}
          >
            {change} ({changePercent}%)
          </div>
        </div>
      </Card>
    </>
  );
};

export default HomeStockCard;
