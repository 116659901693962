import React, { useEffect, useState } from "react";
import millify from "millify";
import { Loader } from "../components";
import { Link } from "react-router-dom";
import { Table, Typography, Row, Col, Divider, Input } from "antd";
import { useProductMasterFractionalQuery } from "../services/orbisApi";

const Fractional = () => {
  const { data, isFetching, isSuccess } = useProductMasterFractionalQuery();
  const [searchItem, setSearchItem] = useState("");
  const [symbols, setSymbols] = useState([]);

  useEffect(() => {
    const filterData = data?.filter((x) => x.Ticker.includes(searchItem));

    setSymbols(filterData);
  }, [searchItem, data]);

  const columns = [
    {
      title: "Symbol",
      dataIndex: "Ticker",
      key: "Ticker",
      align: "center",
      width: "5%",
      render: (text) => <Link to={"/stockdetail/" + text}>{text}</Link>,
    },
    {
      title: "MIC",
      dataIndex: "MIC",
      key: "MIC",
      align: "center",
      width: "5%",
      responsive: ["lg"],
    },
    {
      title: "Name",
      dataIndex: "COMPANY_NAME",
      key: "COMPANY_NAME",
      align: "center",
      width: "16%",
      responsive: ["lg"],
    },
    {
      title: "Last Price",
      dataIndex: "LAST_PRICE",
      key: "LAST_PRICE",
      align: "center",
      width: "6%",
      responsive: ["lg"],
      render: (text, record) => {
        let color = text >= record.OPENING_PRICE ? "green" : "red";
        return <div style={{ color }}>{text}</div>;
      },
    },
    {
      title: "Prev Close",
      dataIndex: "PREVIOUS_CLOSE",
      key: "PREVIOUS_CLOSE",
      align: "center",
      width: "6%",
    },
    {
      title: "Opening Close",
      dataIndex: "OPENING_PRICE",
      key: "OPENING_PRICE",
      align: "center",
      width: "6%",
      render: (text, record) => {
        let color = text >= record.PREVIOUS_CLOSE ? "green" : "red";
        return <div style={{ color }}>{text}</div>;
      },
    },
    {
      title: "Volume",
      dataIndex: "VOLUME",
      key: "VOLUME",
      align: "center",
      width: "8%",
      render: (text) => millify(Number(text)),
    },
    {
      title: "Change",
      dataIndex: "CHANGE",
      key: "CHANGE",
      align: "center",
      width: "6%",
      render: (text) => {
        let color = text > 0 ? "green" : text < 0 ? "red" : "black";
        return <div style={{ color }}>{Number(text).toFixed(2)}%</div>;
      },
    },
    {
      title: "ISIN",
      dataIndex: "ISIN",
      key: "ISIN",
      align: "center",
      width: "8%",
    },
    {
      title: "CUSIP",
      dataIndex: "CUSIP",
      key: "CUSIP",
      align: "center",
      width: "8%",
      responsive: ["xl"],
    },
    {
      title: "Market Cap",
      dataIndex: "MARKET_CAP",
      key: "MARKET_CAP",
      align: "center",
      width: "6%",
      responsive: ["xl"],
      render: (text) => millify(Number(text)),
    },
    {
      title: "Avg 30days Vol",
      dataIndex: "AVG_30D_VOLUME",
      key: "AVG_30D_VOLUME",
      align: "center",
      responsive: ["xxl"],
      width: "8%",
      render: (text) => millify(Number(text)),
    },
    {
      title: "52week High",
      dataIndex: "HIGH_52W",
      key: "HIGH_52W",
      align: "center",
      responsive: ["xxl"],
      width: "6%",
    },
    {
      title: "52week Low",
      dataIndex: "LOW_52W",
      key: "LOW_52W",
      align: "center",
      responsive: ["xxl"],
      width: "6%",
    },
  ];

  const dataSource = symbols?.map((x) => ({
    key: x.Ticker,
    Ticker: x.Ticker,
    AVG_30D_VOLUME: x.AVG_30D_VOLUME,
    CHANGE: x.CHANGE,
    COMPANY_NAME: x.COMPANY_NAME,
    CUSIP: x.CUSIP,
    HIGH_52W: x.HIGH_52W,
    ISIN: x.ISIN,
    LAST_PRICE: x.LAST_PRICE,
    LOW_52W: x.LOW_52W,
    MARKET_CAP: x.MARKET_CAP,
    MIC: x.MIC,
    OPENING_PRICE: x.OPENING_PRICE,
    PREVIOUS_CLOSE: x.PREVIOUS_CLOSE,
    VOLUME: x.VOLUME,
  }));

  return (
    <>
      <Row className={"block-container"}>
        <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
          <div className={"product-master-search"}>
            <Typography.Title level={4} style={{ marginLeft: "15px" }}>
              Fractional Symbols
            </Typography.Title>
            <Input.Search
              placeholder="Search symbol"
              size="middle"
              value={searchItem}
              onChange={(e) => {
                setSearchItem(e.target.value.toUpperCase());
              }}
              allowClear
              style={{ width: 200 }}
            />
          </div>
          <Divider style={{ borderColor: "darkblue", margin: "10px auto" }} />
          {isFetching ? (
            <Loader />
          ) : (
            <Table
              columns={columns}
              dataSource={dataSource}
              size="small"
              pagination={{
                position: ["topRight", "bottomRight"],
                defaultPageSize: 20,
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Fractional;
