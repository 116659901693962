import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const orbisApiHeaders = {};
const baseUrl = process.env.REACT_APP_SERVER_URL;
const createRequest = (url) => ({ url, headers: orbisApiHeaders });

export const orbisApi = createApi({
  reducerPath: "orbisApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // ==== Equity Research ====
    companyResearch: builder.query({
      query: ({ symbol }) => createRequest(`/api/research/${symbol}`),
    }),
    ownershipDetails: builder.query({
      query: ({ symbols, filter, sort, count, page }) =>
        createRequest(
          `/api/research/ownerships/details?symbols=${symbols}&filter=${filter}&sort=${sort}&count=${count}&page=${page}`
        ),
    }),
    ownerDetails: builder.query({
      query: ({ ids }) => createRequest(`/api/research/owner/details?ids=${ids}`),
    }),
    earningsCalendar: builder.query({
      query: ({ excludeOtcs, days, loadQuotes, symbols }) =>
        createRequest(
          `/api/research/earnings?excludeOtcs=${excludeOtcs}&days=${days}&loadQuotes=${loadQuotes}&symbols=${symbols}`
        ),
    }),
    fundamentals: builder.query({
      query: ({ type, symbol }) => createRequest(`/api/research/fundamentals/${type}/${symbol}`),
    }),
    fundamentalTypes: builder.query({
      query: () => createRequest("/api/research/fundamentals/types"),
    }),
    historicalData: builder.query({
      query: ({ symbol, dates }) => createRequest(`/api/research/historicalPrices?symbol=${symbol}&dates=${dates}`),
    }),
    industryHeatMap: builder.query({
      query: ({ industryType }) => createRequest(`/api/research/heatmap/industryranking?industryType=${industryType}`),
    }),
    industryList: builder.query({
      query: ({ industry }) => createRequest(`/api/research/industries/list?industry=${industry}`),
    }),
    symbolHeatMap: builder.query({
      query: ({ amountLimit, exchangeFilter, industryCode, numSymbols, top }) =>
        createRequest(
          `/api/research/heatMap/symbols?amountLimit=${amountLimit}&exchangeFilter=${exchangeFilter}&industryCode=${industryCode}&numSymbols=${numSymbols}&top=${top}`
        ),
    }),
    topStocks: builder.query({
      query: ({ category, market, order, count }) =>
        createRequest(`/api/quotes/top10/${category}?market=${market}&order=${order}&count=${count}`),
    }),

    // ==== QUOTE ====
    equityQuote: builder.query({
      query: ({ symbols }) => createRequest(`/api/quotes/equity?symbols=${symbols}`),
    }),
    equitySearch: builder.query({
      query: ({ criteria, limit, loadQuotes }) =>
        createRequest(`/api/quotes/search?criteria=${criteria}&limit=${limit}&loadQuotes=${loadQuotes}`),
    }),
    equityHistory: builder.query({
      query: ({ symbol, start, end, range, setWeekly, rsiSpan }) =>
        createRequest(
          `/api/quotes/equity/historical?symbol=${symbol}&start=${start}&end=${end}&range=${range}&setWeekly=${setWeekly}&rsiSpan=${rsiSpan}`
        ),
    }),
    intradayChart: builder.query({
      query: ({ symbol, rsiSpan, from, to, rawVolumes }) =>
        createRequest(
          `/api/quotes/equity/intraday?symbol=${symbol}&rsiSpan=${rsiSpan}&from=${from}&to=${to}&rawVolumes=${rawVolumes}`
        ),
    }),

    // ==== NEWS API ====
    listNews: builder.query({
      query: ({ start, count, filter }) =>
        createRequest(`/api/research/news?start=${start}&count=${count}&filter=${filter}`),
    }),
    symbolNews: builder.query({
      query: ({ symbol, start, count }) =>
        createRequest(`/api/research/news/ticker/${symbol}?start=${start}&count=${count}`),
    }),
    symbolNewsMu: builder.mutation({
      query: ({ symbol, start, count }) =>
        createRequest(`/api/research/news/ticker/${symbol}?start=${start}&count=${count}`),
    }),
    secNews: builder.query({
      query: ({ symbol }) => createRequest(`/api/research/news/sec/${symbol}`),
    }),
    lookupNews: builder.query({
      query: ({ newsId }) => createRequest(`/api/research/news/${newsId}`),
    }),

    // ==== ETFs API ====
    etfInitScreener: builder.query({
      query: () => createRequest(`/api/research/etf/init`),
    }),
    etfScreener: builder.query({
      query: ({
        inverseType,
        leverageType,
        assetId,
        categoryId,
        sponsorId,
        start,
        num,
        sort,
        asc,
        loadIndustries,
        loadUpgrades,
        loadEarnings,
        filters,
      }) =>
        createRequest(
          `/api/research/etf/screener?inverseType=${inverseType}&leverageType=${leverageType}&assetId=${assetId}&categoryId=${categoryId}&sponsorId=${sponsorId}&start=${start}&num=${num}&sort=${sort}&asc=${asc}&loadIndustries=${loadIndustries}&loadUpgrades=${loadUpgrades}&loadEarnings=${loadEarnings}&filters=${filters}`
        ),
    }),
    etfTopTen: builder.query({
      query: ({ inverseType, leverageType }) =>
        createRequest(`/api/research/etf/top10?inverseType=${inverseType}&leverageType=${leverageType}`),
    }),
    etfDetails: builder.query({
      query: ({ symbol, loadQuotes }) =>
        createRequest(`/api/research/etf/details?symbol=${symbol}&loadQuotes=${loadQuotes}`),
    }),
    etfSectorBreakdown: builder.query({
      query: ({ duration, displaySize, exposure }) =>
        createRequest(
          `/api/research/etf/breakdown?duration=${duration}&displaySize=${displaySize}&exposure=${exposure}`
        ),
    }),

    // ==== Index ====
    indexComponents: builder.query({
      query: ({ index, loadQuotes }) =>
        createRequest(`/api/research/index/components/${index}?loadQuotes=${loadQuotes}`),
    }),
    indexOverview: builder.query({
      query: () => createRequest(`/api/research/marketOverview`),
    }),

    // ==== ADRs ====
    adrTopTen: builder.query({
      query: ({ country, loadQuotes, loadEarningReleases, loadUpgradesDowngrades }) =>
        createRequest(
          `/api/research/adrs/top10?country=${country}&loadQuotes=${loadQuotes}&loadEarningReleases=${loadEarningReleases}&loadUpgradesDowngrades=${loadUpgradesDowngrades}`
        ),
    }),
    adrDefaults: builder.query({
      query: () => createRequest(`/api/research/adrs/top10/defaults`),
    }),
    adrSearch: builder.query({
      query: ({ country, loadQuotes, loadEarningReleases, loadUpgradesDowngrades }) =>
        createRequest(
          `/api/research/adrs?country=${country}&loadQuotes=${loadQuotes}&loadEarningReleases=${loadEarningReleases}&loadUpgradesDowngrades${loadUpgradesDowngrades}`
        ),
    }),

    // ==== Corporate Action ====
    corporateActionTypes: builder.query({
      query: () => createRequest(`/api/research/actions/types`),
    }),
    corporateActionSearch: builder.query({
      query: ({ symbol, dateFrom, dateTo, type, usePayDate }) =>
        createRequest(
          `/api/research/actions/search?symbol=${symbol}&dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}&usePayDate=${usePayDate}`
        ),
    }),
    coSearch: builder.mutation({
      // use mutation to query
      query: ({ symbol, dateFrom, dateTo, type, usePayDate }) =>
        createRequest(
          `/api/research/actions/search?symbol=${symbol}&dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}&usePayDate=${usePayDate}`
        ),
    }),

    // ==== IPO ====
    ipoRecent: builder.query({
      query: ({ loadQuotes, time, sort }) =>
        createRequest(`/api/research/ipo/recent?loadQuotes=${loadQuotes}&time=${time}&sort=${sort}`),
    }),
    ipoUpcoming: builder.query({
      query: () => createRequest(`/api/research/ipo/upcoming`),
    }),
    ipoPerformance: builder.query({
      query: ({ loadQuotes, time, sort }) =>
        createRequest(`/api/research/ipo/performance?loadQuotes=${loadQuotes}&time=${time}&sort=${sort}`),
    }),

    // ==== Product Master ====
    productMaster: builder.query({
      query: () => createRequest(`/api/productmaster`),
    }),
    productMasterOne: builder.query({
      query: ({ symbol }) => createRequest(`/api/productmasterone?symbol=${symbol}`),
    }),
    productMasterSymbol: builder.query({
      query: () => createRequest(`/api/productmastersymbol`),
    }),
    productMasterSymbolNamePrice: builder.query({
      query: () => createRequest(`/api/productmastersymbolnameprice`),
    }),
    productMasterFractional: builder.query({
      query: () => ({
        url: "/api/productmasterfractional",
        method: "GET",
      }),
    }),

    // ==== FIX ====
    FIXUAT: builder.query({
      query: () => ({
        url: "/api/FIX/UAT",
        method: "GET",
      }),
    }),
    FIXPROD: builder.query({
      query: () => ({
        url: "/api/FIX/Prod",
        method: "GET",
      }),
    }),
  }),
});

export const {
  usePrefetch,
  useCompanyResearchQuery,
  useOwnershipDetailsQuery,
  useOwnerDetailsQuery,
  useEarningsCalendarQuery,
  useFundamentalTypesQuery,
  useFundamentalsQuery,
  useHistoricalDataQuery,
  useIndustryHeatMapQuery,
  useIndustryListQuery,
  useSymbolHeatMapQuery,
  useTopStocksQuery,
  useListNewsQuery,
  useSymbolNewsQuery,
  useSymbolNewsMuMutation,
  useSecNewsQuery,
  useLookupNewsQuery,
  useEtfInitScreenerQuery,
  useEtfScreenerQuery,
  useEtfTopTenQuery,
  useEtfDetailsQuery,
  useEtfSectorBreakdownQuery,
  useIndexComponentsQuery,
  useIndexOverviewQuery,
  useAdrTopTenQuery,
  useAdrDefaultsQuery,
  useAdrSearchQuery,
  useCorporateActionTypesQuery,
  useCorporateActionSearchQuery,
  useCoSearchMutation,
  useIpoRecentQuery,
  useIpoUpcomingQuery,
  useIpoPerformanceQuery,
  useProductMasterQuery,
  useProductMasterOneQuery,
  useProductMasterSymbolQuery,
  useProductMasterSymbolNamePriceQuery,
  useEquityQuoteQuery,
  useEquitySearchQuery,
  useEquityHistoryQuery,
  useIntradayChartQuery,
  useProductMasterFractionalQuery,
  useFIXUATQuery,
  useFIXPRODQuery,
} = orbisApi;
