import React, { useState } from "react";
import { Typography, Radio } from "antd";
import { blue, gold } from "@ant-design/colors";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    registerables,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
    ...registerables,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const PositionHistory = ({ positions, positionsQuarter }) => {
    // console.log(Object.keys(positions.dailyAmount));
    // console.log(Object.values(positions.dailyAmount));
    // console.log(positions.dailyAmount?.map((x) => x.EntryDate));
    // console.log(positions.dailyAmount?.map((x) => x.AccountTotal));

    const [opSelection, setOpSelection] = useState("Quarterly");

    console.log(positionsQuarter.quarterlyAmount);

    const options = {
        responsive: true,
        interaction: {
            mode: "index",
            intersect: false,
        },
        stacked: false,
        scales: {
            y: {
                type: "linear",
                display: true,
                grace: "10%",
                position: "left",
                ticks: {
                    maxTicksLimit: 10,
                },
            },
            x: {
                ticks: {
                    maxTicksLimit: 20,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: "Position Records",
            },
        },
    };

    const data = {
        labels:
            opSelection == "Daily"
                ? positions.dailyAmount?.map((x) => x.EntryDate)
                : positionsQuarter?.quarterlyAmount?.map((x) => x.quarter),
        datasets: [
            {
                type: "bar",
                label: "Amount",
                data:
                    opSelection == "Daily"
                        ? positions.dailyAmount?.map((x) => x.AccountTotal)
                        : positionsQuarter?.quarterlyAmount?.map((x) => x.AccountTotal),
                // lineTension: 0.15,
                // fill: "origin",
                // borderColor: blue[1],
                backgroundColor: gold[5],
                // pointRadius: 0,
                yAxisID: "y",
            },
        ],
    };

    return (
        <div>
            <div
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "15px" }}
            >
                <Typography.Title level={4}>Position Records</Typography.Title>
                <Radio.Group
                    options={["Quarterly", "Daily"]}
                    onChange={(e) => setOpSelection(e.target.value)}
                    optionType="button"
                    buttonStyle="solid"
                    value={opSelection}
                ></Radio.Group>
            </div>
            <Chart options={options} data={data} />
        </div>
    );
};

export default PositionHistory;
