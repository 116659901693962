import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AuthorizationPage, Loader } from "../components";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import { Row, Col, Space, DatePicker, Card, Table, Divider, Button, Tag, Alert } from "antd";
import { Link } from "react-router-dom";
import { useEtfListQuery, useEtfCompareMutation } from "../services/analysisApi";

const ETFList = () => {
  const [prevDate, setPrevDate] = useState({});
  const [nextDate, setNextDate] = useState({});

  const { data: ETFListResult, isFetching } = useEtfListQuery({ date: "" });
  const [compareETF, compareETF_result] = useEtfCompareMutation();
  const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);

  const checkDiff = async () => {
    if (prevDate.dateString !== undefined && nextDate.dateString !== undefined) {
      console.log(prevDate.dateString, nextDate.dateString);
    }

    await compareETF({ date1: prevDate.dateString, date2: nextDate.dateString });

    setPrevDate({});
    setNextDate({});
  };

  console.log(compareETF_result);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "50%",
      render: (text) => {
        return moment(text).format("MM/DD/YYYY");
      },
    },
    {
      title: "Symbol List",
      dataIndex: "symbol",
      key: "symbol",
      align: "center",
      width: "50%",
      render: (text, record) => {
        let newArr = [];
        for (let i = 0; i < text.length; i++) {
          newArr.push([text[i]]);
        }
        return (
          <CSVLink filename={moment(record.date).format("MM/DD/YYYY") + ".csv"} data={newArr}>
            Download
          </CSVLink>
        );
      },
    },
  ];

  const dataSource = ETFListResult?.map((x) => ({
    key: x.date,
    date: x.date,
    symbol: x.symbol,
  }));

  return (
    <>
      {!isAuthenticated ? (
        <AuthorizationPage />
      ) : (
        <>
          <Row gutter={[15, 15]} style={{ flexDirection: "row-reverse" }}>
            <Col order={2} xs={24} sm={24} lg={24} xl={18} xxl={18}>
              <Card hoverable={true}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Space size="large" style={{ width: "100%" }}>
                    <DatePicker
                      size="middle"
                      onChange={(date, dateString) => setPrevDate({ ...prevDate, date, dateString })}
                      value={prevDate.date}
                    />
                    <DatePicker
                      size="middle"
                      onChange={(date, dateString) => setNextDate({ ...nextDate, date, dateString })}
                      value={nextDate.date}
                    />
                    <Button size="middle" type="primary" onClick={checkDiff}>
                      CHECK
                    </Button>
                  </Space>
                  <Divider />

                  {compareETF_result.isSuccess ? (
                    Object.hasOwn(compareETF_result.data, "error") ? (
                      <Alert message={compareETF_result.data.error} type="error" />
                    ) : (
                      <>
                        <Space>
                          <b>ADD:</b>
                          {compareETF_result?.data?.add.map((x) => (
                            <Tag key={x} color="blue">
                              {x}
                            </Tag>
                          ))}
                        </Space>
                        <Space>
                          <b>REMOVE:</b>
                          {compareETF_result?.data?.remove.map((x) => (
                            <Tag key={x} color="volcano">
                              {x}
                            </Tag>
                          ))}
                        </Space>
                      </>
                    )
                  ) : (
                    <></>
                  )}
                </Space>
              </Card>
              <Card hoverable={true} style={{ marginTop: "15px" }}>
                {isFetching ? (
                  <Loader />
                ) : (
                  <Table columns={columns} dataSource={dataSource} size="middle" pagination={false} />
                )}
              </Card>
            </Col>

            <Col order={1} xs={24} sm={24} lg={24} xl={6} xxl={6}>
              <Card hoverable={true}>Get ETF List</Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ETFList;
