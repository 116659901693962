import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Loader,
    CorporateActionTable,
    AuthorizationPage,
    PositionTitle,
    PositionTable,
    PositionTreeMap,
    PositionHistory,
} from "../components";
import { blue } from "@ant-design/colors";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
    Row,
    Col,
    Card,
    Select,
    Space,
    Table,
    DatePicker,
    AutoComplete,
    Button,
    Skeleton,
    Typography,
    Spin,
    Alert,
} from "antd";
import {
    usePositionMutation,
    useHistoryPositionsMutation,
    useHistoryPositionsQuarterMutation,
    useAllAccountsQuery,
    useAccountValueMuMutation,
} from "../services/analysisApi";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const Position = () => {
    const location = useLocation();
    const [account, setAccount] = useState(location?.state?.account);
    const [server, setServer] = useState(location?.state?.server);
    const [companyName, setCompanyName] = useState("");
    const [date, setDate] = useState([]);
    const [getPosition, positionResult] = usePositionMutation();
    const [getHistory, historyResult] = useHistoryPositionsMutation();
    const [getHistoryQuarter, historyResultQuarter] = useHistoryPositionsQuarterMutation();
    const [getAccountValue, accountValueResult] = useAccountValueMuMutation();
    const allAccountsResult = useAllAccountsQuery({ isempty: false });
    const isAuthenticated = useSelector((state) => state.auth.value.isAuthenticated);

    useEffect(() => {
        setAccount(location?.state?.account);
        setServer(location?.state?.server);

        const fetchData = async () => {
            await Promise.all([
                getPosition({ account, server }),
                getHistory({
                    omnibusaccount: account,
                    startdate: moment().subtract(5, "years").format("MM/DD/YYYY"),
                    enddate: moment().format("MM/DD/YYYY"),
                    db: server,
                }),
                getHistoryQuarter({
                    omnibusaccount: account,
                    startdate: moment().subtract(5, "years").format("YYYY-MM-DD"),
                    enddate: moment().format("YYYY-MM-DD"),
                }),
                getAccountValue({ account }),
            ]);

            // 因為 getPosition & getHistory 都是從 BO 直接抓，同時處理的話會造成重複 login，因此先用分開處理，等之後下載資料庫再執行上面同步處裡的方式
            // await getPosition({ account, server });
            // await getHistory({
            //     omnibusaccount: account,
            //     startdate: moment().subtract(36, "months").format("MM/DD/YYYY"),
            //     enddate: moment().format("MM/DD/YYYY"),
            //     db: server,
            // });
        };
        fetchData();

        setAccount("");
        setServer("");
    }, []);

    // console.log(moment().subtract(36, "months").format("MM/DD/YYYY"));

    const handleSearch = async () => {
        const result = await Promise.all([
            getPosition({ account, server }),
            getHistory({ omnibusaccount: account, startdate: date[0], enddate: date[1], db: server }),
            getAccountValue({ account }),
        ]);

        // 因為 getPosition & getHistory 都是從 BO 直接抓，同時處理的話會造成重複 login，因此先用分開處理，等之後下載資料庫再執行上面同步處裡的方式
        // getPosition({ account, server });
        // getHistory({ omnibusaccount: account, startdate: date[0], enddate: date[1], db: server });

        setAccount("");
        setServer("");
        setCompanyName("");
    };

    const serverOptions = [
        { value: "BNPFIX" },
        { value: "BROADFIX" },
        { value: "BROADVIEW" },
        { value: "GENFIX" },
        { value: "HAITONG" },
        { value: "OPENVIEW" },
    ];

    const accountOptions = allAccountsResult?.data?.map((x) => {
        return {
            value: x.account,
        };
    });

    const companyOptions = allAccountsResult?.data?.map((x) => {
        return {
            value: `(${x.account}) ${x.name}`,
        };
    });

    const handlefilterOption = (inputValue, option) => {
        return option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    };

    const handleSelectAccount = (value, option) => {
        const findAccount = allAccountsResult.data.find((x) => x.account === value);
        setAccount(findAccount.account);
        setServer(findAccount.db);
        setCompanyName(findAccount.name);
    };

    const handleSelectCompany = (value, option) => {
        let acctNo = value.split(" ")[0].slice(1, -1);
        const findCompany = allAccountsResult.data.find((x) => x.account === acctNo);
        setAccount(findCompany.account);
        setServer(findCompany.db);
        setCompanyName(findCompany.name);
    };

    return (
        <>
            {!isAuthenticated ? (
                <AuthorizationPage />
            ) : (
                <Row gutter={[15, 15]} style={{ flexDirection: "row-reverse" }}>
                    <Col order={2} xs={24} sm={24} lg={24} xl={18} xxl={18}>
                        {/* <Card>{positionResult.isSuccess ? <Title level={4}>{positionResult.data.title}</Title> : <Skeleton />}</Card> */}

                        <Spin spinning={positionResult?.isLoading} tip="Calculating, it may take a while...">
                            {positionResult.isSuccess && accountValueResult.isSuccess ? (
                                positionResult.data.error !== undefined ? (
                                    <Card hoverable={true}>
                                        <Alert message={positionResult.data.error} type="error" />
                                    </Card>
                                ) : (
                                    <>
                                        <Card hoverable={true}>
                                            <PositionTitle count={positionResult.data} data={accountValueResult.data} />
                                        </Card>
                                        <Card hoverable={true} style={{ marginTop: "15px" }}>
                                            <PositionTreeMap data={positionResult.data} />
                                        </Card>
                                        <Card hoverable={true} style={{ marginTop: "15px" }}>
                                            <PositionTable data={positionResult.data} />
                                        </Card>
                                    </>
                                )
                            ) : (
                                <Card hoverable={true}>
                                    <Skeleton />
                                </Card>
                            )}
                        </Spin>

                        <Spin spinning={historyResult.isLoading} tip="Calculating, it may take couple of minutes...">
                            {historyResult.isSuccess && historyResultQuarter.isSuccess ? (
                                historyResult.data.error !== undefined ? (
                                    <Card hoverable={true} style={{ marginTop: "15px" }}>
                                        <Alert message={historyResult.data.error} type="error" />
                                    </Card>
                                ) : (
                                    <Card hoverable={true} style={{ marginTop: "15px" }}>
                                        <PositionHistory
                                            positions={historyResult?.data}
                                            positionsQuarter={historyResultQuarter?.data}
                                        />
                                    </Card>
                                )
                            ) : (
                                <Card hoverable={true} style={{ marginTop: "15px" }}>
                                    <Skeleton />
                                </Card>
                            )}
                        </Spin>
                    </Col>
                    <Col order={1} xs={24} sm={24} lg={24} xl={6} xxl={6}>
                        <Card hoverable={true}>
                            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                                <div style={{ color: blue[7], margin: "0px 0px -10px 10px" }}>Account</div>
                                <AutoComplete
                                    allowClear
                                    placeholder="Account"
                                    value={account}
                                    onChange={(e) => setAccount(e.toUpperCase())}
                                    onSelect={(value, option) => handleSelectAccount(value, option)}
                                    defaultActiveFirstOption={false}
                                    defaultOpen={false}
                                    options={accountOptions}
                                    filterOption={handlefilterOption}
                                    style={{ width: "100%" }}
                                ></AutoComplete>

                                <div style={{ color: blue[7], margin: "0px 0px -10px 10px" }}>Server</div>
                                <AutoComplete
                                    allowClear
                                    placeholder="Server"
                                    value={server}
                                    onChange={(e) => setServer(e.toUpperCase())}
                                    defaultActiveFirstOption={false}
                                    defaultOpen={false}
                                    options={serverOptions}
                                    filterOption={handlefilterOption}
                                    style={{ width: "100%" }}
                                ></AutoComplete>

                                <div style={{ color: blue[7], margin: "0px 0px -10px 10px" }}>Company Name</div>
                                <AutoComplete
                                    allowClear
                                    placeholder="Company Name"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e)}
                                    onSelect={(value, option) => handleSelectCompany(value, option)}
                                    defaultActiveFirstOption={false}
                                    defaultOpen={false}
                                    options={companyOptions}
                                    filterOption={handlefilterOption}
                                    style={{ width: "100%" }}
                                ></AutoComplete>

                                <div style={{ color: blue[7], margin: "0px 0px -10px 10px" }}>Date Range</div>
                                <RangePicker
                                    size="middle"
                                    // defaultValue={[moment(), moment()]}
                                    format={"MM/DD/YYYY"}
                                    style={{ width: "100%" }}
                                    onChange={(date, dateString) => {
                                        // setDate(date.map((x) => format(x._d, "MM/dd/yyyy")));
                                        setDate(dateString.map((x) => x));
                                        // console.log(format(date[0]._d, "MM/dd/yyyy"));
                                        // setDate(dateString);
                                    }}
                                />

                                <div style={{ margin: "0px 0px" }}></div>
                                <Button onClick={handleSearch} block type="primary">
                                    Search
                                </Button>
                            </Space>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default Position;
