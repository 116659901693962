import React, { useState } from "react";
import { Row, Col, Select } from "antd";
import { Loader, ADRListTable } from "../components";
import { useAdrTopTenQuery, useAdrDefaultsQuery } from "../services/orbisApi";
import { countrySelection } from "../data/countrySelection";

const ADROverview = () => {
  const [country, setCountry] = useState("");

  const { data, isFetching, isSuccess } = useAdrTopTenQuery({
    country: country[0],
    loadQuotes: true,
    loadEarningReleases: true,
    loadUpgradeDowngrades: true,
  });

  return (
    <>
      <Row style={{ marginBottom: "15px" }} className="block-container">
        <Col xs={24} sm={24} lg={24} xl={8} xxl={8} style={{ margin: "5px" }}>
          <div style={{ color: "darkgray", margin: "0px 0px 1px 10px" }}>Country</div>
          <Select
            size="middle"
            placeholder="Country"
            value={country[1]}
            style={{ width: "100%" }}
            onChange={(e, { key, value }) => setCountry([key, value])}
          >
            {countrySelection.map((x) => (
              <Select.Option value={x[1]} key={x[0]}>
                {x[1]}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      {isFetching ? (
        <Loader />
      ) : (
        <Row gutter={[15, 15]}>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={12}>
            <ADRListTable title="Most popular ADRs by volume" data={data.topvolume} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={12}>
            <ADRListTable title="Top ADRs by most money traded" data={data.topvalue} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={12}>
            <ADRListTable title="Best performing ADRs (price change %)" data={data.topchangepc} />
          </Col>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={12}>
            <ADRListTable title="Worst performing ADRs (price change %)" data={data.bottomchangepc} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ADROverview;
