import React, { useEffect } from "react";
import moment from "moment";
import { Typography, Divider, List, Space } from "antd";
import { Loader } from "../components";
import { grey } from "@ant-design/colors";
import { useSymbolNewsQuery, useSymbolNewsMuMutation } from "../services/orbisApi";
import { Link } from "react-router-dom";

const { Text, Title } = Typography;

const StockDetailNews = ({ symbol }) => {
  // const news = useSymbolNewsQuery({ symbol });
  const [getNews, news] = useSymbolNewsMuMutation();

  useEffect(() => {
    getNews({ symbol: symbol.toUpperCase(), start: 0, count: 5 });
  }, []);

  return (
    <>
      <Title level={4}>News</Title>
      <Divider style={{ marginTop: "-5px", borderColor: grey[1] }} />
      {news.isLoading ? (
        <Loader />
      ) : (
        <List
          itemLayout="vertial"
          size="small"
          dataSource={news?.data}
          split={false}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  item.linkOnly ? (
                    <a href={item.body} target="_blank">
                      {item.headline}
                    </a>
                  ) : (
                    <Link to={`/news/${item.newsId}`}>{item.headline}</Link>
                  )
                }
                description={
                  <Space>
                    <div style={{ color: "darkblue" }}>
                      {item.provider.charAt(0).toUpperCase() + item.provider.slice(1).toLowerCase()}{" "}
                    </div>
                    <div>
                      {moment(item.createdOn).startOf("ss").fromNow()}
                      {/* {item.createdOn.split(" ")[0]} {} {item.createdOn.split(" ")[1].slice(0, 5)}{" "} */}
                    </div>
                  </Space>
                }
              />
            </List.Item>
          )}
        />
      )}
    </>
  );
};

export default StockDetailNews;
