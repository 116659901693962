import React, { useState } from "react";
import { Layout, Menu, Avatar } from "antd";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  UserAddOutlined,
  DesktopOutlined,
  FormOutlined,
  GlobalOutlined,
  LineChartOutlined,
  BarChartOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import logo from "../images/logo1.png";

const MySidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => {
        setCollapsed(!collapsed);
      }}
    >
      <div className="logo-container">
        <Avatar src={logo} size="large" />
      </div>
      <Menu theme="dark" defaultSelectedKeys={["Home"]} mode="inline">
        <Menu.Item key="Home" icon={<HomeOutlined />}>
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item key="Trading" icon={<DesktopOutlined />}>
          <Link to="/trading">Trading</Link>
        </Menu.Item>

        <Menu.SubMenu key="News" icon={<GlobalOutlined />} title="News">
          <Menu.Item key="News">
            <Link to="/news">News</Link>
          </Menu.Item>
          <Menu.Item key="PWS">
            <Link to="/pws">PWS</Link>
          </Menu.Item>
          <Menu.Item key="SEC">
            <Link to="/sec">SEC</Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="Market" icon={<LineChartOutlined />} title="Market">
          <Menu.Item key="ETF Center">
            <Link to="/etfcenter">ETF Center</Link>
          </Menu.Item>
          <Menu.Item key="ADR Center">
            <Link to="/adrcenter">ADR Center</Link>
          </Menu.Item>
          <Menu.Item key="IPO Center">
            <Link to="/ipocenter">IPO Center</Link>
          </Menu.Item>
          <Menu.Item key="Corporate Action">
            <Link to="/corporateaction">Corporate Action</Link>
          </Menu.Item>
          <Menu.Item key="TipRank">
            <Link to="/tiprank">TipRank</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key="TW Stock" icon={<BarChartOutlined />} title="TW Stock">
          <Menu.Item key="twQuote">
            <Link to="/twquote">即時報價</Link>
          </Menu.Item>
          <Menu.Item key="twStrategy">
            <Link to="/twstrategy">交易策略</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key="Account" icon={<FormOutlined />} title="Account">
          <Menu.Item key="Account Info">
            <Link to="/accountinfo">Account Info</Link>
          </Menu.Item>
          <Menu.Item key="Account Activity">
            <Link to="/accountactivity">Account Activity</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key="Management" icon={<UserAddOutlined />} title="Management">
          <Menu.Item key="Product Master">
            <Link to="/productmaster">Product Master</Link>
          </Menu.Item>
          <Menu.Item key="Fractional">
            <Link to="/fractional">Fractional</Link>
          </Menu.Item>
          <Menu.Item key="Stock Lending">
            <Link to="/stocklending">Stock Lending</Link>
          </Menu.Item>
          <Menu.Item key="FIX Connection">
            <Link to="/fixconnection">FIX Connection</Link>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="Customers" icon={<PieChartOutlined />} title="Customers">
          <Menu.Item key="All Accounts">
            <Link to="/allaccounts">All Accounts</Link>
          </Menu.Item>
          <Menu.Item key="Position">
            <Link to="/position">Position</Link>
          </Menu.Item>
          <Menu.Item key="Reports">
            <Link to="/Reports">Reports</Link>
          </Menu.Item>
          <Menu.Item key="ETFList">
            <Link to="/etflist">ETF List</Link>
          </Menu.Item>
          <Menu.Item key="FFI Onboarding">
            <Link to="/onboarding">FFI Onboarding Form</Link>
          </Menu.Item>
          <Menu.Item key="User Request">
            <Link to="/userrequest">User Request Form</Link>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Layout.Sider>
  );
};

export default MySidebar;
