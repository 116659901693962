import React from "react";
import { useEtfDetailsQuery } from "../services/orbisApi";
import { useParams } from "react-router-dom";
import { Loader } from "../components";

const ETFDetails = () => {
  const params = useParams();
  const { data, isFetching, isSuccess } = useEtfDetailsQuery({
    symbol: params.symbol,
    loadQuotes: true,
  });

  if (isFetching) return <Loader />;

  console.log(data);

  console.log(data.AssetAllocations);
  console.log(data.Continents);
  console.log(data.Countries);
  console.log(data.Holdings);
  console.log(data.MarketCaps);
  console.log(data.Sectors);

  return <div>ETFDetails</div>;
};

export default ETFDetails;
