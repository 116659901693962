import React, { useState } from "react";
import { Result, Button, Modal } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useActivationMutation } from "../services/authApi";

const Activate = () => {
  const { uid, token } = useParams();
  const [activate, activateResult] = useActivationMutation();
  const navigate = useNavigate();

  const verifyAccount = async () => {
    const activationInfo = { uid: uid, token: token };
    const result = await activate(activationInfo);

    if (result.error) {
      showFailModal(result.error.data.uid);
      navigate("/");
    } else {
      showSuccessModal("Account activated, please login your account!");
      navigate("/lgoin");
    }
  };

  const showSuccessModal = (text) => {
    Modal.info({
      title: "VERIFY SUCCESS",
      content: text,
    });
  };

  const showFailModal = (text) => {
    Modal.error({
      title: "VERIFY FAIL",
      content: text,
    });
  };

  return (
    <Result
      title="Please verify your account"
      extra={
        <Button onClick={verifyAccount} type="primary" key="console">
          Verify
        </Button>
      }
    />
  );
};

export default Activate;
