import React, { useState } from "react";
import { Card, Typography, Row, Col, Skeleton } from "antd";
import { TradingForm, TradingChart, TradingFavList } from "../components";

const { Text, Title } = Typography;

const Trading = () => {
  const [selectedSymbol, setSelectedSymbol] = useState("");

  return (
    <>
      <Row gutter={[15, 15]}>
        <Col xs={24} sm={24} lg={24} xl={6} xxl={6}>
          <Card>
            <TradingForm setSelectedSymbol={setSelectedSymbol} />
          </Card>
        </Col>
        <Col xs={24} sm={24} lg={24} xl={12} xxl={12}>
          <Card>{selectedSymbol.length > 0 ? <TradingChart selectedSymbol={selectedSymbol} /> : <Skeleton />}</Card>
        </Col>
        <Col xs={24} sm={24} lg={24} xl={6} xxl={6}>
          <Card>
            <TradingFavList />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Trading;
