import React from "react";
import { Row, Col, Typography, Statistic, Card, Space } from "antd";
import { blue, green, red, orange, cyan, lime, geekblue, yellow, gold } from "@ant-design/colors";
import { HomeIndustryHeatMap, HomeNews, HomeStockCard, HomeTopStock } from "../components";
import moment from "moment";
import { useEquityQuoteQuery } from "../services/orbisApi";

const Homepage = () => {
  const equity = useEquityQuoteQuery({ symbols: "SPY,DIA,QQQ,IWM" });

  const spy = equity?.data?.filter((item) => item.symbol === "SPY");
  const dia = equity?.data?.filter((item) => item.symbol === "DIA");
  const qqq = equity?.data?.filter((item) => item.symbol === "QQQ");
  const iwm = equity?.data?.filter((item) => item.symbol === "IWM");

  return (
    <>
      {equity.isSuccess && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} lg={12} xl={6} xxl={6}>
            <HomeStockCard
              title={`S&P 500 ETF`}
              price={spy[0].lastPrice.toFixed(2)}
              change={spy[0].change.toFixed(2)}
              changePercent={spy[0].changePercent?.toFixed(2)}
              bgColor={"#BDD2E0"}
            />
          </Col>
          <Col xs={24} sm={24} lg={12} xl={6} xxl={6}>
            <HomeStockCard
              title={"NASDAQ ETF"}
              price={qqq[0].lastPrice.toFixed(2)}
              change={qqq[0].change.toFixed(2)}
              changePercent={qqq[0].changePercent?.toFixed(2)}
              bgColor={"#E1E3E3"}
            />
          </Col>
          <Col xs={24} sm={24} lg={12} xl={6} xxl={6}>
            <HomeStockCard
              title={"Dow Jones ETF"}
              price={dia[0].lastPrice.toFixed(2)}
              change={dia[0].change.toFixed(2)}
              changePercent={dia[0].changePercent?.toFixed(2)}
              bgColor={"#B1B6AF"}
            />
          </Col>
          <Col xs={24} sm={24} lg={12} xl={6} xxl={6}>
            <HomeStockCard
              title={"Russell 2000 ETF"}
              price={iwm[0].lastPrice.toFixed(2)}
              change={iwm[0].change.toFixed(2)}
              changePercent={iwm[0].changePercent?.toFixed(2)}
              bgColor={"#D7D8D2"}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col xs={24} sm={24} lg={12} xl={12} xxl={7}>
          <HomeTopStock />
        </Col>
        <Col xs={24} sm={24} lg={12} xl={12} xxl={9}>
          <div>
            <HomeIndustryHeatMap type={"SECTOR"} title={"Major sector performance"} />
          </div>
          <div style={{ marginTop: "15px" }}>
            <HomeIndustryHeatMap type={"GROUP"} title={"Sector performance"} />
          </div>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={12} xxl={8}>
          <HomeNews />
        </Col>
      </Row>
    </>
  );
};

export default Homepage;
