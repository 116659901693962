import React from "react";
import Plot from "react-plotly.js";
import { Typography } from "antd";

const { Title } = Typography;

const ReportTreeMap = ({ data }) => {
    const totalValue = data.map((x) => x.value).reduce((a, b) => a + b, 0);

    const customColorScale = [
        ["0.0", "#1f1f1f"],
        ["0.009", "#004c6d"],
        ["0.03", "#1c5a7c"],
        ["0.05", "#2f688b"],
        ["0.09", "#40779b"],
        ["0.1", "#5085aa"],
        ["0.3", "#6095ba"],
        ["0.4", "#70a4cb"],
        ["0.5", "#80b4db"],
        ["0.6", "#90c4ec"],
        ["0.7", "#90c4ec"],
        ["0.8", "#90c4ec"],
        ["1.0", "#90c4ec"],
    ];

    const treeMapColor = (num) => {
        let valueRate = (num / totalValue) * 100;
        let color = "";
        if (valueRate < 0.05) {
            color = "#1f1f1f";
        } else if (valueRate >= 0.05 && valueRate < 0.1) {
            color = "#004c6d";
        } else if (valueRate >= 0.1 && valueRate < 0.3) {
            color = "#1c5a7c";
        } else if (valueRate >= 0.3 && valueRate < 0.5) {
            color = "#2f688b";
        } else if (valueRate >= 0.5 && valueRate < 1.5) {
            color = "#40779b";
        } else if (valueRate >= 1.5 && valueRate < 3) {
            color = "#5085aa";
        } else if (valueRate >= 3 && valueRate < 5) {
            color = "#6095ba";
        } else if (valueRate >= 5 && valueRate < 7) {
            color = "#70a4cb";
        } else if (valueRate >= 7 && valueRate < 10) {
            color = "#80b4db";
        } else if (valueRate >= 10) {
            color = "#90c4ec";
        }
        return color;
    };

    var labels = data.map((x) => x.account);
    var text = data.map((x) => x.name);
    var parents = Array(labels.length).fill("");
    var values = data.map((x) => x.value);
    const chartData = [
        {
            type: "treemap",
            labels: labels,
            parents: parents,
            values: values,
            text: text,
            textposition: "middle center",
            texttemplate: "%{label}<br>(%{percentEntry})",
            // textinfo: "label+value+percent entry+text",
            hoverinfo: "label+text+value+percent root",
            domain: { x: [0, 1] },
            outsidetextfont: { size: 20, color: "#377eb8" },
            marker: {
                autocolorscale: false,
                // colorscale: customColorScale,
                colors: data.map((x) => treeMapColor(x.value)),
                line: { width: 0 },
            },
            pathbar: { visible: false },
        },
    ];

    const layout = {
        margin: {
            l: 0,
            r: 0,
            t: 0,
            b: 0,
        },
        extendtreemapcolors: true,
        // showscale: true,
        // autosize: true,
    };

    return (
        <>
            <Title level={4} style={{ marginLeft: "8px" }}>
                Customer account value
            </Title>
            <Plot data={chartData} layout={layout} useResizeHandler={true} style={{ width: "100%" }} />
        </>
    );
};

export default ReportTreeMap;
