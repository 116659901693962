import React from "react";
import { Routes, Route } from "react-router-dom";
import { ADROverview, ADRSearch } from "../components";
import ADRLayout from "../hocs/ADRLayout";

const ADRCenter = () => {
  return (
    <ADRLayout>
      <Routes>
        <Route exact path="/" element={<ADROverview />} />
        <Route exact path="/adrsearch" element={<ADRSearch />} />
      </Routes>
    </ADRLayout>
  );
};

export default ADRCenter;
