import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const finMindApiHeaders = {};
const baseUrl = "https://api.finmindtrade.com/api/v4/data";

export const finMindApi = createApi({
  reducerPath: "finMindApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    taiwanStockInfo: builder.query({
      query: () => {
        return {
          url: "",
          params: {
            dataset: "TaiwanStockInfo",
            token: "",
          },
        };
      },
    }),
  }),
});

export const { useTaiwanStockInfoQuery } = finMindApi;
