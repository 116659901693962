import React from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Row, Col, Card, Modal } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../services/authApi";

const ResetPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resetPassword, resetPasswordResult] = useResetPasswordMutation();

  const onFinish = async (email) => {
    const result = await resetPassword(email);
    form.resetFields();

    if (result.error) {
      showFailModal("Fail to send password request to your email!");
      navigate("/");
    } else {
      showSuccessModal("Reset password request has been sent to you email!");
    }
  };

  const showSuccessModal = (text) => {
    Modal.info({
      title: "PASSWORD CHANGE",
      content: text,
    });
  };
  const showFailModal = (text) => {
    Modal.error({
      title: "PASSWORD CHANGE",
      content: text,
    });
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={24} lg={24} xl={8} xxl={8}></Col>
        <Col
          xs={24}
          sm={24}
          lg={24}
          xl={8}
          xxl={8}
          style={{
            // border: "1px solid",
            height: "80vh",
            width: "100%",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <Card hoverable={true}>
            <Form
              form={form}
              size="large"
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                form={form}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input prefix={<MailOutlined style={{ color: "gray" }} />} placeholder="Email" />
              </Form.Item>
              <Form.Item>
                <Button block type="primary" htmlType="submit">
                  Reset Password
                </Button>
              </Form.Item>
              <Form.Item>
                Back to login page? <Link to="/login">Login</Link>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} lg={24} xl={8} xxl={8}></Col>
      </Row>
    </>
  );
};

export default ResetPassword;
