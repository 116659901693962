import React from "react";
import { Typography, PageHeader, Row, Col, Divider, Space, Tag } from "antd";
import HTMLReactParser from "html-react-parser";
import { Loader } from "../components";
import { useParams } from "react-router-dom";
import { useLookupNewsQuery } from "../services/orbisApi";
import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

const NewsBody = () => {
  const { id } = useParams();
  const { data, isFetching } = useLookupNewsQuery({ newsId: id });

  return (
    <>
      <div className="block-container">
        <PageHeader ghost={true} onBack={() => window.history.back()} title="News"></PageHeader>
      </div>
      {isFetching ? (
        <Loader />
      ) : (
        <Row className="block-container">
          <Col xs={0} sm={0} lg={0} xl={3} xxl={3}></Col>
          <Col xs={24} sm={24} lg={24} xl={18} xxl={18} style={{ padding: "15px" }}>
            <Space direction="vertical" size="small">
              <Title level={2}>{data.headline}</Title>
              <Divider />
              <Text type="secondary">{data?.createdOn}</Text>
              <Text strong>
                News Provided by{" "}
                <a href="#" target="_blank">
                  {data.provider}
                </a>
              </Text>
              {data?.symbols.length > 0 && (
                <Text strong>
                  Symbols:{" "}
                  {data?.symbols.map((x) => (
                    <Tag>
                      <Link to={`/stockdetail/${x}`}>{x}</Link>
                    </Tag>
                  ))}
                </Text>
              )}
              <Divider />
              <div>{HTMLReactParser(data.xmlBody)}</div>
            </Space>
          </Col>
          <Col xs={0} sm={0} lg={0} xl={3} xxl={3}></Col>
        </Row>
        // <div className="block-container" >
        //   <Typography.Title level={3}>{data.headline}</Typography.Title>
        //   firstLink:<div>{data.firstLink}</div>
        //   body:<div>{data.body}</div>
        //   provider:<div>{data.provider}</div>
        //   Symbols:<div>{data.symbols}</div>
        //   xmlBody<div>{data.xmlBody}</div>
        // </div>
      )}
    </>
  );
};

export default NewsBody;
