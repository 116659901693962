import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

const Working = () => {
    return (
        <Result
            title="This page is under construction..."
            extra={
                <Button type="primary" key="console">
                    <Link to="/">Home</Link>
                </Button>
            }
        />
    );
};

export default Working;
